import React from 'react';
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';

import { Badge } from '../../../../../../..';

interface ComponentProps {
  selected: boolean;
  counter: number;
}

export const Component = styled.div<ComponentProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  box-sizing: border-box;
  width: 100%;
  padding: .75rem 1rem;

  cursor: pointer;
  background-color: ${props =>
    props.selected ? '#414A56' :
      props.counter === 0 ? '#ccc' : '#fff'
  };

  ${props => (
    props.counter !== 0 &&
    `:hover {
      background-color: ${props.selected ? '#303945' : '#eee'};
    }`)
  }
`;

export const UnreadMessagesBadge = styled((props) => <Badge {...props} />)<{ visible: boolean }>`
  &.MuiBadge-root {
    display: ${props => props.visible ? 'initial' : 'none'};
    position: absolute;
    right: 30px;
    top: 50%;
  }
`;

interface ChatItemInfoProps {
  chatIsSelected: boolean;
  hasUnreadMessages: boolean;
  hasPerson: boolean;
}

export const ChatItemInfo = styled.div<ChatItemInfoProps>`
  & {
    display: flex;
    flex-direction: column;
    width: 100%;

    div.chat-identifiers {
      display: flex;
      flex-flow: column;

      span:first-child {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        & > p {
          margin-right: 5px;
        }

        & > p:last-child {
          margin-right: 0;
        }
      }

      & p.MuiTypography-root[class*=-identifier] {
        color: ${props => props.chatIsSelected ? '#fff' : '#666'};
        display: ${props => props.hasPerson ? 'initial' : 'none'};
        font-size: .75rem;

        &.main-identifier {
          display: initial; /* O identificador principal sempre aparece */
          font-size: 1rem;
          font-weight: ${props => props.hasUnreadMessages ? 'bold' : 400};
          ${props => (!props.chatIsSelected && props.hasUnreadMessages) && 'color: #000;'}
        }

        &.city-identifier {
          margin-top: -2px;
        }
      }
    }
  }
`;

export const LastMessagePreview = styled(Typography)`
  & {
    margin-top: 2px;
    font-size: 12px !important;
    text-overflow: ellipsis;
  }
`;

export const DemandBookmark = styled.div<{ visible: boolean; hasOpenSuggest: boolean }>`
  & {
    display: ${props => props.visible ? 'initial' : 'none'};

    position: relative;
    margin-right: 5px;

    span {
      position: absolute;
      width: 100%;
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${props => props.hasOpenSuggest ? '#fff' : '#000'};
    }

    svg {
      font-size: 40px;
    }
  }
`;
