import React, {
  memo,
  forwardRef
} from 'react';

import {
  TextFieldElement,
  StyledAutocomplete
} from './styles';

import { createFilterOptions, FilterOptionsState } from '@material-ui/lab';


import { CheckBox } from '../../components';

import Chip from '@material-ui/core/Chip';
import { TextFieldProps } from '@material-ui/core/TextField';

type TextFieldElement = Omit<TextFieldProps, "onSelect"> & {
  options: any[];
  getOptionLabel: (item: any) => string;
  onSelect: (item: any) => void;
  onDesselect: (item: any) => void;
}



const defaultFilterOptions = createFilterOptions<any>();
const filterOptions = (options: any[], state: FilterOptionsState<any>) => 
  defaultFilterOptions(options, state).slice(0, 20);

export const AutoComplete = memo((props: TextFieldElement) => {
  return (
    <StyledAutocomplete
      multiple
      disabled={props.disabled}
      options={props.options}
      getOptionLabel={props.getOptionLabel}
      disableCloseOnSelect
      freeSolo
      disableClearable
      filterOptions={filterOptions}
      value={props.value}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={props.getOptionLabel ? props.getOptionLabel(option) : ''}
            size="small"
            key={`item-${props.getOptionLabel(option).toLowerCase()}`}
            style={{
              margin: '0 2px'
            }}
          />
        ))
      }
      renderInput={(params) => (
        <TextFieldElement
          {...params}
          size="small"
          placeholder={props.placeholder}
        />
      )}
      renderOption={(option, { selected }) => (
        <CheckBox
          style={{ marginRight: 8 }}
          checked={selected}
          onClick={(event) => {
            event?.preventDefault();
            if (props.onSelect) {
              props.onSelect(option);
            }
          }}
          label={props.getOptionLabel ? props.getOptionLabel(option) : ''}
        />
      )}
    />
  );
});

export const TextField = memo(forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {
  return (
    <TextFieldElement
      {...props}
      ref={ref}
      variant="filled"
      size={props.size}
    />
  );
}));
