import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';

export const AttachedFileIconButton = styled(IconButton)`
  && {
    width: 24px;
    height: 24px;
    z-index: 99;

    svg {
      color: #555;
    }
  }
`;
