import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ReactHtmlParser from 'react-html-parser';

import { MessageContext } from '../../../../../../../../../../contexts';

import Attachment from './components/Attachment';
import ShowMore from './components/ShowMore';

import {
  Content,
  MessageGroup,
  MessageItem,
  ReadIndicator,
} from './styles';

function createMessageItemChildren(sentence: string) {
  const words = sentence.split(' ');

  const elements: (string | JSX.Element)[] = words.map((word, index) => {
    if (word.length > 40) {
      const items: string[] = []
      for (let i = 0; i < word.length; i += 40) {
        items.push(word.substring(i, i + 40));
      }
      word = items.join('\n');
    }

    let element: (string | JSX.Element) = word;

    if (word.includes('http')) {
      element = <a key={`word-${index}`} target="_blank" rel="noopener noreferrer" href={word}>{word}</a>
    }

    return element;
  });

  return elements
    .reduce((total: (string | JSX.Element)[], item) => ([
      ...total,
      item,
      ' '
    ]), []);
}

function MessageContent() {
  const { message, markAsRead } = useContext(MessageContext);
  
  const bodyRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setOverflow] = useState(false);
  const [isVisibleShowMore, setVisibleShowMore] = useState(false);

  const markMessageAsRead = useCallback(() => markAsRead(!message.read), [markAsRead, message.read]);

  useEffect(() => {
    if (bodyRef.current) {
      setOverflow(bodyRef.current.clientHeight > 400);
    }
  }, []);
  
  const attachedFileMIMEType = useMemo( () => {
    if (message.attachedFile) {
      const { base64 } = message.attachedFile;
      const decodedStr = window.atob(base64);

      if (base64.startsWith('/9j')) return 'image/jpeg';
      
      else if (decodedStr.substring(1).startsWith('PNG')) return 'image/png';
      
      else if  (decodedStr.startsWith('GIF')) return 'image/gif';
      
      else if (decodedStr.substring(1).startsWith('PDF')) return 'application/pdf';

      else if (base64.startsWith('0M8R') || decodedStr.startsWith('PK')) {
        // se existe 'xl/drawings', na string decodificada,
        // o arquivo é uma planilha xls/xlsx
        // caso contrário é um documento doc/docx
        
        return (decodedStr.includes('xl/drawings'))
          ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      } else if (decodedStr.startsWith('ID3') || decodedStr.startsWith('//')) {
        return 'audio/mpeg3';
      }
      const isOtherAudio = (
        decodedStr.startsWith('RIFF')
        || decodedStr.startsWith('0&')
        || decodedStr.startsWith('OggS')
        || decodedStr.startsWith('ftypisom')
      )
      if (isOtherAudio) return 'audio/wav';
      return 'text/plain';
    }
    return '';
  }, [message.attachedFile] );

  const isAttachedFileViewable = useMemo(() => {
    if (message.attachedFile) {
      return Boolean(attachedFileMIMEType.match(/image|audio/));
    }
    return undefined;
  }, [message.attachedFile, attachedFileMIMEType]);

  return (
    <>
      <Content>
        {
          message.origin !== 'sent' &&
          message.read !== undefined &&
          <ReadIndicator
            read={message.read}
            onClick={markMessageAsRead}
          />
        }
        <MessageGroup
          ref={bodyRef}
          isOverflow={isOverflow}
          show={isVisibleShowMore}
        >
          {
            message.items.length > 0 
              ? message.items.map((item, index) => {
                  let result;
                  if (message.type === 'email') {
                    const elements = [...ReactHtmlParser(item)];
                    const [first, ...others] = elements;
                    if (others.length > 0) {
                      const sentences: string[] = elements
                        .filter(item => typeof item === 'string');
                      
                      const results = sentences
                        .map(createMessageItemChildren);

                      sentences.forEach((sentence, index) => {
                        const elementIndex = elements
                          .findIndex(element => element === sentence);
                        elements[elementIndex] = results[index];
                      })
                    } else if (first && typeof first === 'string') {
                      elements[0] = createMessageItemChildren(first);
                    }
                    result = elements.flatMap(element => element);
                  } else result = createMessageItemChildren(item);
                  
                  return (<MessageItem key={`${index}-${item}`}>{result}</MessageItem>);
                })
              : (isAttachedFileViewable === false)
                ? <MessageItem>Anexo: {message.attachedFile?.descricao}</MessageItem>
                : <></>
          }
          {
            isAttachedFileViewable === true && (
              <Attachment
                // @ts-expect-error `message.attachedFile` sempre será definido
                attachedFile={message.attachedFile}
                MIMEType={attachedFileMIMEType}
                campaing={message.code}
              />
            )
          }
        </MessageGroup>
        {
          isAttachedFileViewable === false && (
            <Attachment
              // @ts-expect-error `message.attachedFile` sempre será definido
              attachedFile={message.attachedFile}
              MIMEType={attachedFileMIMEType}
              campaing={message.code}
            />
          )
        }
      </Content>
      {
        isOverflow && (
          <ShowMore
            expand={isVisibleShowMore}
            onClick={() => setVisibleShowMore(old => !old)}
          />
        )
      }
    </>
  )
}

export default memo(MessageContent);
