import React, { memo } from 'react';
import { Component } from './styles';

import SearchForm from './components/SearchForm';
import ChatList from './components/ChatList';

const SideBar = () => (
  <Component>
    <SearchForm />
    <ChatList />
  </Component>
);

export default memo(SideBar);
