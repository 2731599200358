import { AxiosResponse } from "axios";
import Resource from "./Resource";

export interface Chat {
  contact: string;
  contactName?: string;
  city?: string
  type: 'whats' | 'email' | 'sms';
  description: string;
  counter: number;
  personId?: number;
  status: string;
  lastMessageDate: Date;
  demand: 'open' | 'close' | 'none';
  demandLevels: string;
  hasOpenSuggestion: boolean;
}

export interface ChatFilter {
  peopleNames?: string[]
  peopleIds?: number[]
  emails?: string[]
  telephones?: string[]
  colors?: number[]
  regions?: number[]
  dioceses?: number[]
  cities?: number[]
  neighborhood?: number[]
  campaignsIds?: number[]
  campaignsNames?: string[]
  message?: string
  messageStatus?: number[]
  demandLevels?: number[]
  addedToHistory: boolean;
  withNote: boolean;
  withSuggestion: boolean;
  note?: string;
  suggestion?: string;
  startDate: Date | null;
  endDate: Date | null;
}

export default class GetListChatResource extends Resource<Chat[]> {
  private token: string;
  private limit?: number;
  private offset?: number;
  private personId?: number;

  private filter?: ChatFilter;
  private order?: string;

  constructor(token: string) {
    super();
    this.token = token;
  }

  public async result(): Promise<Chat[]> {
    let response: AxiosResponse;
    const requestUrl = this.getRequestUrl();

    response = await this.request.get(requestUrl);

    const result = (response.data.chats as any[]).map<Chat>(item => ({
      contact: item.contato,
      contactName: item.nome,
      city: item.cidade,
      type: item.tipo,
      description: item.mensagem,
      counter: item.qtde_nao_lidas,
      personId: item.idpessoa,
      status: item.status,
      lastMessageDate: item.dtretorno,
      demand: item.demanda_aberta
        ? 'open'
        : 'none',
      demandLevels: item.nivel || '',
      hasOpenSuggestion: item.tem_sugestao_de_resposta
    }));

    return result
  }

  setPersonId(value: number) {
    this.personId = value;
  }

  setLimit(limit?: number) {
    this.limit = limit;
  }

  setOffset(offset?: number) {
    this.offset = offset;
  }

  setFilter(filter: ChatFilter) {
    this.filter = filter;
  }

  setOrder(order: string) {
    this.order = order;
  }

  getRequestUrl() {
    let url: string = `${this.baseURL}/chats/${this.token}`;
    let queryParams: string = '';

    if (this.limit !== undefined && this.offset !== undefined) {
      queryParams = `limit=${this.limit}&offset=${this.offset}`
    }
    if (this.filter) {
      const filterParams = this.parseFilters();
      queryParams += `&${filterParams}`;
    }
    if (this.order) {
      queryParams += `&ordem=${this.parseOrder()}`;
    }
    if (this.personId) {
      queryParams += `&idpessoa=${this.personId}`;
    }

    return `${url}?${queryParams}`;
  }

  private parseFilters() {

    let params = {};
    if(this.filter?.peopleNames) params["nomePessoas"] = this.filter?.peopleNames;
    if(this.filter?.peopleIds) params["idPessoas"] = this.filter?.peopleIds;
    if (this.filter?.emails) params["emails"] = this.filter?.emails;
    if (this.filter?.telephones) params["telefones"] = this.filter?.telephones;
    if(this.filter?.colors) params["cores"] = this.filter?.colors

    if(this.filter?.neighborhood) params["bairros"] = this.filter?.neighborhood
    if(this.filter?.cities) params["cidades"] = this.filter?.cities
    if(this.filter?.dioceses) params["dioceses"] = this.filter?.dioceses
    if(this.filter?.regions) params["regioes"] = this.filter?.regions

    if(this.filter?.campaignsIds) params["idCampanhas"] = this.filter?.campaignsIds
    if(this.filter?.campaignsNames) params["nomeCampanhas"] = this.filter?.campaignsNames
    if(this.filter?.message) params["mensagem"] = this.filter?.message
    if(this.filter?.messageStatus) params["statusMensagem"] = this.filter?.messageStatus
    if(this.filter?.demandLevels) params["nivel"] = this.filter?.demandLevels
    if(this.filter?.addedToHistory) params["adicionadoNoHistorico"] = this.filter?.addedToHistory

    if(this.filter?.withNote) params["comObservacao"] = this.filter?.withNote
    if(this.filter?.withSuggestion) params["comSugestaoDeResposta"] = this.filter?.withSuggestion

    if(this.filter?.note) params["observacao"] = this.filter?.note;
    if(this.filter?.suggestion) params["sugestaoDeResposta"] = this.filter?.suggestion;

    if(this.filter?.startDate) params["dataInicial"] = this.filter?.startDate.toISOString().split('T')[0]
    if(this.filter?.endDate) params["dataFinal"] = this.filter?.endDate.toISOString().split('T')[0]

    return new URLSearchParams(params).toString();
  }

  private parseOrder() {
    return (this.order === 'Mais Antigas') ? 'date:asc' : 'date:desc';
  }
}
