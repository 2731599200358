import Resource from "./Resource";

export interface Region {
  id: number;
  name: string;
}

export default class GetListRegionsResource extends Resource<Region[]> {
  private token: string;

  constructor(token: string) {
    super();
    this.token = token;
  }

  public async result(): Promise<Region[]> {
    const response = await this.request.get(`${this.baseURL}/autocomplete/regioes/${this.token}`);

    const result: Region[] = response.data.map(item => ({
      id: item.id,
      name: item.descricao,
    }));

    return result
  }
}
