import axios from 'axios';

import useSWR from 'swr';

import GetChatResource from '../../api/resources/GetChatResource';
import GetChatWithoutPersonResource from '../../api/resources/GetChatWithoutPersonResource';

import {
  IMessage,
} from '.';
import { CancelToken } from 'axios';

interface UseMessageList {
  messageListRefresh?: IMessage[];
  setMessageListRefresh: (action: IMessage[] | undefined | ((data?: IMessage[]) => IMessage[] | undefined), value?: boolean) => Promise<IMessage[] | undefined>;
}

/**
 * Esse hook realiza a atualização das mensagens da conversa dentro de um intervalo de tempo
 * @param token Token do usuário
 * @param personId Identificação da pessoa
 * @param currentChat Identificador do chat
 * @returns Lista das últimas mensagens
 */
function useChatRefresh(token: string, personId?: number, currentChat?: string, cancelToken?: CancelToken): UseMessageList {

  const {
    data,
    mutate,
  } = useSWR<IMessage[] | undefined>([`/messages`, token, currentChat], async () => {
    if (currentChat && cancelToken) {
      let resource: GetChatResource | GetChatWithoutPersonResource;
      let contactName: string = '';
      if (personId) {
        resource = new GetChatResource(cancelToken, personId, token);
      } else {
        let identifier: string;
        let type: 'email' | 'number';
        if (currentChat!.includes('@')) {
          identifier = currentChat!.replace('-null', '');
          type = 'email';
          contactName = identifier;
        } else {
          type = 'number';
          const formattedNumber = currentChat!.replace('-null', '');
          const justNumbers = formattedNumber.match(/\d+/g) ? formattedNumber.match(/\d+/g)!.join("") : "";
          identifier = justNumbers;
          contactName = formattedNumber;
        }

        resource = new GetChatWithoutPersonResource(cancelToken, type, identifier, token);
      }

      resource.setOffset(0);
      resource.setLimit(20);

      if (resource) {
        try { 
          const result = await resource.result();
          let newMessages: IMessage[] = result.map(item => ({
            id: item.id,
            code: item.campaign ? Number(item.campaign?.id) : undefined,
            label: item.campaign ? item.campaign.name : undefined,
            timestamp: item.timestamp,
            editTimestamp: item.editTimestamp,
            read: !(item.status === 'Não lido'),
            type: item.type,
            items: item.text?.split('\n') ?? [],
            origin: item.received ? 'received' : 'sent',
            note: item.note,
            suggestion: item.responseSuggestion,
            historyType: item.historyType,
            demand: item.status === 'Demanda em Aberta'
              ? 'open'
              : item.status === 'Demanda Finalizada'
                ? 'close'
                : 'none',
            isQueued: item.status === 'Na fila',
            relationshipId: item.relationshipId,
            answer: item.answer,
            qualification: item.qualification,
            attachedFile: item.attachedFile,
          }));

          // apenas adiciona mensagens da pessoa atual
          const currentChatParts = currentChat.split('-');
          const { length: partsLength } = currentChatParts;

          const noPersonIdChat = currentChatParts
            .filter((item, index) => index !== partsLength - 1);
          const { length: chatNameLength } = noPersonIdChat;

          const currentPersonId = currentChatParts[partsLength - 1];
          const isCurrentChatMessages = (
            personId 
              ? personId === Number(currentPersonId)
              : chatNameLength > 1 ? noPersonIdChat.join('-') === contactName : noPersonIdChat[0]
          );
          if (!isCurrentChatMessages) return undefined;
          return newMessages;
        } catch (error) {
          if (axios.isCancel(error)) {
            // @ts-ignore
            console.error(error.message);
          } else {
            console.error(error);
          }
        }
      }
    }
  }, {
    refreshInterval: 20000,
  });

  return {
    messageListRefresh: data,
    setMessageListRefresh: mutate,
  }
}

export default useChatRefresh;