import React from 'react';

import Typography from '@material-ui/core/Typography' ;

import styled from 'styled-components';

interface BlockProps {
  width?: number;
  height?: number;
  flexDirection?: string;
}

export const Block = styled.div<BlockProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection ?? 'column'};
  background-color: #E8E8E8;
  border-left: 5px solid #414A56;
  padding: 16px;
  width: calc(${props => props.width ?? 50}px - 16px);
  height: calc(${props => props.height ?? 50}px - 16px);
`;

interface GroupProps {
  flexDirection?: string;
  divider?: boolean;
}

export const Group = styled.div<GroupProps>`
  display: flex;
  padding: 8px 4px;
  flex-direction: ${props => props.flexDirection ?? 'column'};
  height: 100%;
`;

interface TitleProps {
  children: string;
}

export const Title = (props: TitleProps) => (
  <Typography
    style={{
      fontSize: 16,
      fontWeight: 'bold'
    }}
  >
    {props.children}
  </Typography>
)


interface DividerProps {
  height: number;
}

export const Divider = styled.div<DividerProps>`
  height: ${props => props.height + 'px' ?? '100%'};
  width: 1px;
  background-color: #aaa;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  margin: 6px;
  align-items: center;
  flex-wrap: wrap;
`;

export const CheckBoxGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 4px;

  .level-checkbox {
    margin-left: 0px;
  }
`;