import React, {
  useContext,
} from 'react';

import ChatContext from '../../contexts/ChatContext';

import BlankScreen from './components/BlankScreen'
import ChatScreen from './components/ChatScreen';

export default function MessagePanel() {
  const { currentChat } = useContext(ChatContext);
  
  return (
    currentChat !== 'none'
      ? <ChatScreen />
      : <BlankScreen />
  );
}
