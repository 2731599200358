import React, {
  memo,
  forwardRef,
} from 'react';

import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';

function TextInput(props: InputBaseProps, ref: React.Ref<unknown>) {
  return (
    <InputBase
      {...props}
      ref={ref}
      style={{
        border: 0,
        outline: 0,
        margin: '0 5px',
        width: 'calc(100% - 10px)',
        alignItems: 'start',
        ...props.style,
      }}
    />
  );
}

/**
 * Componente de input para form de mensagem.
 */
export default memo(forwardRef(TextInput));
