import React, {
  memo,
} from 'react';

import Typography from '@material-ui/core/Typography';

import { Component } from './styles';

interface HeaderItemProps {
  name: string;
  size?: number;
}

interface HeaderProps {
  items: HeaderItemProps[];
}

function Header(props: HeaderProps) {

  return (
    <Component>
      {
        props.items.map(item => (
          <Typography 
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontSize: 14,
              fontWeight: 'bold',
              padding: '10px 15px',
              width: item.size === undefined ? 'auto' : item.size > 20 ? item.size : 20,
            }}
            key={`table-header-${item.name}`}
          >
            {item.name}
          </Typography>
        ))
      }
    </Component>
  );
}

export default memo(Header);
