import React, {
  ReactChild,
} from 'react';

import { Component } from './styles';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface DialogTitleProps {
  children: ReactChild;
  onClose: () => void;
  width?: React.CSSProperties["width"];
  color?: string;
}

export default function Title(props: DialogTitleProps) {
  return (
    <Component style={{ width: props.width ?? '100%' }}>
      <Typography
        style={{
          fontSize: 20,
          opacity: 0.8,
          color: props.color ? props.color : '#000'
        }}
      >
        {props.children}
      </Typography>
      <IconButton
        onClick={props.onClose}
        style={{
          padding: 0,
          color: props.color ? props.color : '#000'
        }}
      >
        <CloseIcon />
      </IconButton>
    </Component>
  );
}