import React from 'react';

import {
  ChatFilterProvider,
} from '../../../../contexts/ChatFilterContext';

import { Component } from './styles';

import SearchTextField from './components/SearchTextField';


export default function SearchForm() {

  return (
    <Component>
      <ChatFilterProvider>
        <SearchTextField />
      </ChatFilterProvider>
    </Component>
  )
}
