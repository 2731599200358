import React, { memo } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import {
  Component
} from './styles';

interface AnswerToProps {
  onClose?: () => void
  text: string
}

function AnswerTo(props: AnswerToProps) {

  return (
    <Component>
      <Typography style={{
        fontSize: 14,
        color: '#222',
        overflow: 'hidden',
        textOverflow: 'clip',
        maxHeight: 40,
      }}>
        {props.text}
      </Typography>
      {
        props.onClose &&
        <IconButton
          style={{ padding: 4 }}
          onClick={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      }
    </Component>
  );
}

export default memo(AnswerTo);