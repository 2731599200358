import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  bottom: 0;
  display: flex;
  padding: 15px 35px;
  width: calc(100% - 87px);
  min-height: 60px;
  max-height: calc(30vh - 30px);
  background-color: #414A56;
  z-index: -20;
`;

interface TypeDecoratorProps {
  type: 'sms' | 'whats' | 'email';
  noOptions: boolean
}

export const LeftTypeDecorator = styled.div<TypeDecoratorProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: ${props =>
    props.noOptions
      ? 'grey'
      : props.type === 'email'
        ? '#D34F12'
        : props.type === 'whats'
          ? '#12D36D'
          : '#0E7CD7'
  };
  height: calc(100% - 20px);
  width: 70px;
  border-radius: 5px;
  box-shadow: 0 0 8px #222;
  padding-top: 14px;
  padding-bottom: 6px;
  padding-left: 8px;
`;

export const Group = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;