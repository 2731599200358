import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import SendIcon from '@material-ui/icons/Send';

import { BaseEmoji } from 'emoji-mart';

import { getTextFromHTMLString } from '../../../../../../../../../../utils';

import {
  ChatContext,
  MessageGroupContext,
} from '../../../../../../../../../../contexts';

import { AnswerTo } from '../../../../../../../../../../components';

import EmojiAdornment from './components/EmojiAdornment';
import AttachmentAdornment from './components/AttachmentAdornment';
import EmojiPickerPopup from './components/EmojiPickerPopup';
import TextInput from './components/TextInput';

import {
  ButtonArea,
  Component,
  Content,
  Divider,
  Line,
} from './styles';

import { Attachment } from '../../../../../../../../../../api/handlers/SendIndividualMessageHandler';

type SendType = 'whats' | 'email' | 'sms';

export interface SelectedFile {
  file: File | null;
  info?: Attachment;
}

interface MessageInputFormProps {
  handleScrollOnSend: VoidFunction;
  type?: SendType;
}

function MessageInputForm(props: MessageInputFormProps) {
  const {
    addSendMessage,
    responseToAnswer,
    setHeaderVisible,
    selectResponseToAnswer,
  } = useContext(MessageGroupContext);
  
  const { personId } = useContext(ChatContext);

  const [sendInProgress, setSendInProgress] = useState<boolean>(false);
  const [emojiPickerVisible, setEmojiPickerVisible] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState<string>('');

  const [attachment, setAttachment] = useState<SelectedFile>({ file: null });

  const refFormBody = useRef<HTMLDivElement>(null);
  const refMessageTextInput = useRef<HTMLTextAreaElement>(null);
  const refLastCursorPos = useRef<number>(0);

  const color = useMemo(() => {
    if (!props.type) return 'grey';
    if (props.type === 'whats') return '#12D36D';
    if (props.type === 'email') return '#D34F12';
    if (props.type === 'sms') return '#0E7CD7';
  }, [props.type]);

  const statusSendButton = useMemo(() => {
    if (!props.type) {
      return {
        disable: true,
        tooltip: "Não existem meios de comunicação disponíveis"
      };
    } else if (props.type === 'email' && subject.length === 0) {
      return {
        disable: true,
        tooltip: "O assunto não pode ser vazio"
      };
    } else if (props.type === 'whats' && message.length === 0 && !attachment.file) {
      return {
        disable: true,
        tooltip: "A mensagem deve ter, pelo menos, um texto ou um anexo"
      }
    } else if (props.type !== 'whats' && message.length === 0) {
      return {
        disable: true,
        tooltip: "A mensagem não pode ser vazia"
      }
    } else if (sendInProgress) {
      return {
        disable: true,
        tooltip: "Enviando..."
      };
    }

    return {
      disable: false,
      tooltip: "Clique para enviar a mensagem",
    };
  }, [attachment.file, message.length, props.type, sendInProgress, subject.length]);
  
  const isTextInputDisabled = useMemo(() => !props.type, [props.type]);

  const clearForm = useCallback(() => {
    setSubject('');
    setMessage('');
    setAttachment({ file: null });
  }, []);

  const removeAnswer = useCallback(() => {
    selectResponseToAnswer(undefined);
  }, [selectResponseToAnswer]);
  
  const writeEmojiOnInput = useCallback((emoji: BaseEmoji) => {
    setMessage(old => {
      const startOfStr = old.substring(0, refLastCursorPos.current);
      const endOfStr = old.substring(refLastCursorPos.current);

      const result = startOfStr + emoji.native + endOfStr;
      refLastCursorPos.current += emoji.native.length;
      return result;
    });
  }, [refLastCursorPos]);

  const calculateMessageFormHeight = useCallback(() => {
    let extraHeight = 0;
    if (props.type === 'email') {
      extraHeight += 34;
    }

    if (responseToAnswer) {
      extraHeight += 64;
    }
    
    return `calc(100% - ${extraHeight}px)`;
  }, [props.type, responseToAnswer]);
  
  const handleSendMessage = useCallback(() => {
    if (personId && !statusSendButton.disable && props.type) {
      setSendInProgress(true);
      addSendMessage(personId, subject, (message.length > 0 ? message : null), props.type, attachment.info, responseToAnswer).then((result) => {
        if (result) {
          clearForm();
          removeAnswer();
          window.setTimeout(props.handleScrollOnSend, 100);
        }
        setSendInProgress(false);
      }).catch(() => {
        removeAnswer();
        setSendInProgress(false);
      })
    }
  }, [
    addSendMessage,
    attachment.info,
    clearForm,
    message,
    personId,
    props,
    removeAnswer,
    responseToAnswer,
    statusSendButton.disable,
    subject,
  ]);

  useEffect(() => {
    if (refFormBody.current) refFormBody.current.style.transition = "all .5s ease";
  }, []);
  
  useEffect(() => {
    setMessage(
      responseToAnswer?.suggestion && responseToAnswer.suggestion.length > 0 
        ? responseToAnswer.suggestion 
        : ''
    );
  }, [responseToAnswer]);
  
  useEffect(() => {
    if (refMessageTextInput.current) {
      setTimeout(() => {
        const { clientHeight } = (refMessageTextInput.current || { clientHeight: 0 })
        setHeaderVisible(clientHeight < 350);
      }, 1000)
    }
  }, [message.length, setHeaderVisible]);

  useEffect(() => {
    if (personId) {
      setMessage('');
      setSubject('');
      setEmojiPickerVisible(false);
    }
  }, [personId]);

  return (
    <Component>
      <Content ref={refFormBody}>
        {
          responseToAnswer?.questionText &&
          <AnswerTo
            text={getTextFromHTMLString(responseToAnswer!.questionText)}
            onClose={removeAnswer}
          />
        }
        {
          props.type === 'email' &&
          <>
            <Line>
              <Typography style={{ fontWeight: 'bold', fontSize: 14, color: "#222" }}>Assunto: </Typography>
              <TextInput
                onChange={({ target }) => setSubject(target.value)}
                placeholder="Escreva o assunto da mensagem"
                style={{
                  fontSize: 14,
                  maxHeight: 20,
                  opacity: sendInProgress ? 0.6 : 1,
                }}
                value={subject}
              />
            </Line>
            <Divider />
          </>
        }
        {
          refMessageTextInput.current && (
            <EmojiPickerPopup
              open={emojiPickerVisible}
              onEmojiPick={writeEmojiOnInput}
            />
          )
        }
        <TextInput
          autoFocus
          disabled={isTextInputDisabled}
          multiline
          onChange={({ target }) => setMessage(target.value)}
          onSelect={({ target }) => {
            // @ts-ignore
            refLastCursorPos.current = target.selectionStart;
          }}
          placeholder="Escreva o texto da mensagem"
          ref={refMessageTextInput}
          startAdornment={
            <EmojiAdornment
              active={emojiPickerVisible}
              onClick={() => setEmojiPickerVisible(old => !old)}
            />
          }
          endAdornment={
            props.type === 'whats'
              ? <AttachmentAdornment {...{ attachment, setAttachment }} />
              : undefined
          }
          style={{
            fontSize: 14,
            opacity: sendInProgress ? 0.6 : 1,
            height: calculateMessageFormHeight(),
          }}
          inputProps={{
            style: {
              maxHeight: '100%',
              overflow: 'auto',
            },
          }}
          value={message}
        />
      </Content>
      <ButtonArea>
        <Tooltip title={statusSendButton.tooltip}>
          <IconButton
            onClick={handleSendMessage}
            disableRipple={statusSendButton.disable}
            style={{
              padding: 3,
              cursor: statusSendButton.disable ? 'not-allowed' : 'pointer',
            }}
          >
            <SendIcon style={{ color }} />
          </IconButton>
        </Tooltip>
      </ButtonArea>
    </Component>
  );
}

/**
 * Componente de input de texto para escrita de uma mensagem,
 * seja ela de tipo whats, sms ou email.
 */
export default memo(MessageInputForm);
