import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';


import { AppProvider } from './contexts/AppContext';
import { FeedbackProvider } from './contexts/FeedbackContext';

import {
  Home,
} from './pages'

import { GlobalStyle } from './components';

document.title = 'Infogab - Módulo de Comunicação';

ReactDOM.render(
  <AppProvider>
    <FeedbackProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} />
        </Switch>
      </BrowserRouter>
      <GlobalStyle />
    </FeedbackProvider>
  </AppProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
