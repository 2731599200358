import styled from 'styled-components';

export const Component = styled.div`
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 250px;
  height: 50px;
  align-items: center;
  box-shadow: 0 0 8px #222;
`;