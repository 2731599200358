import styled from 'styled-components';

interface LineProps {
  position: 'right' | 'left';
}

export const Component = styled.div<LineProps>`
  position: relative;
  border-radius: 5px;
  min-height: 100px;
  min-width: 250px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  ${props => props.position === 'left'
    ? 'margin-left: -10px'
    : 'margin-right: -10px'
  };
  box-shadow: 0 0 8px #222;
`;

export const Body = styled.div`
  width: calc(100% - 24px);
  padding: 12px;
  background-color: #fff;
  min-height: 40px;
  border-radius: 0 0 5px 5px;
`

export const Line = styled.div<LineProps>`
  display: flex;
  flex-direction: column;
  margin: 15px 35px;
  justify-content: center;
  align-items: ${props => props.position === 'right' ? 'flex-end' : 'flex-start'};
`

export const Group = styled.div`
  display: flex;
  flex-direction: row;
`

interface TypeDecoratorProps {
  type: 'sms' | 'whats' | 'email';
}

export const LeftTypeDecorator = styled.div<TypeDecoratorProps>`
  background-color: ${props =>
    props.type === 'email' ? '#D34F12' :
      props.type === 'whats' ? '#12D36D' :
        '#0E7CD7'
  };
  height: calc(100% - 20px);
  width: 44px;
  border-radius: 5px;
  box-shadow: 0 0 8px #222;
  padding-top: 20px;
  padding-left: 8px;
  z-index: -10;
`;

export const RightTypeDecorator = styled.div<TypeDecoratorProps>`
  background-color: ${props =>
    props.type === 'email' ? '#D34F12' :
      props.type === 'whats' ? '#12D36D' :
        '#0E7CD7'
  };
  height: calc(100% - 20px);
  width: 15px;
  border-radius: 5px;
  box-shadow: 0 0 8px #222;
  padding-top: 20px;
  padding-left: 8px;
  z-index: -10;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

interface BottomAppendProps {
  width?: number;
}

export const BottomAppend = styled.div<BottomAppendProps>`
  display: flex;
  flex-direction: column;
  background-color: #374049;
  width: ${props => props.width ? props.width+'px' : '100%'};
  align-items: center;
  box-shadow: 0 0 2px #222;
  z-index: 0;
  border-radius: 0 0 4px 4px;
`;

interface AppendLineProps {
  light?: boolean;
}

export const AppendLine = styled.div<AppendLineProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: .5s;
  border-top: 1px solid #3D464E;
  width: calc(95% - 16px);
  max-width: calc(95% - 16px);
  max-height: 38px;
  cursor: pointer;
  padding: 4px 8px;
  color: ${props => props.light ? '#EEE' : '#7B8187'};
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: clip;
  :hover {
    color: ${props => props.light ? '#EEE' : '#9DA3A9'};
  }
`;