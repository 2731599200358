import React from 'react'

import LoadingChatItem from './components/LoadingChatItem'

export default function LazyChatList() {
  return (
    <>
      <LoadingChatItem key="temp-1" />
      <LoadingChatItem key="temp-2" />
      <LoadingChatItem key="temp-3" />
      <LoadingChatItem key="temp-4" />
      <LoadingChatItem key="temp-5" />
      <LoadingChatItem key="temp-6" />
      <LoadingChatItem key="temp-7" />
      <LoadingChatItem key="temp-8" />
    </>
  )
}