import styled from "styled-components";

import Avatar from "@material-ui/core/Avatar";

import { avatarColors } from "./constants";

interface NoPhotoAvatarProps {
  alt: string;
}

export const NoPhotoAvatar = styled(Avatar)<NoPhotoAvatarProps>`
  && {
    background-color: ${props => avatarColors[props.alt.substring(0, 1)] || '#4e89ae'}
  }
`;