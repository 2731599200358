import Handler, { ResultHandler } from "./Handler";

export default class SetQualificationToAMessageHandler extends Handler {
  private token: string;
  private messageIdentifier: string;
  private qualification?: string | null;

  constructor(token: string, messageIdentifier: string) {
    super();
    this.token = token;
    this.messageIdentifier = messageIdentifier;
  }

  setQualification(qualification?: string) {
    if (qualification) {
      this.qualification = qualification;
    } else this.qualification = null;
  }

  public async handle(): Promise<ResultHandler> {
    try {
      const { status } = await this.request.post(
        `${this.baseURL}/message/${this.messageIdentifier}/qualify/${this.qualification}/${this.token}`,
      );
      if (status === 200) {
        return { result: true };
      };

      return { result: false };
    } catch(error) {
      console.error(error);
      return { result: false, error: String(error) };
    }
  }
}