import React, {
  useCallback,
  useContext,
  memo,
  useMemo,
  useEffect,
  useState,
} from 'react';

import { ChatFilter } from '../../../../../../../../api/resources/GetListChatResource';

import {
  ChatListContext,
  ChatFilterContext,
  AppContext,
} from '../../../../../../../../contexts';

import { Dialog, CircleButton, CheckBox, TextField, DateTextField, Button, AutoComplete } from '../../../../../../..';

import { Block, Title, Group, Divider, Line, CheckBoxGroup } from './styles';

import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

interface ChatFilterDialogProps {
  open: boolean;
  show: (value: boolean) => void;
  setFilterCounter: (value: number) => void;
}

interface Item {
  id: number;
  name: string;
}

function ChatFilterDialog(props: ChatFilterDialogProps) {
  
  const { isCityCouncilor } = useContext(AppContext);
  const { setActiveFilter } = useContext(ChatListContext);

  const {
    getRegionsList,
    getDiocesesList,
    getCitiesList,
    getNeighborhoodList,
    getColors,
  } = useContext(ChatFilterContext);

  const handleClose = useCallback(() => {
    props.show(false);
  }, [props]);

  const [fieldName, changeFieldName] = useState('');
  const [fieldCode, changeFieldCode] = useState('');

  const [fieldColors, setFieldColors] = useState<number[]>([]);
  const changeFieldColor = useCallback((code: number) => {
    setFieldColors(old => {
      if (old.includes(code))
        return old.filter(item => code !== item)
      return [...old, code]
    })
  }, []);

  const [fieldRegions, setFieldRegions] = useState<Item[]>([]);
  const changeFieldRegions = useCallback((code: Item) => {
    setFieldRegions(old => {
      if (old.includes(code))
        return old.filter(item => code !== item)
      return [...old, code]
    })
  }, []);

  const [fieldDioceses, setFieldDioceses] = useState<Item[]>([]);
  const changeFieldDioceses = useCallback((code: Item) => {
    setFieldDioceses(old => {
      if (old.includes(code))
        return old.filter(item => code !== item)
      return [...old, code]
    })
  }, []);

  const [fieldCities, setFieldCities] = useState<Item[]>([]);
  const changeFieldCities = useCallback((code: Item) => {
    setFieldCities(old => {
      if (old.includes(code))
        return old.filter(item => code !== item)
      return [...old, code]
    })
  }, []);

  const [fieldNeighborhood, setFieldNeighborhood] = useState<Item[]>([]);
  const changeFieldNeighborhood = useCallback((code: Item) => {
    setFieldNeighborhood(old => {
      if (old.includes(code))
        return old.filter(item => code !== item)
      return [...old, code]
    })
  }, []);

  const [fieldCampaignNames, setFieldCampaignNames] = useState('');
  const [fieldCampaignIds, setFieldCampaignIds] = useState('');
  const [fieldMessage, setFieldMessage] = useState('');
  const [fieldNote, setFieldNote] = useState('');
  const [fieldSuggestion, setFieldSuggestion] = useState('');
  const [fieldStartDate, setStartDate] = useState<Date | null>(null);
  const [fieldEndDate, setEndDate] = useState<Date | null>(null);

  const [checkMessageStatus, setMessageStatus] = useState<number[]>([]);
  const [checkDemandLevels, setDemandLevels] = useState<number[]>([]);

  const toggleMessagesStatus = useCallback((code: number) => {
    if (code === 4) {
      setDemandLevels(old => {
        if (old.length === 3) return [];
        return [0, 1, 2];
      })
    }
    setMessageStatus(old => {
    if (old.includes(code))
        return old.filter(item => code !== item)
      return [...old, code]
    })
  }, []);

  const toggleDemandLevels = useCallback((level: number) => {
    setDemandLevels(old => {
      if (old.includes(level))
        return old.filter(item => level !== item)
      return [...old, level]
    })
  }, []);

  const [checkAddedToHistory, setAddedToHistory] = useState(false);
  const [checkWithNote, setWithNote] = useState(false);
  const [checkWithSuggestion, setWithSuggestion] = useState(false);

  const handleFilter = useCallback(() => {
    let counter = 0;
    const filter: ChatFilter = {
      addedToHistory: false,
      withNote: false,
      withSuggestion: false,
      startDate: null,
      endDate: null,
    };

    if (fieldName.length !== 0) {
      filter['peopleNames'] = fieldName.split(',').map(item => item.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "")).filter(item => item !== '')
      counter++;
    }

    if (fieldCode.length !== 0) {
      filter['peopleIds'] = fieldCode.split(',').map(item => Number(item)).filter(item => item ? true : false)
      counter++;
    }

    if (fieldColors.length !== 0) {
      filter['colors'] = fieldColors
      counter += fieldColors.length;
    }

    if (fieldRegions.length !== 0) {
      filter['regions'] = fieldRegions.map(item => item.id)
      counter++;
    }

    if (fieldDioceses.length !== 0) {
      filter['dioceses'] = fieldDioceses.map(item => item.id)
      counter++;
    }

    if (fieldCities.length !== 0) {
      filter['cities'] = fieldCities.map(item => item.id)
      counter++;
    }

    if (fieldNeighborhood.length !== 0) {
      filter['neighborhood'] = fieldNeighborhood.map(item => item.id)
      counter++;
    }

    if (fieldCampaignNames.length !== 0) {
      filter['campaignsNames'] = fieldCampaignNames.split(',').map(item => item.trim()).filter(item => item !== '')
      counter++;
    }

    if (fieldCampaignIds.length !== 0) {
      filter['campaignsIds'] = fieldCampaignIds.split(',').map(item => Number(item)).filter(item => item ? true : false)
      counter++;
    }

    if (fieldMessage.length !== 0) {
      filter['message'] = fieldMessage
      counter++;
    }

    if (checkMessageStatus.length !== 0) {
      filter['messageStatus'] = checkMessageStatus
      counter += checkMessageStatus.length;
    }

    if (checkDemandLevels.length !== 0) {
      filter['demandLevels'] = checkDemandLevels
      counter += checkDemandLevels.length;
    }

    if (checkAddedToHistory) {
      filter['addedToHistory'] = true
      counter++;
    }

    if (checkWithNote) {
      filter['withNote'] = true
      counter++;
    }

    if (checkWithSuggestion) {
      filter['withSuggestion'] = true
      counter++;
    }

    if (fieldNote.length !== 0) {
      filter['note'] = fieldNote
      counter++;
    }

    if (fieldSuggestion.length !== 0) {
      filter['suggestion'] = fieldSuggestion
      counter++;
    }

    if (fieldStartDate) {
      filter['startDate'] = fieldStartDate
      counter++;
    }

    if (fieldEndDate) {
      filter['endDate'] = fieldEndDate
      counter++;
    }

    setActiveFilter(old => ({ ...old, ...filter}));

    props.setFilterCounter(counter);
    props.show(false);
  }, [
    checkAddedToHistory,
    checkDemandLevels,
    checkMessageStatus,
    checkWithNote,
    checkWithSuggestion,
    fieldCampaignIds,
    fieldCampaignNames,
    fieldCities,
    fieldCode,
    fieldColors,
    fieldDioceses,
    fieldEndDate,
    fieldMessage,
    fieldName,
    fieldNeighborhood,
    fieldNote,
    fieldRegions,
    fieldStartDate,
    fieldSuggestion,
    setActiveFilter,
    props
  ]);

  const clearFilter = useCallback(() => {
    changeFieldName('');
    changeFieldCode('');
    setFieldColors([]);
    setFieldRegions([]);
    setFieldDioceses([]);
    setFieldCities([]);
    setFieldNeighborhood([]);
    setFieldCampaignIds('');
    setFieldCampaignNames('');
    setFieldMessage('');
    setMessageStatus([]);
    setAddedToHistory(false);
    setWithNote(false);
    setWithSuggestion(false);
    setFieldNote('');
    setFieldSuggestion('');
    setStartDate(null);
    setEndDate(null);
    props.setFilterCounter(0);
    setActiveFilter({
      addedToHistory: false,
      withNote: false,
      withSuggestion: false,
      startDate: null,
      endDate: null,
    });

    handleClose();
  }, [handleClose, props, setActiveFilter]);

  const regionsList = useMemo(() => {
    return getRegionsList()
      .map(({ id, name }) => ({ id, name }));
  }, [getRegionsList]);

  const diocesesList = useMemo(() => {
    return getDiocesesList({ regions: fieldRegions.map(item => item.id) })
      .map(({ id, name }) => ({ id, name }))
  }, [fieldRegions, getDiocesesList]);

  const citiesList = useMemo(() => {
    return getCitiesList({
      regions: fieldRegions.map(item => item.id),
      dioceses: fieldDioceses.map(item => item.id),
    }).map(({ id, name }) => ({ id, name }));
  }, [fieldDioceses, fieldRegions, getCitiesList]);

  const [neighborhoodList, setNeighborhoodList] = useState<Item[]>([]);

  useEffect(() => {
    if (isCityCouncilor) {
      getNeighborhoodList({
        cities: fieldCities.map(item => item.id),
      }).then(result => {
        setNeighborhoodList(result);
      })
    }
  }, [fieldCities, getNeighborhoodList, isCityCouncilor]);

  return (
    <Dialog
      title="Filtrar"
      onClose={handleClose}
      open={props.open}
      width={670}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15,
      }}>
        <Block width={190} height={200}>
          <Title>Pessoa</Title>
          <Group>
            <TextField
              value={fieldName}
              onChange={({ target }) => changeFieldName(target.value)}
              placeholder="Nome"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: 18 }} />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              value={fieldCode}
              onChange={({ target }) => changeFieldCode(target.value)}
              placeholder="Código"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: 18 }} />
                  </InputAdornment>
                )
              }}
            />
            <Line>
              {
                getColors().map((item, index) => (
                  <CircleButton
                    key={`circle-button-${index}=${item.label}`}
                    style={{
                      backgroundColor: item.hex,
                      height: 18,
                      width: 18,
                      margin: 3,
                    }}
                    selected={fieldColors.includes(item.id)}
                    onClick={() => changeFieldColor(item.id)}
                  />
                ))
              }
            </Line>
          </Group>
        </Block>
        <Block width={190} height={200}>
          <Title>Localização</Title>
          <Group>
            {
              !isCityCouncilor &&
              <>
                <AutoComplete
                  onSelect={changeFieldRegions}
                  onDesselect={changeFieldRegions}
                  placeholder="Região"
                  options={regionsList}
                  getOptionLabel={(item) => item.name}
                  value={fieldRegions}
                />
                <AutoComplete
                  onSelect={changeFieldDioceses}
                  onDesselect={changeFieldDioceses}
                  placeholder="Diocese"
                  options={diocesesList}
                  getOptionLabel={(item) => item.name}
                  value={fieldDioceses}
                />
              </>
            }
            <AutoComplete
              onSelect={changeFieldCities}
              onDesselect={changeFieldCities}
              placeholder="Cidade"
              options={citiesList}
              getOptionLabel={(item) => item.name}
              value={fieldCities}
            />
            {
              isCityCouncilor &&
              <AutoComplete
                disabled={fieldCities.length === 0}
                onSelect={changeFieldNeighborhood}
                onDesselect={changeFieldNeighborhood}
                placeholder="Bairro"
                options={neighborhoodList}
                getOptionLabel={(item) => item.name}
                value={fieldNeighborhood}
              />
            }
          </Group>
        </Block>
        <Block width={190} height={200}>
          <Title>Campanha</Title>
          <Group>
            <TextField
              value={fieldCampaignNames}
              onChange={({ target }) => setFieldCampaignNames(target.value)}
              placeholder="Descrição"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: 18 }} />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              value={fieldCampaignIds}
              onChange={({ target }) => setFieldCampaignIds(target.value)}
              placeholder="Código"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: 18 }} />
                  </InputAdornment>
                )
              }}
            />
          </Group>
        </Block>
      </div>
      <Block width={650} height={240}>
        <Title>Mensagem</Title>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
          <Group>
            <TextField
              value={fieldMessage}
              onChange={({ target }) => setFieldMessage(target.value)}
              placeholder="Palavra-chave"
              style={{ width: 180 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: 18 }} />
                  </InputAdornment>
                )
              }}
            />
            <CheckBoxGroup>
              <CheckBox
                label="Demanda aberta"
                onClick={() => toggleMessagesStatus(4)}
                checked={checkDemandLevels.length === 3}
                indeterminate={checkDemandLevels.length > 0 && checkDemandLevels.length < 3}
              />
              <CheckBox
                label="Demanda sem nível"
                className="level-checkbox"
                onClick={() => toggleDemandLevels(0)}
                checked={checkDemandLevels.includes(0)}
              />
              <CheckBox
                label="Demanda de nível 1"
                className="level-checkbox"
                onClick={() => toggleDemandLevels(1)}            
                checked={checkDemandLevels.includes(1)}
              />
              <CheckBox
                label="Demanda de nível 2"
                className="level-checkbox"
                onClick={() => toggleDemandLevels(2)}
                checked={checkDemandLevels.includes(2)}
              />
              <CheckBox
                label="Demanda fechada"
                onClick={() => toggleMessagesStatus(5)}
                checked={checkMessageStatus.includes(5)}
              />
            </CheckBoxGroup>
          </Group>
          <Divider height={200} />
          <Group>
            <CheckBox
              label="Adicionada no histórico"
              onClick={() => setAddedToHistory(old => !old)}
              checked={checkAddedToHistory}
            />
            <CheckBox
              style={{ padding: '2px 0' }}
              label="Não lidas"
              onClick={() => toggleMessagesStatus(1)}
              checked={checkMessageStatus.includes(1)}
            />
            <CheckBox
              style={{ padding: '2px 0' }}
              label="Lidas"
              onClick={() => toggleMessagesStatus(2)}
              checked={checkMessageStatus.includes(2)}
            />
            <CheckBox
              style={{ padding: '2px 0' }}
              label="Com observação"
              onClick={() => setWithNote(old => !old)}
              checked={checkWithNote}
            />
            <CheckBox
              style={{ padding: '2px 0' }}
              label="Com sugestão de resposta"
              onClick={() => setWithSuggestion(old => !old)}
              checked={checkWithSuggestion}
            />
          </Group>
          <Divider height={200} />
          <Group>
            <TextField
              value={fieldNote}
              onChange={({ target }) => setFieldNote(target.value)}
              style={{ width: 180 }}
              placeholder="Observação"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: 18 }} />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              value={fieldSuggestion}
              onChange={({ target }) => setFieldSuggestion(target.value)}
              placeholder="Sugest. de resposta"
              style={{ width: 180 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: 18 }} />
                  </InputAdornment>
                )
              }}
            />
            <DateTextField
              value={fieldStartDate}
              onChange={setStartDate}
              placeholder="De"
              style={{ width: 180 }}
            />
            <DateTextField
              value={fieldEndDate}
              onChange={setEndDate}
              placeholder="Até"
              style={{ width: 180 }}
            />
          </Group>
        </div>
      </Block>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          color="#000"
          style={{
            margin: "10px 15px",
            width: 100,
          }}
          onClick={clearFilter}
        >
          Limpar
        </Button>
        <Button
          color="#fff"
          backgroundColor="#0B70D2"
          style={{
            margin: "10px 15px",
            width: 100,
          }}
          onClick={handleFilter}
        >
          Filtrar
        </Button>
      </div>
    </Dialog>
  );
}

export default memo(ChatFilterDialog);
