import React, { 
  useContext,
  useCallback
} from 'react';

import Typography from '@material-ui/core/Typography';

import { Component, Line } from './styles';
import {
  MessageGroupContext,
  MessageContext,
} from '../../../../../../../../../../contexts';

interface JoinMessagesProps {
  isHovered: boolean;
}

export default function JoinMessages(props: JoinMessagesProps) {

  const { setJoinMessagesInProgress } = useContext(MessageGroupContext);

  const { message } = useContext(MessageContext);

  const startJoinMessages = useCallback(() => {
    setJoinMessagesInProgress({
      date: message.timestamp!,
      type: message.type,
    });
  }, [message.timestamp, message.type, setJoinMessagesInProgress]);

  return (
    <Component 
      show={props.isHovered}
      onClick={startJoinMessages}
    >
      <Line width="calc(50% - 70px)" />
      <Typography style={{
        width: 140,
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 'bold',
        margin: '0 5px',
        textAlign: 'center',
      }}>
        Juntar mensagens
      </Typography>
      <Line width="calc(50% - 70px)" />
    </Component>
  );
}