import styled from "styled-components";

export const AvatarContainer = styled.div`
  && {
    position: relative;
    
    &:hover {
      cursor: pointer;
    }
  }
`;

interface ProfileColorProps {
  color: string;
}

export const ProfileColor = styled.div<ProfileColorProps>`
  && {
    position: absolute;
    bottom: -3px;
    right: -3px;
    background-color: ${props => props.color};
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 3px solid #2B323B;
  }
`;
