import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  memo
} from 'react';

import 'moment/locale/pt-br';
import moment from 'moment';

import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';

import ErrorIcon from '@material-ui/icons/Error';
import SearchIcon from '@material-ui/icons/Search';

import { MessageProvider } from '../../../../../../contexts/MessageContext';

import ChatContext, { IMessage } from '../../../../../../contexts/ChatContext';
import MessageGroupContext from '../../../../../../contexts/MessageGroupContext';

import { Loading } from '../../../../../';

import Message from './components/Message';
import ConfirmJoinMessagesDialog from './components/ConfirmJoinMessagesDialog';
import AnswerForm from './components/AnswerForm';
import MessageFilter from './components/MessageFilter';

import {
  Component,
  FloatingBox,
} from './styles';

function ChatBody() {
  const refContainer = useRef<HTMLDivElement>(null);
  const scrollPosition = useRef<number>(0);

  const { joinMessagesInProgress } = useContext(MessageGroupContext);

  const {
    showFilters, setShowFilters,
    isFilterActive, setFilterActive,
    numberOfFilters, setNumberOfFilters,
    getMoreChatMessages,
    chatMessages,
    personId,
  } = useContext(ChatContext);

  const [isInitialLoading, setInitialLoading] = useState<boolean>(true);

  const handleOpenFilter = useCallback(() => {
    setShowFilters(true);
  }, [setShowFilters]);

  useEffect(() => {
    if (refContainer.current) {
      scrollPosition.current = refContainer.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    if (chatMessages.status === 'not-initialized') {
      setInitialLoading(true);
    } else if (refContainer.current && isInitialLoading) {
      refContainer.current.scroll(0, refContainer.current.scrollHeight);
      setInitialLoading(false);
    }
  }, [chatMessages.status, isInitialLoading]);

  const onScrollHandle = useCallback((event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (refContainer.current &&
      scrollPosition.current > event.currentTarget.scrollTop &&
      event.currentTarget.scrollTop < 100) {

      const oldHeight = refContainer.current.scrollHeight;
      getMoreChatMessages().then(() => {
        if (refContainer.current) {
          const newHeight = refContainer.current.scrollHeight;
          const offset = newHeight - oldHeight;
          scrollPosition.current += offset;
          refContainer.current.scrollTop = scrollPosition.current;
        }
      });
    }
    scrollPosition.current = event.currentTarget.scrollTop;
  }, [getMoreChatMessages]);

  const isMessageJoinable = useCallback((message: IMessage, current: IMessage) => {
    let diffTime = 20;
    if (current.timestamp && message.timestamp) {
      diffTime = moment(current.timestamp.toString())
        .diff(message.timestamp, 'hours');
    }
    return (
      message.origin === 'received' &&
      message.type === current.type &&
      Math.abs(diffTime) < 12
    );
  }, []);

  return (
    <>
      <Component
        ref={refContainer}
        onScroll={onScrollHandle}
      >
        {
          chatMessages.status === 'not-initialized' ? (
            <Loading color="#eee" />
          ) : chatMessages.status === 'error' ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: 'calc(100% - 100px)',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ErrorIcon
                color="primary"
                style={{
                  width: 180,
                  height: 180,
                  color: '#000',
                  opacity: 0.2,
                }}
              />
              <Typography
                variant="h5"
                style={{
                  color: '#FAFAFA',
                }}
              >
                Ops... algo aconteceu de errado
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: '#FAFAFA',
                  marginTop: '0.8rem',
                  textAlign: 'center',
                }}
              >
                Clique novamente em uma conversa para<br /> visualizar as mensagens
            </Typography>
            </div>
          ) : (
                chatMessages.items.map((item, index) => {
                  let nextJoin = false;
                  if (index < chatMessages.items.length - 1) {
                    nextJoin = isMessageJoinable(chatMessages.items[index + 1], item);
                  }

                  return (
                    <MessageProvider
                      key={`${item.id}-${index}`}
                      message={item}
                    >
                      <Message
                        {...item}
                        isNextMessageJoinable={nextJoin}
                      />
                    </MessageProvider>
                  );
                })
              )
        }
      </Component>
      <FloatingBox>
        <Badge
          badgeContent={numberOfFilters}
          color="secondary"
          overlap="circle"
        >
          <Fab aria-label="Pesquisar" onClick={handleOpenFilter}>
            <Tooltip title="Pesquisar">
              <SearchIcon />
            </Tooltip>
          </Fab>
        </Badge>
        <Zoom in={showFilters}>
          <MessageFilter
            open={showFilters}
            onClose={() => setShowFilters(false)}
            personId={personId}
            isActive={isFilterActive}
            setIsActive={setFilterActive}
            setNumberOfFilters={setNumberOfFilters}
          />
        </Zoom>
        {
          personId &&
          <AnswerForm refChat={refContainer} />
        }
      </FloatingBox>
      {
        joinMessagesInProgress &&
        <ConfirmJoinMessagesDialog />
      }
    </>
  );
}

export default memo(ChatBody);
