import React, {
  useContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { Profile } from '../../../../../../../../api/resources/GetProfileResource';

import { ChatContext } from '../../../../../../../../contexts';

import ProfileAvatar from './components/ProfileAvatar';
import ProfileDialog from './components/ProfileDialog';

import {
  AvatarContainer,
  ProfileColor,
} from './styles'

interface ChatAvatarProps {
  profile: Profile;
}

function ChatAvatar(props: ChatAvatarProps) {
  const { currentChat, setPersonBindDialogVisible } = useContext(ChatContext);

  const [isProfileDialogVisible, setProfileDialogVisible] = useState<boolean>(false);

  const color = useMemo(() => {
    switch (props.profile.color) {
      case 'Verde': return 'green';
      case 'Amarelo': return 'yellow';
      case 'Cinza': return 'grey';
      case 'Azul': return 'blue';
      case 'Roxo': return 'purple';
      case 'Vermelho': return 'red';
      case 'Laranja': return 'orange';
      case 'Preto': return 'black';
      default: return undefined;
    }
  }, [props.profile.color]);

  const onAvatarClick = useCallback(() => {
    if (props.profile.contact === currentChat.replace('-null', '')) {
      return setPersonBindDialogVisible(true);
    }
    setProfileDialogVisible(true);
  }, [currentChat, props.profile.contact, setPersonBindDialogVisible]);

  return (
    <>
      <AvatarContainer onClick={onAvatarClick}>
        <ProfileAvatar photo={props.profile.photo} name={props.profile.contact} />
        {color && <ProfileColor color={color} className="profile-color" />}
      </AvatarContainer>
      <ProfileDialog
        visible={isProfileDialogVisible}
        onClose={() => setProfileDialogVisible(false)}
        data={props.profile}
      />
    </>
  )
}

export default ChatAvatar;