import React, {
  useCallback,
  useState,
  useContext,
  useEffect
} from 'react';

import {
  ChatContext,
  ChatListContext
} from '../../../../../../contexts';

import { Dialog, Table, Button, TextField } from '../../../../../../components';

interface BindPersonToAChatDialogProps {
  open: boolean;
  show: (value: boolean) => void;
  chatId: string;
}

interface PersonDTO {
  'Código': number;
  'Nome completo': string;
  'emailId'?: number;
  'phoneId'?: number;
}

export default function BindPersonToAChatDialog(props: BindPersonToAChatDialogProps) {

  const [searchValue, setSearchValue] = useState('');
  const [currentRow, setCurrentRow] = useState<PersonDTO>({} as PersonDTO);
  const [tableLines, setTableLines] = useState<PersonDTO[]>([]);

  const { getPeopleList } = useContext(ChatContext);
  const { bindPersonToAChat } = useContext(ChatListContext);

  useEffect(() => {
    if (props.open) {
      getPeopleList(props.chatId).then(persons => {
        const lines = persons.map(item => ({
          'Código': item.id,
          'Nome completo': item.name,
          'emailId': item?.emailId,
          'phoneId': item?.phoneId
        }));
        setTableLines(lines);
      });
    }
  }, [getPeopleList, props.chatId, props.open]);

  const handleClose = useCallback(() => {
    props.show(false);
  }, [props]);

  const handleConfirmBindPersonToAChat = useCallback(() => {
    bindPersonToAChat(props.chatId, currentRow['Código'], currentRow['phoneId'], currentRow['emailId']).then(result => {
      if (result) {
        props.show(false);
      }
    });
  }, [bindPersonToAChat, currentRow, props]);

  return (
    <Dialog
      title="Vincular pessoa"
      onClose={handleClose}
      open={props.open}
      width={550}
    >
      <TextField
        style={{ width: 550, margin: '15px 0' }}
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        size="small"
        placeholder="Procurar por ..."
      />
      <Table
        selectedItems={[currentRow]}
        setSelectedItem={setCurrentRow}
        index="Código"
        headers={[
          { name: 'Código', size: 50, align: 'right' },
          { name: 'Nome completo', align: 'right' },
        ]}
        items={
          tableLines.filter(item => (
            item['Código'].toString().toLowerCase().includes(searchValue.toLowerCase()) ||
            item['Campanha'].toLowerCase().includes(searchValue.toLowerCase())
          ))
        }
        hideColumns={['idpessoa', 'idtelefone', 'idemail']}
      />
      <div style={{
        display: 'flex',
        marginTop: 15,
        flexDirection: 'row',
      }}>
        <Button
          color="#fff"
          backgroundColor="#0B70D2"
          onClick={() => handleConfirmBindPersonToAChat()}
          disabled={currentRow['Código'] === undefined ? true : false}
          style={{
            width: 120,
            margin: '0 10px',
          }}
        >
          Confirmar
        </Button>
        <Button
          onClick={() => handleClose()}
          backgroundColor="transparent"
          color="#515A64"
          style={{
            width: 100,
            margin: '0 10px',
          }}
        >
          Cancelar
        </Button>
      </div>
    </Dialog>
  );
}
