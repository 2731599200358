import React, {
  memo,
  useContext,
} from 'react';

import { ChatContext } from '../../../../contexts';

import {
  SideBar,
  MessagePanel,
} from '../../../../components';

import BindPersonToAChatDialog from './components/BindPersonToAChatDialog';

function AppScreen() {
  const {
    currentChat,
    isPersonBindDialogVisible,
    setPersonBindDialogVisible,
  } = useContext(ChatContext);
  
  return (
    <>
      <SideBar />
      <MessagePanel />
      <BindPersonToAChatDialog
        open={isPersonBindDialogVisible}
        show={setPersonBindDialogVisible}
        chatId={currentChat}
      />
    </>
  );
}

export default memo(AppScreen);