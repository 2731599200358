/**
 * Função que decodifica codificações de HTML
 * @param str String a ser decodificada
 * @returns {string} String decodificada
 */
function decodeEntities(str: string): string {
  let temp: HTMLParagraphElement | null = document.createElement('p');
  temp.innerHTML= str;
  const result: string = temp.textContent || temp.innerText;
  temp = null;
  return result;
}

/**
 * Função que retira todo o html de uma string, mantendo apenas o conteúdo de texto
 * @param string String com html para retirar as tags retornando apenas o texto
 * @returns {string} Conteúdo de texto
 */
export const getTextFromHTMLString = (string: string) => {
  const tags = string.match(/<([^>]+)>/gi)
  if (tags) {
    const content = string.replace(/<([^>]+)>/g, '')
    return decodeEntities(content);
  }
  return string;
}

/**
 * Função que trunca uma string limpando o caracter especial mais proximo do limite caracteres requisitado.
 * @param str String a ser truncada
 * @param truncateLen Número de caracteres para truncar
 * @param searchOffset Margem máxima de caracteres em que o truncamento permite buscar o caracter especial, por padrão 10 caracteres
 *
 * O truncamento irá achar o caracter especial mais proximo de `truncateLen`
 * e cortar o texto no caracter anterior a ele.
 * Caso não ache um caracter dentro da margem, truncará usando tamanho especificado.
 */
export const truncateString = (str: string, truncateLen: number, searchOffset = 10) => {
  const nonWordsRegex = '[^a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9]';
  if (str.length < truncateLen) {
    return str
  }

  const leftSide = str.substring(0, truncateLen);
  const rightSide = str.substring(truncateLen);
  const leftSideNonWords = leftSide.match(new RegExp(nonWordsRegex, 'g')) || [];
  const leftSideNonWord = leftSideNonWords.length > 0 ? leftSideNonWords[leftSideNonWords.length - 1] : undefined
  const [rightSideNonWord] = rightSide.match(new RegExp(nonWordsRegex)) || [];

  const leftSideIndex = leftSideNonWord ? leftSide.lastIndexOf(leftSideNonWord) : -1
  const rightSideIndex = rightSideNonWord ? rightSide.indexOf(rightSideNonWord) : -1

  let result: string;
  if (rightSideIndex > -1) {
    const finalIndex = rightSideIndex < searchOffset ? rightSideIndex + truncateLen : truncateLen + searchOffset
    result = str.substring(0, finalIndex)
  } else if (leftSideIndex > -1) {
    const leftSearchOffset = leftSide.length - searchOffset
    const finalIndex = leftSideIndex > leftSearchOffset ? leftSideIndex : truncateLen
    result = str.substring(0, finalIndex)
  } else {
    result = str.substring(0, truncateLen)
  }

  return result + '...';
}