import { AxiosResponse } from "axios";
import Resource from "./Resource";

export interface Person {
  id: number;
  name: string;
  emailId?: number;
  phoneId?: number;
}

export default class GetListPersonResource extends Resource<Person[]> {
  private token: string;
  private type: 'number' | 'email';
  private identifier: string;

  constructor(type: 'number' | 'email', identifier: string, token: string) {
    super();
    this.token = token;
    this.type = type;
    this.identifier = identifier;
  }

  public async result(): Promise<Person[]> {
    let response: AxiosResponse;
    response = await this.request.get(`${this.baseURL}/chats/assign-person/${this.type}/${this.identifier}/${this.token}`);

    const result: Person[] = response.data.pessoas.map(item => ({
      id: item.id,
      name: item.nome,
      emailId: item?.idemail,
      phoneId: item?.idtelefone
    }));

    return result
  }
}
