import React, { memo } from 'react';

import { AudioPlayerProvider } from 'react-use-audio-player';

import AudioController from './components/AudioController';

interface AudioPlayerProps {
  src: string;
}

function AudioPlayer(props: AudioPlayerProps) {
  return (
    <AudioPlayerProvider>
      <AudioController src={props.src} />
    </AudioPlayerProvider>
  );
}

export default memo(AudioPlayer);