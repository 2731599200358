import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

interface Props {
  label: string;
  checked?: boolean;
  indeterminate?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
  checkColor?: string;
  color?: string;
  className?: string;
}

const CheckboxElement = withStyles({
  root: {
    padding: 3,
  }
})(Checkbox);

const FormControlLabelElement = withStyles({
  label: {
    fontSize: '14px',
    marginLeft: 3,
  }
})(FormControlLabel);

export function CheckBoxItem(props: Props) {

  return (
    <FormControlLabelElement
      className={props.className}
      style={{
        ...props.style,
        color: props.color ?? '#000',
      }}
      label={props.label}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if(props.onClick) {
          props.onClick();
        }
      }}
      control={
        <CheckboxElement
          style={{ 
            color: props.checkColor ?? '#0B70D2',
            marginLeft: 6,
          }}
          onSelect={event => {
            event.preventDefault();
          }}
          onChange={event => {
            event.preventDefault();
            //props.onClick
          }}
          checked={props.checked}
          indeterminate={props.indeterminate}
          size="small"
        />
      }
    />
  );
}