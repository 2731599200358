import React, {
  memo
} from 'react';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps
} from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import EventIcon from '@material-ui/icons/Event';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#414A56',
    },
  },
});

export const StyledKeyboardDatePicker = withStyles({
  root: {
    margin: 0,
  }
})(KeyboardDatePicker);



const DateTextField = (props: KeyboardDatePickerProps) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <StyledKeyboardDatePicker
          disableToolbar
          format="dd/MM/yyyy"
          variant="dialog"
          size="small"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          autoOk
          margin="normal"
          cancelLabel="Cancelar"
          invalidDateMessage="Data em formato inválido."
          minDate={'01/01/2010'}
          maxDate={'01/01/2100'}
          minDateMessage={'A data deve ser maior que 01/01/2010.'}
          maxDateMessage={'A data deve ser menor do que 01/01/2100.'}
          inputProps={{
            ...props.inputProps,
            style: {
              padding: '10px 12px',
            },
          }}
          InputProps={{
            ...props.InputProps,
            style: {
              fontSize: 14,
              margin: "4px 0",
              backgroundColor: "#fff",
              color: '#444',
              boxShadow: 'inset 0 0 4px #eee',
              border: '1px solid #aaa',
              borderRadius: 4,
              display: 'flex',
              flexWrap: 'nowrap',
              overflowX: 'auto',
            },
          }}
          
          keyboardIcon={
            <EventIcon style={{ fontSize: 16, margin: '-4px' }} />
          }
          {...props}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default memo(DateTextField);