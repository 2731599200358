import React, {
  forwardRef
} from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#414A56',
    },
  },
});

export const StyledAutocomplete = withStyles({
  inputRoot: {
    padding: '0px!important',
    '&::-webkit-scrollbar': {
      width: '3px!important',
      height: '6px!important',
    },
  }
})(Autocomplete);

export const TextFieldElement = forwardRef<HTMLDivElement, TextFieldProps>((props,ref) => {
  return (
    <ThemeProvider theme={theme}>
      <TextField
        {...props}
        ref={ref}
        inputProps={{
          ...props.inputProps,
          style: {
            padding: props.size === 'medium' ? '15px 12px' : '10px 12px',
          },
        }}
        InputProps={{
          ...props.InputProps,
          style: {
            fontSize: 14,
            margin: "4px 0",
            backgroundColor: "#fff",
            color: '#444',
            boxShadow: 'inset 0 0 4px #eee',
            border: '1px solid #aaa',
            borderRadius: 4,
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
          },
        }}
        hiddenLabel={true}
      />
    </ThemeProvider>
  );
});

