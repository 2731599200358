import React, {
  useContext,
} from 'react';

import { Component } from './styles';
import MessageIcon from '@material-ui/icons/Message'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  ChatListContext
} from '../../../../contexts';

import packageJson from '../../../../../package.json';

export default function BlankScreen() {

  const { chatList } = useContext(ChatListContext);

  return (
    <Component>
      {
        chatList ?
          <>
            <MessageIcon
              color="primary"
              style={{
                width: 180,
                height: 180,
                color: '#000',
                opacity: 0.2,
              }}
            />
            <br />
            <Typography
              variant="h5"
              style={{
                color: '#FAFAFA'
              }}
            >
              Selecione uma pessoa ao lado
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: '#FAFAFA'
              }}
            >
              para visualizar o histórico de comunicação.
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: '#FAFAFA'
              }}
            >
              {`Comunicação v${packageJson.version}`}
            </Typography>
          </> :
          <CircularProgress style={{ color: '#fff' }} />
      }
    </Component>
  );
}