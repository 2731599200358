import React, { 
  useContext, 
  memo, 
  useCallback,
} from "react";

import NestedMenuItem, {NestedMenuItemProps} from "material-ui-nested-menu-item";
import { MenuItem } from "@material-ui/core";

import { MessageContext } from "../../../../../../../../../../../../../../contexts";

import { StyledCheckIcon } from "../../styles";

interface DemandLevelMenuProps extends NestedMenuItemProps {
  parentMenuClose: VoidFunction;
}

function DemandLevelMenu(props: DemandLevelMenuProps) {
  const {
    message,
    changeDemandLevel
  } = useContext(MessageContext)

  const onLevelOneClick = useCallback(() => {
    if (message.demandLevel === 1) {
      changeDemandLevel(-1);
    } else {
      changeDemandLevel(1);
    }
    props.parentMenuClose();
  }, [changeDemandLevel, message.demandLevel, props.parentMenuClose]);

  const onLevelTwoClick = useCallback(() => {
    if (message.demandLevel === 2) {
      changeDemandLevel(-1);
    } else {
      changeDemandLevel(2);
    }
    props.parentMenuClose()
  }, [changeDemandLevel, message.demandLevel, props.parentMenuClose]);

  return (
    <NestedMenuItem
      {...props}
      ContainerProps={{       
        style: {
          pointerEvents: props.disabled ? 'none' : undefined,
        }
      }}
    >
      <MenuItem
        disabled={message.demand !== 'open'}
        onClick={onLevelOneClick}
      >
        <StyledCheckIcon className={message.demandLevel === 1 ? 'visible' : 'hidden'}/>
        Nível 1
      </MenuItem>
      <MenuItem
        disabled={message.demand !== 'open'}
        onClick={onLevelTwoClick}
      >
        <StyledCheckIcon className={message.demandLevel === 2 ? 'visible' : 'hidden'}/>
        Nível 2
      </MenuItem>
    </NestedMenuItem>
  )
}

export default memo(DemandLevelMenu);