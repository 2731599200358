import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: '#CF1E37',
    color: '#fff',
    fontWeight: 600,
  }
})(Badge);

export default StyledBadge;