import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { Dialog, Button, Table, TextField } from '../../../../../../../../..';

import { MessageContext } from '../../../../../../../../../../contexts';

interface SuggestionDialogProps {
  open: boolean;
  show: (value: boolean) => void;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#414A56',
    },
  },
});

interface SuggestionDTO {
  'Código': number;
  'Tema': string;
  'Sub-Tema': string;
  'Sugestão': string;
}

type Status = 'step-select' | 'step-edit-message';

export default function SuggestionDialog(props: SuggestionDialogProps) {

  const {
    listSuggestions,
    setSuggestion,
    message,
  } = useContext(MessageContext);

  const [status, setStatus] = useState<Status>(message.suggestion ? 'step-edit-message' : 'step-select');

  const [searchValue, setSearchValue] = useState('');
  const [tableLines, setTableLines] = useState<SuggestionDTO[]>([]);
  const [currentRows, setCurrentRows] = useState<SuggestionDTO[]>([]);
  const [currentText, setCurrentText] = useState(message.suggestion ?? '');

  const selectedRows = useMemo( () => {
    if (currentRows.length === 1) {
      return '1 item selecionado'
    } 
    if (currentRows.length > 1) {
      return `${currentRows.length} itens selecionados`
    } 
    return ''
  }, [currentRows.length] )  

  const selectRow = useCallback((element: SuggestionDTO) => {
    setCurrentRows(old => {
      if (old.find(item => item['Código'] === element['Código'])) {
        return old.filter(item => item['Código'] !== element['Código']);
      }
      return [...old, element];
    });
  }, []);

  const handleClose = useCallback(() => {
    props.show(false);
  }, [props]);

  const handleConfirmSuggestion = useCallback(() => {
    const idSuggestions = currentRows.map(item => item['Código']);
    setSuggestion(idSuggestions, currentText).then((result) => {
      if (result) {
        setCurrentText('');
        handleClose();
      }
    });
  }, [currentRows, currentText, handleClose, setSuggestion]);

  const handleNext = useCallback(() => {
    setCurrentText(currentRows.map(item => item['Sugestão']).join('\n\n'));
    setStatus('step-edit-message');
  }, [currentRows]);

  const handleEditSuggestion = useCallback(() => {
    setStatus('step-edit-message');
  }, []);

  const handleBack = useCallback(() => {
    setStatus('step-select');
  }, []);

  useEffect(() => {
    listSuggestions().then(items => {
      setTableLines(items.map(item => ({
        'Código': item.id,
        'Tema': item.topic,
        'Sub-Tema': item.subTopic,
        'Sugestão': item.name,
      })));
    });
  }, [listSuggestions]);

  return (
    <Dialog
      title={
        <>
          <span>Selecionar sugestão de resposta</span>
          <span style={{ opacity: .5, marginLeft: 15 }}>({status === 'step-select' ? 1 : 2} de 2)</span>
        </>
      }
      onClose={handleClose}
      open={props.open}
      width={600}
    >
      {
        status === 'step-select' ?
          <>
            <ThemeProvider theme={theme}>
              <TextField
                style={{ width: 600, margin: '15px 0' }}
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                size="small"
                placeholder="Procurar por ..."
              />
            </ThemeProvider>
            <Table
              selectedItems={currentRows}
              setSelectedItem={selectRow}
              maxHeight='50vh'
              index="Código"
              hideColumns={['Código']}
              headers={[
                { name: 'Código', align: 'center' },
                { name: 'Tema', size: 100, align: 'left' },
                { name: 'Sub-Tema', size: 100, align: 'left' },
                { name: 'Sugestão', size: 280, align: 'left' },
              ]}
              items={
                tableLines.filter(item => (
                  item['Tema'].toLowerCase().includes(searchValue.toLowerCase()) ||
                  item['Sub-Tema'].toLowerCase().includes(searchValue.toLowerCase()) ||
                  item['Sugestão'].toLowerCase().includes(searchValue.toLowerCase())
                ))
              }
            />
          </> :
          <TextField
            variant="outlined"
            style={{ width: 600 }}
            multiline
            rows={20}
            value={currentText}
            onChange={(event) => setCurrentText(event.target.value)}
          />
      }
      <div style={{
        display: 'flex',
        marginTop: 15,
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}>
        {
          status === 'step-select' && (
            <div>
              <Button
                onClick={handleEditSuggestion}
                color="#fff"
                backgroundColor="#0B70D2"
                style={{
                  width: 100,
                  margin: '5px',
                  justifySelf: 'flex-start',
                }}
              >
                Editar
              </Button>
              <span style={{ marginLeft: 30, opacity: .5 }}> {selectedRows} </span>
            </div>
          )
        }
        {
          status === 'step-select'
            ? (
              <div>
                <Button
                  onClick={handleClose}
                  backgroundColor="transparent"
                  color="#515A64"
                  style={{ width: 100, margin: '5px 10px' }}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleNext}
                  color="#fff"
                  backgroundColor={currentRows.length === 0 ? "#515A64" : "#0B70D2"}
                  style={{ width: 100, margin: '5px 10px', opacity: currentRows.length === 0 ? .5 : 1 }}
                  disabled={currentRows.length === 0}
                >
                  Próximo
                </Button>
              </div>
            )
            : (
              <>
                <Button
                  onClick={handleBack}
                  backgroundColor="transparent"
                  color="#515A64"
                  style={{ width: 100, margin: '5px 10px' }}
                >
                  Anterior
                </Button>
                <div style={{ display: 'flex' }}>
                  <Button
                    onClick={handleClose}
                    backgroundColor="transparent"
                    color="#515A64"
                    style={{ width: 100, margin: '5px 10px' }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={handleConfirmSuggestion}
                    color="#fff"
                    backgroundColor="#0B70D2"
                    style={{ width: 120, margin: '5px 10px' }}
                  >
                    Confirmar
                  </Button>
                </div>
              </>
            )
        }
      </div>
    </Dialog>
  );
}
