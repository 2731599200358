import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Container } from '../../styles';
import { TextBlock } from './styles';


export default function NotAuthorizedScreen() {
  return (
    <Container>
      <TextBlock>
        <Typography style={{ fontSize: 18 }}>
          Sessão não autorizada
        </Typography>
        <Typography
          style={{
            fontSize: 16,
            textAlign: 'center',
            marginTop: 20,
          }}
        >
          Verifique se você possui autorização para utilizar esse módulo<br />
          e recarregue a página novamente
        </Typography>
      </TextBlock>
    </Container>
  );
}