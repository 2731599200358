import React, {
  memo,
} from 'react';

import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';

interface ButtonProps {
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  selected?: boolean;
}

const CircleButton = (props: ButtonProps) => (
  <IconButton
    disabled={props.disabled}
    style={{
      ...props.style,
    }}
    onClick={() => {
      if (props.onClick) {
        props?.onClick();
      }
    }}
  >
    {
      props.selected ? (
        <CheckIcon
          style={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: 16,
          }}
        />
      ) :
      undefined
    }
  </IconButton>
);


export default memo(CircleButton);