import styled from 'styled-components';

interface ComponentProps {
  show: boolean;
}

export const Component = styled.div<ComponentProps>`
  display: ${props => props.show ? 'flex' : 'none' };
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  position: relative;
  top: 5px;
  color: #ddd;
  opacity: 0;
  :hover {
    transition: .3s;
    color: #fff;
    border-color: #fff;
    opacity: 1;
  }
`;

interface ItemProps {
  width: string;
}

export const Text = styled.div<ItemProps>`
  text-transform: uppercase;
  font: bold 12px Roboto;
  margin: 0 5px;
  text-align: center;
  width: ${props => props.width};
`;

export const Line = styled.div<ItemProps>`
  display: flex;
  width: ${props => props.width};
  height: 0;
  border: 1px solid #eee;
`;