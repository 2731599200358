import Handler, { ResultHandler } from "./Handler";

export default class MarkMessageAsReadHandler extends Handler {
  private token: string;
  private read: boolean;
  private messageIdentifier: string;

  constructor(token: string, messageIdentifier: string) {
    super();
    this.token = token;
    this.read = true;
    this.messageIdentifier = messageIdentifier;
  }

  setRead(type: boolean) {
    this.read = type;
  }

  public async handle(): Promise<ResultHandler> {
    try {
      if(this.read) {
        //marcar como lido
        await this.request.patch(`${this.baseURL}/message/read/${this.messageIdentifier}/${this.token}`);
      } else {
        //marcar como não lido
        await this.request.patch(`${this.baseURL}/message/unread/${this.messageIdentifier}/${this.token}`);
      }
      return { result: true };
    } catch(error) {
      return { result: false, error: String(error) };
    }
  }
}
