import axios, {
  AxiosInstance,
  CancelToken,
} from 'axios';

const domains = [
  'reinaldoalguz.com.br',
  'enricomisasi.com.br',
  'rogeriossantos.com.br',
  'taniafruchi.com.br',
  'infogab.com.br',
  'libcom.org.br',
  'arco.org.br',
];

function getBaseURL() {
  const regex = new RegExp(domains.join('|'));
  const [domain] = document.domain.match(regex) || [];

  if (domain)
    return process.env.REACT_APP_API_COMUNICACAO
      ?.replace('infogab.com.br', domain);

  return process.env.REACT_APP_API_COMUNICACAO;
}

const baseURL = getBaseURL();

export default abstract class DefaultRequest {

  protected request: AxiosInstance;
  protected baseURL: string;

  protected cancelToken?: CancelToken

  constructor(cancelToken?: CancelToken) {
    this.request = axios.create({
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      cancelToken,
    });
    this.baseURL = baseURL || '';
  }
}

export abstract class CancelableRequest extends DefaultRequest {
  constructor(cancelToken: CancelToken) {
    super(cancelToken);
    this.cancelToken = cancelToken;
  }
}