import Handler, { ResultHandler } from "./Handler";

export default class DeleteMessageHandler extends Handler {
  private token: string;
  private messageIdentifier: string;

  constructor(token: string, messageIdentifier: string) {
    super();
    this.token = token;
    this.messageIdentifier = messageIdentifier;
  }

  public async handle(): Promise<ResultHandler> {
    try {
      await this.request.delete(
        `${this.baseURL}/message/undo/${this.messageIdentifier}/${this.token}`
      );
      return { result: true };
    } catch(error) {
      console.error(error);
      return { result: false, error: String(error) };
    }
  }
}
