import Handler, { ResultHandler } from "./Handler";

export default class AddNoteToAMessageHandler extends Handler {
  private token: string;
  private messageIdentifier: string;
  private note?: string;

  constructor(token: string, messageIdentifier: string) {
    super();
    this.token = token;
    this.messageIdentifier = messageIdentifier;
  }

  setNote(note: string) {
    this.note = note;
  }

  public async handle(): Promise<ResultHandler> {
    try {
      await this.request.patch(
        `${this.baseURL}/message/note/${this.messageIdentifier}/${this.token}`, 
        { observacao: this.note }
      );
      return { result: true };
    } catch (error) {
      console.error(error);
      return {
        result: false,
        error: String(error)
      };
    }
  }
}
