import React, { ReactChild } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

interface FeedbackProps {
  type: 'error' | 'warning' | 'info' | 'success';
  open: boolean;
  onClose: () => void;
  title: ReactChild;
  description?: ReactChild;
  autoHide?: boolean;
}

export default function Feedback(props: FeedbackProps) {

  return (
    <Snackbar 
      open={props.open} 
      onClose={props.onClose}
      anchorOrigin={{
        horizontal: "right",
        vertical: 'bottom',
      }}
      {...props.autoHide ? { autoHideDuration: 5000 } : {}}
    >
      <Alert onClose={props.onClose} severity={props.type}>
        <AlertTitle>{props.title}</AlertTitle>
        { props.description }
      </Alert>
    </Snackbar>
  );
}