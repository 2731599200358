import React from 'react';

import ShowMoreText from 'react-show-more-text';
import Typography from '@material-ui/core/Typography'

interface TableCellProps {
  children: string;
  width?: number;
  textAlign: 'left' | 'center' | 'right';
  /**
   * @link https://developer.mozilla.org/pt-BR/docs/Web/CSS/align-items
   */
  alignItems: 'normal' | 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'self-start' | 'self-end';
}

export default function TableCell(props: TableCellProps) {

  return (
    <Typography style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: props.alignItems,
      padding: '0 10px',
      fontSize: 14,
      textAlign: props.textAlign,
      width: props.width === undefined ? 'auto' : props.width > 20 ? props.width : 20,
    }}>
      <ShowMoreText
        more='Ler tudo'
        less='Ler menos'
        lines={2}
      >
        {props.children}
      </ShowMoreText>
    </Typography>
  );
}