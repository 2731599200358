import React from 'react';

import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';

export const Component = styled.div`
  position: fixed;
  top: -290px;
  width: calc(100% - 17px);
  background-color: #2B323B;
  padding: 15px 30px;
  box-shadow: inset 0 7px 9px -7px #000;
  overflow-x: auto;
  box-sizing: border-box;
  z-index: -30;
`; 

interface TitleProps {
  children: string
}

export const Title = (props: TitleProps) => (
  <Typography style={{
    color: '#fff',
    fontSize: 22,
  }}>
    {props.children}
  </Typography>
);

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 6px;
  justify-content: space-between;
  align-items: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Group = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
`;