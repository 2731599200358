import React, {
  useCallback,
  useContext,
} from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';

import MessageGroupContext from '../../../../../../../../contexts/MessageGroupContext';

//import { Button } from '../../../../../../../../components';


export default function ConfirmJoinMessagesDialog() {

  const {
    setJoinMessagesInProgress,
    finalizeJoinMessages,
    clearMessagesIds,
    joinMessagesInProgress,
    messagesIds,
  } = useContext(MessageGroupContext);

  const handleCancelJoin = useCallback(() => {
    clearMessagesIds();
    setJoinMessagesInProgress(undefined);
  }, [clearMessagesIds, setJoinMessagesInProgress]);

  const handleConfirmJoin = useCallback(() => {
    finalizeJoinMessages();
  }, [finalizeJoinMessages]);

  return (
    <Snackbar
      open={joinMessagesInProgress ? true : false}
      onClose={(event, reason) => {
        if (reason !== 'clickaway') {
          handleCancelJoin();
        }
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}

    >
      <Alert severity="success">
        <AlertTitle>Juntar mensagens</AlertTitle>
          Você deseja juntar as mensagens selecionadas?
          <br />
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 5,
        }}>
          <Button color="inherit"
            onClick={handleConfirmJoin}
            disabled={messagesIds.length < 2}
          >
            Confirmar
            </Button>
          <Button
            onClick={handleCancelJoin}
            color="inherit"
          >
            Cancelar
            </Button>
        </div>

      </Alert>
    </Snackbar>

  );
}

/*
<Component>
      <Typography
        style={{
          fontWeight: 'bold',
          fontSize: '14px',
          color: '#fff',
          marginRight: '20px',
        }}
      >
        Você deseja juntar as mensagens selecionadas?
      </Typography>
      <Button
        backgroundColor="transparent"
        color="#fff"
        onClick={handleCancelJoin}
      >
        Cancelar
      </Button>
      <Button
        disabled={messagesIds.length < 2}
        color="#fff"
        backgroundColor="#0B70D2"
        onClick={handleConfirmJoin}
      >
        Confirmar
      </Button>
    </Component>
    */