import React, {
  memo,
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import Slide from '@material-ui/core/Slide';


import { CheckBox, TextField, Button } from '../../../../../../../../components';

import {
  Component,
  Title,
  Header,
  Group,
  Body,
} from './styles';

import ChatContext from '../../../../../../../../contexts/ChatContext';

interface MessageFilterProps {
  open: boolean;
  onClose: () => void;
  personId?: number;
  isActive: boolean;
  setIsActive: (active: boolean) => void;
  setNumberOfFilters: (number: number) => void;
  offset?: number;
}

const initialFormValue = {
  fieldMessage: '',
  fieldSuggestion: '',
  fieldNote: '',
  checkWhats: false,
  checkSMS: false,
  checkEmail: false,
  checkAddHistory: false,
  checkWithNote: false,
  checkRead: false,
  checkNotRead: false,
  checkWithSuggestion: false,
  checkOpenDemand: false,
  checkCloseDemand: false,
};

function MessageFilter(props: MessageFilterProps) {
  const {
    currentChat,
    clearMessages,
    getMoreChatMessages,
    chatMessages
  } = useContext(ChatContext);

  const [form, setForm] = useState(initialFormValue);
  const [initialChat, setInitialChat] = useState<string>('');
  const cleaningFilter = useRef(false);

  const countNumberOfActiveFilters = useCallback(() => {
    let count = 0;

    if (form.fieldMessage.trim() !== '') count++;
    if (form.fieldSuggestion.trim() !== '') count++;
    if (form.fieldNote.trim() !== '') count++;

    if (form.checkWhats) count++;
    if (form.checkSMS) count++;
    if (form.checkEmail) count++;
    if (form.checkAddHistory) count++;
    if (form.checkWithNote) count++;
    if (form.checkRead) count++;
    if (form.checkNotRead) count++;
    if (form.checkWithSuggestion) count++;
    if (form.checkOpenDemand) count++;
    if (form.checkCloseDemand) count++;

    props.setNumberOfFilters(count);
  }, [props, form]);

  useEffect(() => {
    if (initialChat !== currentChat) {
      setInitialChat(currentChat);
      setForm(initialFormValue);
    }
  }, [initialChat, currentChat]);

  const handleFilter = useCallback(() => {
    clearMessages();

    if (form === initialFormValue) {
      getMoreChatMessages();
    } else {
      getMoreChatMessages(form);
    }

    countNumberOfActiveFilters();

    props.onClose();
    props.setIsActive(true);

  }, [clearMessages, countNumberOfActiveFilters, form, getMoreChatMessages, props]);

  const clearFilter = useCallback(() => {
    cleaningFilter.current = true;
    setForm(initialFormValue);
    props.setNumberOfFilters(0);
    clearMessages();
  }, [clearMessages]);

  useEffect(() => {
    if (chatMessages.items.length === 0 && cleaningFilter.current) {
      getMoreChatMessages();
      props.setIsActive(false);
      cleaningFilter.current = true;
    }
  }, [chatMessages.items.length, getMoreChatMessages, props]);

  const onChangeMessage = useCallback((event) => {
    const { value } = event.target;
    setForm(old => ({
      ...old, fieldMessage: value
    }));
  }, []);

  const onChangeNote = useCallback((event) => {
    const { value } = event.target;
    setForm(old => ({
      ...old, fieldNote: value
    }));
  }, []);

  const onChangeSuggestion = useCallback((event) => {
    const { value } = event.target;
    setForm(old => ({
      ...old, fieldSuggestion: value
    }));
  }, []);

  return (
    <Slide direction="up" in={props.open}>
      <Component>
        <Header>
          <Title>Filtrar</Title>
          <IconButton
            style={{ padding: 6 }}
            onClick={() => {
              if (props.onClose) {
                props.onClose();
              }
            }}
          >
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </Header>
        <Body>
          <Group>
            <TextField
              placeholder="Mensagem"
              style={{ margin: '4px 0 6px', width: 150 }}
              onChange={onChangeMessage}
              value={form.fieldMessage}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <CheckBox
              color="#fff"
              checkColor="#0B70D2"
              label="WhatsApp"
              checked={form.checkWhats}
              onClick={() => setForm(old => ({ ...old, checkWhats: !old.checkWhats }))}
            />
            <CheckBox
              color="#fff"
              checkColor="#0B70D2"
              label="SMS"
              checked={form.checkSMS}
              onClick={() => setForm(old => ({ ...old, checkSMS: !old.checkSMS }))}
            />
            <CheckBox
              color="#fff"
              checkColor="#0B70D2"
              label="E-mail"
              checked={form.checkEmail}
              onClick={() => setForm(old => ({ ...old, checkEmail: !old.checkEmail }))}
            />
            <CheckBox
              color="#fff"
              checkColor="#0B70D2"
              label="Adicionada no histórico"
              checked={form.checkAddHistory}
              onClick={() => setForm(old => ({ ...old, checkAddHistory: !old.checkAddHistory }))}
            />
          </Group>
          <Group>
            <TextField
              style={{ width: 160 }}
              placeholder="Observação"
              onChange={onChangeNote}
              value={form.fieldNote}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <CheckBox
              color="#fff"
              checkColor="#0B70D2"
              label="Com observação"
              checked={form.checkWithNote}
              onClick={() => setForm(old => ({ ...old, checkWithNote: !old.checkWithNote }))}
            />
            <CheckBox
              color="#fff"
              checkColor="#0B70D2"
              label="Lidas"
              checked={form.checkRead}
              onClick={() => setForm(old => ({ ...old, checkRead: !old.checkRead }))}
            />
            <CheckBox
              color="#fff"
              checkColor="#0B70D2"
              label="Não lidas"
              checked={form.checkNotRead}
              onClick={() => setForm(old => ({ ...old, checkNotRead: !old.checkNotRead }))}
            />
          </Group>
          <Group>
            <TextField
              style={{ width: 210 }}
              placeholder="Sugestão de resposta"
              onChange={onChangeSuggestion}
              value={form.fieldSuggestion}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <CheckBox
              color="#fff"
              checkColor="#0B70D2"
              label="Com sugestão de resposta"
              checked={form.checkWithSuggestion}
              onClick={() => setForm(old => ({ ...old, checkWithSuggestion: !old.checkWithSuggestion }))}
            />
            <CheckBox
              color="#fff"
              checkColor="#0B70D2"
              label="Demanda Aberta"
              checked={form.checkOpenDemand}
              onClick={() => setForm(old => ({ ...old, checkOpenDemand: !old.checkOpenDemand }))}
            />
            <CheckBox
              color="#fff"
              checkColor="#0B70D2"
              label="Demanda Fechada"
              checked={form.checkCloseDemand}
              onClick={() => setForm(old => ({ ...old, checkCloseDemand: !old.checkCloseDemand }))}
            />
          </Group>
        </Body>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            margin: '10px 0',
          }}
        >
          <Button
            color="#fff"
            onClick={clearFilter}
            style={{
              width: 100,
              margin: '0 5px',
            }}
          >
            Limpar
        </Button>
          <Button
            color="#fff"
            backgroundColor="#0B70D2"
            onClick={handleFilter}
            style={{
              width: 100,
              margin: '0 5px',
            }}
          >
            Filtrar
        </Button>
        </div>
      </Component>
    </Slide>
  );
}

export default memo(MessageFilter);