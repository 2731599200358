import styled from 'styled-components';

export const NoChats = styled.div`
  & {
    display: flex;
    width: calc(100% - 50px);
    padding: 0 25px;
    height: 150px;
    color: #888;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;