import React, {
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import OptionsIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';

import {
  MessageContext,
  MessageGroupContext
} from '../../../../../../../../../../contexts';

import AssignCampaignDialog from './components/AssignCampaignDialog';
import EditMessageDialog from './components/EditMessageDialog';

import { Header, BookmarkButton, DemandBookmark, BookmarkLabel } from './styles';

import DemandMenu from './components/DemandMenu';

interface MessageHeaderProps {
  setDelete: (value: boolean) => void;
  isHovered: boolean;
  hasPerson: boolean;
  personId?: number;
}

export default function MessageHeader(props: MessageHeaderProps) {

  const {
    message,
    closeDemand,
    openDemand,
    unbindCampaignFromMessage,
  } = useContext(MessageContext);

  const { selectResponseToAnswer } = useContext(MessageGroupContext);

  const [optionMenuVisible, setVisibilityOptionsMenu] = useState(undefined);

  const [isVisibleCampaignDialog, setVisibleCampaignDialog] = useState(false);
  const [isVisibleEditMessageDialog, setVisibleEditMessageDialog] = useState(false);

  const [menuVisible, setVisibilityMenu] = useState<HTMLElement | undefined>(undefined);

  const {
    deleteMessage: deleteMessageContext,
  } = useContext(MessageContext)

  const showOptionsMenu = useCallback((event) => {
    setVisibilityOptionsMenu(event.target);
  }, []);

  const editMessageHandle = useCallback(() => {
    setVisibleEditMessageDialog(true);
    setVisibilityOptionsMenu(undefined);
  }, []);

  const deleteMessageHandle = useCallback(() => {
    deleteMessageContext().then(result => {
      if (result) props.setDelete(true);
    });
  }, [deleteMessageContext, props]);

  const answerMessageHandle = useCallback(() => {
    selectResponseToAnswer({
      questionText: message.items.join('\n'),
      suggestion: message.suggestion,
      relationshipId: message.relationshipId,
      messageId: message.id,
      type: message.type
    });
    setVisibilityOptionsMenu(undefined);

  }, [message.id, message.items, message.relationshipId, message.suggestion, message.type, selectResponseToAnswer])

  const assignCampaign = useCallback(() => {
    setVisibleCampaignDialog(true);
  }, []);

  const bookmarkTooltip = useMemo(() => {
    if (message.suggestion && message.demand) 
      return 'Com sugestão de resposta'
    else if (message.demand === 'open') 
      return 'Demanda aberta'
    else if (message.demand === 'close')
      return 'Demanda finalizada'

    return 'Alterar demanda';
  }, [message.demand, message.suggestion]) 

  const showMenuDemand = useCallback((event) => {
    setVisibilityMenu(event.target);
  }, []);

  const selectDemandOption = useCallback((option: string) => {
    if (option === 'open') openDemand();
    else if (option === 'close') {
      closeDemand();
      setVisibilityMenu(undefined);
    }
  }, [openDemand, closeDemand]);

  const isAbleToAddOrChangeCampaign = useMemo(() => (
    props.hasPerson &&
    !message.historyType &&
    message.origin === 'received'
  ), [message.historyType, message.origin, props.hasPerson]);

  const isCampaignHeaderVisible = useMemo(() => (
    message.origin === 'sent' || (message.code || (!props.hasPerson) || message.historyType)
      ? true
      : false
  ), [message.code, message.historyType, message.origin, props.hasPerson]);

  const unbindCampaignHandle = useCallback(() => {
    unbindCampaignFromMessage();
  }, [unbindCampaignFromMessage]);

  return (
    <>
      {
        isVisibleEditMessageDialog &&
        <EditMessageDialog
          open={isVisibleEditMessageDialog}
          show={setVisibleEditMessageDialog}
        />
      }
      {
        isVisibleCampaignDialog &&
        <AssignCampaignDialog
          show={setVisibleCampaignDialog}
          open={isVisibleCampaignDialog}
        />
      }
      <Header hasCampaign={isCampaignHeaderVisible}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: isAbleToAddOrChangeCampaign ? 'pointer' : 'auto',
          }}
          onClick={isAbleToAddOrChangeCampaign ? assignCampaign : () => { }}
        >
          {
            message.code ?
              <Typography
                style={{
                  fontSize: '10px',
                  color: '#000',
                  opacity: .5,
                  margin: '0 8px 0 3px'
                }}
              >
                {message.code}
              </Typography> :
              props.hasPerson &&
                message.historyType === undefined &&
                message.origin === 'received' ?
                <EditIcon
                  style={{
                    color: 'rgba(0,0,0,.7)',
                    padding: '0 8px 0 3px',
                  }}
                /> :
                undefined
          }
          <Typography
            style={{
              fontSize: '12px',
              color: '#000',
              fontWeight: 'bold'
            }}
          >
            {
              message.label
                ? message.label
                : props.hasPerson &&
                  message.historyType === undefined &&
                  message.origin === 'received' ?
                  'Atribuir campanha' :
                  ''
            }
          </Typography>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          marginLeft: 25,
        }}>
          {
            message.origin === 'received' && (
              <Tooltip title={bookmarkTooltip}>
                <BookmarkButton 
                  onClick={showMenuDemand}
                  className={
                    message.demand !== 'none' ?
                    `set-demand ${
                    (message.demand !== 'close')
                      ? (message.suggestion ? 'with-suggestion' : '')
                      : 'closed'
                    }` : 'unset-demand'
                  }
                >
                  <BookmarkLabel
                    demandLevel={message.demandLevel}
                    className={message.demand === 'open' && message.suggestion ? 'light-text' : 'dark-text'}
                    onClick={showMenuDemand}
                  >
                    {message.demandLevel}
                  </BookmarkLabel>
                  <DemandBookmark isMessageHovered={props.isHovered}/>
                </BookmarkButton>
              </Tooltip>
            )
          }
          {
            message.origin === 'received' &&
            <>
              <IconButton
                onClick={showOptionsMenu}
                aria-controls="options-menu"
                style={{
                  height: 20,
                  width: 20,
                }}
              >
                <OptionsIcon style={{ color: '#ccc', cursor: 'pointer' }} />
              </IconButton>
              <Menu
                id="options-menu"
                keepMounted
                open={Boolean(optionMenuVisible)}
                anchorEl={optionMenuVisible}
                onClose={() => setVisibilityOptionsMenu(undefined)}
              >
                {
                  props.personId && message.relationshipId
                    ? (
                      <MenuItem dense onClick={answerMessageHandle}>
                        Responder mensagem
                      </MenuItem>
                    )
                    : undefined
                }
                <MenuItem dense
                  onClick={editMessageHandle}
                >
                  Editar mensagem
                </MenuItem>
                {
                  message.code
                    ? (
                      <MenuItem dense
                        onClick={unbindCampaignHandle}
                      >
                        Desvincular campanha
                      </MenuItem>
                    )
                    : undefined
                }
                <MenuItem dense
                  onClick={deleteMessageHandle}
                >
                  <span style={{ color: 'red', fontWeight: 500 }}>Apagar mensagem</span>
                </MenuItem>
              </Menu>
            </>
          }

          {
            menuVisible &&
            <DemandMenu
              onClick={selectDemandOption}
              setRefItem={setVisibilityMenu}
              refItem={menuVisible}
            />
          }
        </div>
      </Header>
    </>
  );
}