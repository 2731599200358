import React, {
  useContext,
  useEffect,
  useState,
  memo,
} from 'react'

import axios from 'axios';

import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

import GetProfileResource, { Profile } from '../../../../../../api/resources/GetProfileResource';

import {
  AppContext,
  MessageGroupContext,
  ChatContext,
} from '../../../../../../contexts'

import ChatAvatar from './components/ChatAvatar';

import {
  Component,
  Container,
} from './styles';

function Header() {
  const { token, isCityCouncilor } = useContext(AppContext);

  const {
    setAvailableMedia,
    isHeaderVisible,
  } = useContext(MessageGroupContext);

  const { currentChat, personId, cancelSource } = useContext(ChatContext);

  const [profile, setProfile] = useState<Profile>({ contact: 'searching' });

  useEffect(() => {
    setProfile({ contact: 'searching' });

    if (cancelSource && personId) {
        const resource = new GetProfileResource(cancelSource.token, personId, token);
        resource.result()
          .then(result => {
            setProfile(() => {
              if (!isCityCouncilor) return result;
              return {
                ...result,
                availableMedia: result.availableMedia?.filter(media => media !== 'sms'),
              }
            })
          })
          .catch(error => {
            if (axios.isCancel(error)) { console.error(error.message); }
            else { console.error(error); }
          });
    } else {
      setProfile({ contact: currentChat.replace('-null', '') });
    }
  }, [cancelSource, currentChat, isCityCouncilor, personId, token]);

  useEffect(() => setAvailableMedia(profile.availableMedia ?? []), [profile, setAvailableMedia]);

  return (
    <Component visible={isHeaderVisible} hasPerson={!!personId}>
      <Container>
        {
          profile.contact !== 'searching' && (
            <>
              <ChatAvatar profile={profile} />
              <Typography className="cabecalho-texto perfil-nome">
                {profile.name || profile.contact}
              </Typography>
              <Typography className="cabecalho-texto perfil-dado">
                {profile.code ? `(${profile.code})` : ''}
              </Typography>
            </>
          )
        }
        {
          profile.contact === 'searching' && (
            <>
              <Skeleton variant="circle" width="40px" height="40px" />
              <Skeleton width="200px" height="25px" style={{ marginLeft: 30 }} />
              <Skeleton width="40px" height="20px" style={{ marginLeft: 20 }} />
            </>
          )
        }
      </Container>
      {
        profile.contact !== 'searching'
          ?
            <div className='right-side'>
                <Typography className="cabecalho-texto perfil-dado perfil-contato">
                  {!profile.contact?.includes('@') ? profile.contact : ''}
                  &nbsp;&mdash;&nbsp;
                </Typography>
                <Typography className="cabecalho-texto perfil-cidade">{profile.city}</Typography>
            </div>
          :
            <div className='right-side'>
              <Skeleton width="100px" height="20px" />
              <Skeleton width="60px" height="25px" style={{ marginLeft: 20 }} />
            </div>
      }
    </Component>
  );
}

export default memo(Header);