import React, { useCallback, useContext } from 'react';

import Typography from '@material-ui/core/Typography';

import { Component } from './styles';

import MessageContext from '../../../../../../../../../../contexts/MessageContext';

interface DeletedMessageProps {
  undo: () => void;
}

export default function DeletedMessage(props: DeletedMessageProps) {
  const {
    undoDeleteMessage: undoDeleteMessageContext,
  } = useContext(MessageContext)

  const handleUndoClick = useCallback(() => {
    undoDeleteMessageContext().then(result => {
      if(result) props.undo();
    });
  },[undoDeleteMessageContext, props]);

  return (
    <Component>
      <Typography
        style={{
          fontWeight: 'bold',
          color: '#222',
          fontSize: '14px',
          marginRight: 15,
        }}
      >
        Mensagem apagada
      </Typography>
      <Typography
        style={{
          fontSize: '14px',
          color: '#59AAE8',
          fontWeight: 'bold',
          cursor: 'pointer'
        }}
        onClick={handleUndoClick}
      >
        DESFAZER
      </Typography>
    </Component>
  );
}