import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';

export const ControllerContainer = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: rgb(240, 240, 240);
  width: 100%;
  min-width: 200px;
  
  padding: 2px 4px;
  padding-right: 6px;
  border-radius: 18px;

  user-select: none;
  font-size: 0.75rem;
`;

export const AudioStatusButton = styled(IconButton)`
  && {
    padding: 0px;

    &:hover {
      background-color: transparent;
    }

    svg {
      color: rgba(0, 0, 0, 0.4);
      font-size: 30px;

      &.error-icon {
        color: red;
      }
    }
  }
`;

export const RateTooltip = styled(Popper)`
  && {
    text-align: center;
    left: -12px !important;
  }
`;

export const RateBadge = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  
  padding: 3px 5px;
  border-radius: 6px;
  background-color: rgb(220, 220, 220);
  box-shadow: 0 1px 4px rgb(180, 180, 180);
  width: 30px;

  cursor: pointer;
  font-weight: bold;
`;
