import React, {
  memo,
  useMemo,
} from 'react';

import Grow from '@material-ui/core/Grow';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import data from 'emoji-mart/data/apple.json';
import {
  BaseEmoji,
  NimblePicker,
} from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import './styles.css';

const I18n = {
  categories: {
    activity: 'Atividades',
    flags: 'Bandeiras',
    foods: 'Comidas e Bebidas',
    nature: 'Animais e Natureza',
    objects: 'Objetos',
    people: 'Carinhas e Pessoas',
    places: 'Viagem',
    recent: 'Usados Frequentemente',
    smileys: 'Emotes',
    search: 'Resultados da pesquisa',
    symbols: 'Símbolos',
  },
  categorieslabel: 'Categorias de emoji', 
  clear: 'Limpar',
  notfound: 'Nenhum emoji encontrado',
  search: 'Pesquisa',
  skintones: {
    1: 'Tom de pele padrão',
    2: 'Tom de pele clara',
    3: 'Tom de pele morena clara',
    4: 'Tom de pele morena',
    5: 'Tom de pele morena escura',
    6: 'Tom de pele escura',
  },
}

interface EmojiPickerPopupProps {
  open: boolean;
  onEmojiPick: (emoji: BaseEmoji) => void
}

function EmojiPickerPopup(props: EmojiPickerPopupProps) {
  const match = useMediaQuery('(max-height: 630px)');
  
  const frequent = useMemo(() => {
    const storagedFrequently = localStorage.getItem('emoji-mart.frequently');
    if (props.open && storagedFrequently) {
      const storageJson = JSON.parse(storagedFrequently);
      return Object.keys(storageJson);
    }
    return undefined;
  }, [props.open]);
  
  return (
    <Grow
      in={props.open}
      style={{ transformOrigin: '25% bottom' }}
    >
      <NimblePicker
        data={data}
        emoji="point_up"
        native
        onSelect={props.onEmojiPick}
        perLine={match ? 8 : 10}
        recent={frequent}
        showSkinTones={false}
        theme="dark"
        title="Selecione seu emoji..."
        i18n={I18n}
      />
    </Grow>
  );
}

/**
 * Popup de seleção de emojis. 
 */
export default memo(EmojiPickerPopup);
