import React from 'react';

import { CheckBoxItem } from './styles';

interface CheckBoxProps {
  checked?: boolean;
  indeterminate?: boolean; 
  onClick?: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  style?: React.CSSProperties;
  checkColor?: string;
  color?: string;
  className?: string;
}

export default function CheckBox(props: CheckBoxProps) {
  return(
    <CheckBoxItem
      {...props}
    />
  );
}