import React, { ReactChild } from 'react';
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

export const Component = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

interface TimestampProps {
  children: ReactChild;
  date?: Date;
  fontSize?: string;
}

export function Timestamp(props: TimestampProps) {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography
            color="inherit"
            style={{ fontSize: 14 }}
          >
            {
              props.date
                ? `${props.date.toLocaleDateString('pt-br')} ${props.date.toLocaleTimeString('pt-br')}`
                : 'Adicionado à fila'
            }
          </Typography>
        </React.Fragment>
      }
    >
      <Typography
        style={{
          fontSize: props.fontSize ? props.fontSize : '12px',
          opacity: 0.5,
          padding: '4px 0',
          marginTop: '2px',
        }}
      >
        {props.children}
      </Typography>
    </HtmlTooltip>
  );

}
