import React, {
  memo,
} from 'react';

import { withStyles } from '@material-ui/core/styles'

import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import QualificationIcon from './QualificationIcon';

type OptionQualification = 'neutro' | 'negativo' | 'positivo'

const IconItem = withStyles((theme) => ({
  root: {
    padding: 4
  }
}))(MenuItem);

interface QualificationMenuProps {
  refItem?: HTMLElement;
  onClick: (value: string) => void;
  setRefItem: (refItem?: HTMLElement) => void;
  option?: OptionQualification;
}

function QualificationMenu(props: QualificationMenuProps) {
  return (
    <Menu
      id="rate-menu"
      open={Boolean(props.refItem)}
      anchorEl={props.refItem}
      transformOrigin={{
        vertical: -10,
        horizontal: 65,
      }}
      onClose={() => props.setRefItem(undefined)}
      MenuListProps={{
        style: {
          padding: 2,
          display: 'flex',
          flexDirection: 'row',
        }
      }}
    >
      <IconItem dense disableGutters
        onClick={() => props.onClick(props.option !== 'positivo' ? 'Positivo' : 'Nulo')}
      >
        <QualificationIcon
          icon={<ThumbUpRoundedIcon style={{ fontSize: '1.25rem' }} />}
          iconColor="#0E7CD7"
          selected={props.option === 'positivo'}
          tooltip={
            props.option === 'positivo'
              ? 'Avaliado como positivo. Clique para remover'
              : 'Avaliar como positivo'
          }
        /> 
      </IconItem>
      <IconItem dense disableGutters
        onClick={() => props.onClick(props.option !== 'neutro' ? 'Neutro' : 'Nulo')}
      >
        <QualificationIcon
          icon={<RemoveCircleOutlineIcon viewBox="2 2 20 20" style={{ fontSize: '1.25rem' }} />}
          iconColor="#FFCB00"
          selected={props.option === 'neutro'}
          tooltip={
            props.option === 'neutro'
              ? 'Avaliado como neutro. Clique para remover'
              : 'Avaliar como neutro'
          }
        />
      </IconItem>
      <IconItem dense disableGutters
        onClick={() => props.onClick(props.option !== 'negativo' ? 'Negativo' : 'Nulo')}
      >
        <QualificationIcon
          icon={<ThumbDownRoundedIcon style={{ fontSize: '1.25rem' }} />}
          iconColor="#D60F0F"
          selected={props.option === 'negativo'}
          tooltip={
            props.option === 'negativo'
              ? 'Avaliado como negativo. Clique para remover'
              : 'Avaliar como negativo'
          }
        />
      </IconItem>
    </Menu>
  );
}

export default memo(QualificationMenu);