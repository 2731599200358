import React, {
  useState,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useMemo,
} from 'react';

import axios, { CancelTokenSource } from 'axios';

import GetChatResource from '../../api/resources/GetChatResource';
import GetChatWithoutPersonResource from '../../api/resources/GetChatWithoutPersonResource';
import GetListPersonResource from '../../api/resources/GetListPersonResource';

import {
  AppContext,
  FeedbackContext
} from '..';

import useMessageListRefresh from './useMessageListRefresh';

import { Attachment } from '../../api/handlers/SendIndividualMessageHandler';

export type OptionHistory = 'questioning' | 'informative' | 'invitation' | 'order';
export type OptionQualification = 'neutro' | 'negativo' | 'positivo';
export type DemandLevel = -1 | 1 | 2;

export interface IMessage {
  id: string;
  code?: number;
  label?: string;
  type: 'sms' | 'whats' | 'email';
  timestamp?: Date;
  editTimestamp?: Date;
  read?: boolean;
  items: string[];
  origin: 'sent' | 'received';
  note?: string;
  historyType?: OptionHistory;
  demand: 'open' | 'close' | 'none';
  demandLevel?: DemandLevel | null;
  suggestion?: string;
  isQueued: boolean;
  answer?: string;
  relationshipId?: number;
  qualification?: OptionQualification;
  attachedFile?: Attachment;
}

interface IPerson {
  id: number;
  name: string;
  emailId?: number;
  phoneId?: number;
}

export interface IMessageFilter {
  fieldMessage?: string;
  fieldSuggestion?: string;
  fieldNote?: string;
  checkWhats?: boolean;
  checkSMS?: boolean;
  checkEmail?: boolean;
  checkAddHistory?: boolean;
  checkWithNote?: boolean;
  checkRead?: boolean;
  checkNotRead?: boolean;
  checkWithSuggestion?: boolean;
  checkOpenDemand?: boolean;
  checkCloseDemand?: boolean;
}

interface ChatMessages {
  items: IMessage[]
  status: 'not-initialized' | 'success' | 'error'
}

type SetChatMessagesType = (item: ChatMessages | ((item: ChatMessages) => ChatMessages)) => void

interface ChatContextData {
  cancelSource?: CancelTokenSource;
  
  currentChat: string;
  selectChat: React.Dispatch<React.SetStateAction<string>>;

  isPersonBindDialogVisible: boolean;
  setPersonBindDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;

  chatMessages: ChatMessages;
  getMoreChatMessages: (filters?: IMessageFilter | undefined) => Promise<void>;
  clearMessages: () => void;
  setChatMessages: SetChatMessagesType;
  changeMessage: (messageId: string, action: (element: IMessage) => IMessage) => void;
  hasOpenDemand: () => boolean;
  hasOpenSuggestion: () => boolean;

  getPeopleList: (chatId: string) => Promise<IPerson[]>;

  showFilters: boolean;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
  isFilterActive: boolean;
  setFilterActive: (filterActive: boolean) => void;
  numberOfFilters: number;
  setNumberOfFilters: (value: number) => void;
  cleanMessageFilter: () => void;

  personId ?: number;
}

interface OperationInProgress {
  [id: string]: boolean;
}

let inProgress = false;

const ChatContext = createContext<ChatContextData>({} as ChatContextData);

export const ChatProvider = ({ children }) => {

  const { token } = useContext(AppContext);

  const { setFeedback } = useContext(FeedbackContext);

  const [currentChat, setCurrentChat] = useState<string>('none');

  const [isPersonBindDialogVisible, setPersonBindDialogVisible] = useState<boolean>(false);

  const cancelSource = useMemo(() => (
    currentChat !== 'none'
      ? axios.CancelToken.source()
      : undefined
  ), [currentChat]);

  const operationInProgress = useRef<OperationInProgress>({});

  /**
   * Este estado armazena a identificação da pessoa 
   * com base no identificador da conversa atual
   */
  const personId = useMemo(() => {
    if (currentChat !== 'none' && !currentChat.endsWith('null')) {
      const lastIndex = currentChat.split('-').length - 1;
      return Number(currentChat.split('-')[lastIndex]);
    }
    return undefined;
  }, [currentChat]);

  // Filter states
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [isFilterActive, setFilterActive] = useState<boolean>(false);
  const [numberOfFilters, setNumberOfFilters] = useState<number>(0);

  //========================================================
  const [chatMessages, setChatMessages] = useState<ChatMessages>({
    items: [],
    status: 'not-initialized'
  });

  const currentMessageListOffset = useMemo(() => chatMessages.items.length, [chatMessages.items.length]);

  const allChatMessagesRetrieved = useRef(false);
  const { messageListRefresh } = useMessageListRefresh(token, personId, currentChat);

  /**
   * Essa função atualiza a listagem de mensagens existentes, com as possíveis
   * mensagens novas oriundas do parâmetro "newMessages", em que todas são
   * ordenadas com base na data do 'timestamp'
   * 
   * @param newMessages Lista das mensagens mais recentes
   */
  const updateMessageList = useCallback((newMessages: IMessage[]) => {
    setChatMessages((old) => {
      let items = [
        ...old.items.filter((item) => (
          !newMessages.some((message) => item.id === message.id)
        )),
        ...newMessages
      ];
      items = items.sort((a, b) => {
        if (!a.timestamp) return 1;
        if (!b.timestamp) return -1;
        if (a.timestamp < b.timestamp) return -1
        if (a.timestamp > b.timestamp) return 1
        return 0
      });

      return {
        status: 'success',
        items,
      };
    })
  }, []);

  useEffect(() => {
    if (messageListRefresh && !inProgress) {
      operationInProgress.current['chatMessages'] = true;
      updateMessageList(messageListRefresh);
      operationInProgress.current['chatMessages'] = false;
    }
  }, [messageListRefresh, updateMessageList])

  /**
   * Essa função busca as mensagens referentes a conversa selecionada
   * Quando a conversa é aberta, a busca recupera as mensagens mais recentes, 
   * e conforme a barra de rolagem é acionada, as mensagens seguintes são buscadas
   * 
   * @param filters Configuração do filtro (pode limitar quais mensagens são buscadas)
   * 
   */
  const getMoreChatMessages = useCallback(async (filters?: IMessageFilter) => {
    if (cancelSource && !operationInProgress.current['chatMessages'] && (!allChatMessagesRetrieved.current || filters)) {
      operationInProgress.current['chatMessages'] = true;

      let resource: GetChatResource | GetChatWithoutPersonResource;
      let contactName: string = '';
      if (personId) {
        resource = new GetChatResource(cancelSource.token, personId, token, filters);
      } else {
        let identifier: string;
        let type: 'email' | 'number';
        if (currentChat!.includes('@')) {
          identifier = currentChat!.replace('-null', '');
          type = 'email';
          contactName = identifier;
        } else {
          type = 'number';
          const formattedNumber = currentChat!.replace('-null', '');
          const justNumbers = formattedNumber.match(/\d+/g) ? formattedNumber.match(/\d+/g)!.join("") : "";
          identifier = justNumbers;
          contactName = formattedNumber;
        }

        resource = new GetChatWithoutPersonResource(cancelSource.token, type, identifier, token, filters);
      }

      if (currentMessageListOffset > 0) {
        resource.setOffset(currentMessageListOffset);
        resource.setLimit(20);
      }

      if (resource!) {
        try {
          const result = await resource.result();

          let newMessages: IMessage[] = result.map(item => ({
            id: item.id,
            code: item.campaign ? Number(item.campaign?.id) : undefined,
            label: item.campaign ? item.campaign.name : undefined,
            timestamp: item.timestamp,
            editTimestamp: item.editTimestamp,
            read: !(item.status === 'Não lido'),
            type: item.type,
            items: item.text?.split('\n') ?? [],
            origin: item.received ? 'received' : 'sent',
            note: item.note,
            suggestion: item.responseSuggestion,
            historyType: item.historyType,
            demand: item.status === 'Demanda em Aberta'
              ? 'open'
              : item.status === 'Demanda Finalizada'
                ? 'close'
                : 'none',
            demandLevel: item.demandLevel,
            isQueued: item.status === 'Na fila',
            relationshipId: item.relationshipId,
            answer: item.answer,
            qualification: item.qualification,
            attachedFile: item.attachedFile,
          }));

          if (newMessages.length === 0 || filters) {
            allChatMessagesRetrieved.current = true;
          }

          // apenas adiciona mensagens da pessoa atual
          const currentChatParts = currentChat.split('-');
          const { length: partsLength } = currentChatParts;

          const noPersonIdChat = currentChatParts
            .filter((item, index) => index !== partsLength - 1);
          const { length: chatNameLength } = noPersonIdChat;

          const currentPersonId = currentChatParts[partsLength - 1];
          const isCurrentChatMessages = (
            personId 
              ? personId === Number(currentPersonId)
              : chatNameLength > 1 ? noPersonIdChat.join('-') === contactName : noPersonIdChat[0]
          );
          if (isCurrentChatMessages) {
            inProgress = false;
            updateMessageList(newMessages);
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            // @ts-ignore
            console.error(error.message);
          } else {
            console.error(error);
            setChatMessages(() => ({ status: 'error', items: [] }));
          }
        } finally {
          operationInProgress.current['chatMessages'] = false;
        }
      }
    }

  }, [cancelSource, currentChat, currentMessageListOffset, personId, token, updateMessageList]);

  /**
   * Essa função limpa a lista de mensagens exibidas 
   * na tela de conversa
   */
  const clearMessages = useCallback(() => {
    setChatMessages(() => ({ status: 'not-initialized', items: [] }));
    allChatMessagesRetrieved.current = false;
    operationInProgress.current['chatMessages'] = false;
  }, []);

  /**
   * Essa função utilitária permite a modificação de qualquer mensagem 
   * listada na conversa que seja identificada pelo "messageId", e alterada 
   * pela função "action"
   * 
   * @param messageId Identificador da mensagem
   * @param action Função que recebe a mensagem, altera essa e retorna a mensagem alterada
   * 
   * @return void
   */
  const changeMessage = useCallback((messageId: string, action: (element: IMessage) => IMessage) => {
    setChatMessages((old) => ({
      status: old.status,
      items: old.items.map((item, index, list) => {
        if (messageId === item.id) {
          list[index] = action(item);
        }
        return item;
      })
    }));
  }, []);

  //========================================================
  /**
   * Essa função verifica se alguma mensagem tem demanda aberta 
   * na lista de mensagens da conversa
   * 
   * @return boolean
   */
  const hasOpenDemand = useCallback(() => (
    chatMessages.items.some(item => item.demand === 'open')
  ), [chatMessages.items]);

  //========================================================
  /**
   * Essa função verifica se alguma mensagem tem sugestão de resposta 
   * na lista de mensagens da conversa
   * 
   * @return boolean
   */
  const hasOpenSuggestion = useCallback(() => (
    chatMessages.items.some(item => item.suggestion && item.demand !== 'close')
  ), [chatMessages.items]);


  //========================================================
  /**
   * Essa função busca a lista das possíveis pessoas que podem ser vinculadas a
   * uma conversa que não tem pessoa identificada no sistema
   * 
   * @param chatId Identificador da conversa que se pretende associar uma pessoa
   */
  const getPeopleList = useCallback(async (chatId: string) => {
    let result: IPerson[] = [];

    if (!operationInProgress.current['getPeopleList']) {
      let identifier: string;
      let type: 'email' | 'number';
      if (chatId!.includes('@')) {
        identifier = chatId!.replace('-null', '');
        type = 'email';
      } else {
        type = 'number';
        const formattedNumber = chatId!.replace('-null', '');
        const justNumbers = formattedNumber.match(/\d+/g) ? formattedNumber.match(/\d+/g)!.join("") : "";
        identifier = justNumbers;
      }

      const resource = new GetListPersonResource(type, identifier, token);
      try {
        operationInProgress.current['getPeopleList'] = true;
        result = await resource.result();
      } catch (error) {
        console.error(error);
        setFeedback({
          title: 'Falha ao buscar pessoas',
          description: <span>Falha na comunicação com o servidor para <br />obter lista de pessoas</span>,
          type: 'error',
        });
      }

      operationInProgress.current['getPeopleList'] = false;
    }
    return result;
  }, [setFeedback, token]);

  //========================================================
  const cleanMessageFilter = useCallback(() => {
    setShowFilters(false);
    setFilterActive(false);
    setNumberOfFilters(0);
  }, []);

  useEffect(() => {
    if (chatMessages.status === 'not-initialized') {
      getMoreChatMessages();
    }
  }, [getMoreChatMessages, chatMessages.status]);

  return (
    <ChatContext.Provider value={{
      cancelSource,
      changeMessage,
      chatMessages,
      cleanMessageFilter,
      clearMessages,
      currentChat,
      getMoreChatMessages,
      getPeopleList,
      hasOpenDemand,
      hasOpenSuggestion,
      isFilterActive,
      isPersonBindDialogVisible,
      numberOfFilters,
      personId,
      selectChat: setCurrentChat,
      setChatMessages,
      setFilterActive,
      setNumberOfFilters,
      setPersonBindDialogVisible,
      setShowFilters,
      showFilters,
    }}>
      { children}
    </ChatContext.Provider>
  );
}

export default ChatContext;