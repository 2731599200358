import { AxiosResponse } from "axios";
import Resource from "./Resource";

export interface Suggestion {
  id: number;
  name: string;
  topic: string;
  subTopic: string;
}


export default class GetListCampaignResource extends Resource<Suggestion[]> {
  private token: string;

  constructor(token: string) {
    super();
    this.token = token;
  }

  public async result(): Promise<Suggestion[]> {
    let response: AxiosResponse;
    response = await this.request.get(`${this.baseURL}/suggestions/${this.token}`);

    const result: Suggestion[] = response.data.sugestoes.map(item => ({
      id: item.id,
      name: item.texto,
      topic: item.tema,
      subTopic: item.subtema
    }));

    return result
  }
}
