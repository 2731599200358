import React, {
  memo,
  useMemo
} from 'react';

import CheckIcon from '@material-ui/icons/Check';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

type OptionHistory = 'questioning' | 'informative' | 'invitation' | 'order'

interface HistoryMenuProps {
  refItem?: HTMLElement;
  onClick: (value: string) => void;
  setRefItem: (refItem?: HTMLElement) => void;
  option?: OptionHistory;
}

function HistoryMenu(props: HistoryMenuProps) {

  const iconElement = useMemo(() => (
    <CheckIcon 
      style={{ 
        fontSize: 16, 
        color: '#0E7CD7', 
        marginRight: 10 
      }} 
    />
  ), []);

  return (
    <Menu
      id="history-menu"
      open={Boolean(props.refItem)}
      anchorEl={props.refItem}
      onClose={() => props.setRefItem(undefined)}
    >
      <MenuItem dense onClick={() => props.onClick('Questionamento')}>
        { props.option === 'questioning' && iconElement }
        <span>Questionamento</span>
      </MenuItem>
      <MenuItem dense onClick={() => props.onClick('Informativo')}>
        { props.option === 'informative' && iconElement }
        <span>Informativo</span>
      </MenuItem>
      <MenuItem dense onClick={() => props.onClick('Convite')}>
        { props.option === 'invitation' && iconElement }
        <span>Convite</span>
      </MenuItem>
      <MenuItem dense onClick={() => props.onClick('Pedido')}>
        { props.option === 'order' && iconElement }
        <span>Pedido</span>
      </MenuItem>
    </Menu>
  );
}

export default memo(HistoryMenu);