import React, {
  memo,
  useMemo,
  useContext
} from 'react';

import CheckIcon from '@material-ui/icons/Check';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {
  MessageGroupContext
} from '../../../../../../../../../../contexts'

type SendType = 'whats' | 'email' | 'sms'

interface SendTypeMenuProps {
  refItem?: HTMLElement;
  onClick: (value: SendType) => void;
  setRefItem: (refItem?: HTMLElement) => void;
  option?: SendType;
}

function SendTypeMenu(props: SendTypeMenuProps) {

  const { 
    availableMedia,
  } = useContext(MessageGroupContext);

  const iconElement = useMemo(() => (
    <CheckIcon 
      style={{ 
        fontSize: 16, 
        color: '#0E7CD7', 
        marginRight: 10 
      }} 
    />
  ), []);

  return (
    <Menu
      id="send-type-menu"
      open={Boolean(props.refItem)}
      anchorEl={props.refItem}
      onClose={() => props.setRefItem(undefined)}
    >
      {
        availableMedia?.includes('whats') &&
        <MenuItem dense onClick={() => props.onClick('whats')}>
          { props.option === 'whats' && iconElement }
          <span>WhatsApp</span>
        </MenuItem>
      }
      
      {
        availableMedia?.includes('email') &&
        <MenuItem dense onClick={() => props.onClick('email')}>
          { props.option === 'email' && iconElement }
          <span>E-mail</span>
        </MenuItem>
      }

      {
        availableMedia?.includes('sms') &&
        <MenuItem dense onClick={() => props.onClick('sms')}>
          { props.option === 'sms' && iconElement }
          <span>SMS</span>
        </MenuItem>
      }
    </Menu>
  );
}

export default memo(SendTypeMenu);