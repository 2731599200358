import styled from 'styled-components';

import Slider from '@material-ui/core/Slider';

export const ProgressContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 0 8px;
  width: 100%;
`;

export const AudioSlider = styled(Slider)`
  && {
    color: rgb(11, 112, 210);
    padding: 6px 0;
    margin-top: 12px;
    .MuiSlider-rail, .MuiSlider-track {
      height: 4px;
    }
    
    .MuiSlider-thumb {
      margin-top: -4.2px;
      &::after {
        width: 24px;
        height: 24px;
        top: -6px;
        left: -6px;
      }
    }
  }
`; 

export const TimeProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.7);
`;