import DefaultRequest, { CancelableRequest } from '../Request';

export default abstract class Resource<T> extends DefaultRequest {

  public abstract result(): Promise<T>
}

export abstract class CancelableResource<T> extends CancelableRequest {

  public abstract result(): Promise<T>
}
