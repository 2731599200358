import { AxiosResponse, CancelToken } from "axios";
import { CancelableResource } from './Resource';
import { DemandLevel, OptionHistory, OptionQualification, IMessageFilter } from "../../contexts/ChatContext";

import { Attachment } from "../handlers/SendIndividualMessageHandler";
interface Campaign {
  id: string;
  name: string;
}
export interface Message {
  id: string;
  type: 'whats' | 'email' | 'sms';
  text: string | null;
  subject?: string;
  status: 'Não lido' | string;
  note: string;
  responseSuggestion: string;
  historyType?: OptionHistory;
  timestamp: Date;
  editTimestamp?: Date;
  campaign: Campaign;
  received: boolean;
  isQueued: boolean;
  relationshipId?: number;
  answer?: string;
  qualification?: OptionQualification;
  attachedFile?: Attachment;
  demandLevel?: DemandLevel;
}

export default abstract class DefaultChat extends CancelableResource<Message[]> {
  protected token: string;

  protected limit?: number;
  protected offset?: number;

  protected url?: string;

  protected filters?: IMessageFilter;

  constructor(cancelToken: CancelToken, token: string, filters?: IMessageFilter) {
    super(cancelToken);
    this.token = token;
    if (filters) this.filters = filters;
  }

  async result(): Promise<Message[]> {
    let response: AxiosResponse;
    const config = { cancelToken: this.cancelToken };

    if (this.filters) {
      response = await this.request.get(`${this.url}/?${this.parseFilters()}`, config);
    } else if (this.limit !== undefined && this.offset !== undefined) {
      response = await this.request.get(`${this.url}?offset=${this.offset}&limit=${this.limit}`, config);
    } else {
      response = await this.request.get(`${this.url}`, config);
    }

    const result: Message[] = response.data.mensagens.map(item => ({
      type: item.tipo === 'whatsapp' ? 'whats' : item.tipo,
      subject: item.assunto,
      note: item.observacao,
      responseSuggestion: item.sugestao_de_resposta,
      text: item.mensagem,
      historyType: this.identifyHistoryType(item.tipo_historico),
      id: item.id,
      editTimestamp: item.dtaltera ? new Date(item.dtaltera) : undefined,
      campaign: {
        id: item.campanha?.id,
        name: item.campanha?.nome
      },
      relationshipId: item.idrelacionamento,
      status: item.status,
      demandLevel: item.nivel,
      received: item.recebido,
      timestamp: item.dtretorno ? new Date(item.dtretorno) : undefined,
      isQueued: item.status === 'Na fila',
      answer: item.resposta,
      qualification: this.identifyQualificationType(item.classificacao),
      attachedFile: item.anexo,
    }));

    return result
  }

  private identifyHistoryType(index: string) {
    const items = {
      'Questionamento': 'questioning',
      'Informativo': 'informative',
      'Convite': 'invitation',
      'Pedido': 'order',
    };
    return items[index]
  }

  private identifyQualificationType(index: string) {
    const items = {
      'NEUTRO': 'neutro',
      'NEGATIVO': 'negativo',
      'POSITIVO': 'positivo',
    };
    return items[index] || null
  }

  protected parseFilters() {
    let APIParams = {};

    if (this.filters?.fieldMessage) APIParams['mensagem'] = this.filters?.fieldMessage;

    if (this.filters?.checkEmail || this.filters?.checkWhats || this.filters?.checkSMS) {
      APIParams['tipos'] = [];

      if (this.filters?.checkWhats) APIParams['tipos'].push('whats');
      if (this.filters?.checkEmail) APIParams['tipos'].push('email');
      if (this.filters?.checkSMS) APIParams['tipos'].push('sms');
    }

    if (this.filters?.checkAddHistory) APIParams['adicionadoAoHistorico'] = this.filters?.checkAddHistory;

    if (this.filters?.checkWithNote) APIParams['comObservacao'] = this.filters?.checkWithNote;
    if (this.filters?.fieldNote !== "") APIParams['observacao'] = this.filters?.fieldNote;

    if (this.filters?.checkRead) APIParams['lidas'] = this.filters?.checkRead;
    if (this.filters?.checkNotRead) APIParams['naoLidas'] = this.filters?.checkNotRead;

    if (this.filters?.checkWithSuggestion) APIParams['comSugestaoDeResposta'] = this.filters?.checkWithSuggestion;
    if (this.filters?.fieldSuggestion !== "") APIParams['sugestaoDeResposta'] = this.filters?.fieldSuggestion;

    if (this.filters?.checkOpenDemand) APIParams['comDemandaEmAberto'] = this.filters?.checkOpenDemand;
    if (this.filters?.checkCloseDemand) APIParams['comDemandaFechada'] = this.filters?.checkCloseDemand;

    return new URLSearchParams(APIParams).toString();
  }

  setLimit(limit?: number) {
    this.limit = limit;
  }

  setOffset(offset?: number) {
    this.offset = offset;
  }

  setUrl(url: string) {
    this.url = url;
  }
}