import React, {
  useContext,
  useCallback,
  useRef,
  useMemo,
  memo,
} from 'react';

import ChatItem from './components/ChatItem';
import LazyChatList from './components/LazyChatList';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider'

import { getTextFromHTMLString } from '../../../../utils';

import { Loading } from '../../../';

import {
  ChatListContext,
} from '../../../../contexts';

import { Typography } from '@material-ui/core';
import NewMessagesAlert from './components/NewMessagesAlert';
import { NoChats } from './styles';

function ChatList() {
  const {
    chatList,
    getMoreChatItems,
    isNewListLoading,
  } = useContext(ChatListContext);

  const refChatList = useRef<HTMLUListElement>(null);

  const onWhellHandle = useCallback((event: React.WheelEvent<HTMLUListElement>) => {
    const scrollHeight = event.currentTarget.scrollHeight;
    const clientHeight = event.currentTarget.clientHeight;
    const scrollTop = refChatList.current?.scrollTop ?? 0;
    const movingDown = event.deltaY > 0;

    if (movingDown && ((clientHeight+scrollTop)/scrollHeight > 0.85)) {
      getMoreChatItems();
    }
  }, [getMoreChatItems, refChatList]);

  const chatItems = useMemo(() => {
    const listedProps = chatList?.map(item => ({
      code: `${item.contactName || item.contact}-${item.personId}`,
      contact: item.contact,
      contactName: item.contactName,
      city: item.city,
      counter: item.counter,
      description: getTextFromHTMLString(item.description),
      personId: item.personId,
      demand: item.demand,
      demandLevels: item.demandLevels,
      hasOpenSuggestion: item.hasOpenSuggestion,
      lastMessageDate: item.lastMessageDate
    }));

    return listedProps?.map((item, index) => (
      <div key={`chatitem-${item.contactName}-${index}`}>
        <ChatItem
          code={item.code}
          contact={item.contact}
          contactName={item.contactName}
          city={item.city}
          description={item.description}
          counter={item.counter}
          demand={item.demand}
          demandLevels={item.demandLevels}
          hasOpenSuggestion={item.hasOpenSuggestion}
        />
        <Divider />
      </div>
    ))
  }, [chatList]);

  return (
    <List
      ref={refChatList}
      style={{
        width: 350,
        height: "calc(100vh - 120px)",
        backgroundColor: "#eee",
        maxHeight: "calc(100vh - 80px)",
        overflowY: "auto",
        padding: 0,
        paddingBottom: 40
      }}
      onWheel={onWhellHandle}
      onScroll={onWhellHandle}
    >
      {
        (chatItems === undefined)
          ? <LazyChatList />
          : (isNewListLoading)
            ? <Loading color="#414a56"/>
            : (chatItems.length === 0)
                ?
                  <NoChats>
                    <Typography>
                      Não há conversas a serem <br />listadas
                    </Typography>
                  </NoChats>
                :
                  <>
                    <NewMessagesAlert />
                    {chatItems}
                  </>
      }
    </List>
  );
}

export default memo(ChatList);