import Resource from "./Resource";

export interface City {
  id: number;
  name: string;
  dioceseId: number;
}

export default class GetListCitiesResource extends Resource<City[]> {
  private token: string;

  constructor(token: string) {
    super();
    this.token = token;
  }

  public async result(): Promise<City[]> {
    let response = await this.request.get(`${this.baseURL}/autocomplete/cidades/${this.token}`);

    const result: City[] = response.data.map(item => ({
      id: item.id,
      name: item.descricao,
      dioceseId: item["id_diocese"]
    }));

    return result
  }
}
