import styled from 'styled-components';

interface ComponentProps {
  /**
   * @default '200px'
   */
  maxHeight?: string;
}

export const Component = styled.div<ComponentProps>`
  display: flex;
  flex-direction: column;
  max-height: ${props => props.maxHeight ?? '200px'};
  overflow-y: scroll;
  border-bottom: 1px solid #aaa;
`;