import React, {
  useState,
  createContext,
  ReactChild,
  useMemo,
} from 'react';

import { Feedback } from '../components'

interface Feedback {
  type: 'error' | 'warning' | 'info' | 'success';
  title: ReactChild;
  description?: ReactChild;
  autoHide?: boolean;
}

interface FeedbackContextData {
  setFeedback: (item: Feedback) => void;
}

const FeedbackContext = createContext<FeedbackContextData>({} as FeedbackContextData);

/**
 * Este contexto controla a exibição do feedback ao usuário
 */
export const FeedbackProvider = ({ children }) => {

  /**
   * Este estado armazena os dados relativos ao feedback exibido
   * ao usuário
   */
  const [data, setFeedback] = useState<Feedback | undefined>(undefined);

  /**
   * Este estado armazena o componente de feedback para
   * exibição em tela para mostrar ao usuário quando solicitado
   */
  const result = useMemo(() => {
    if (data) {
      return (
        <Feedback
          title={data.title}
          description={data.description}
          type={data.type}
          autoHide={data.autoHide}
          onClose={() => setFeedback(undefined)}
          open={data ? true : false}
        />
      )
    }
    return undefined;
  }, [data]);

  return (
    <FeedbackContext.Provider value={{
      setFeedback,
    }}>
      { result }
      { children}
    </FeedbackContext.Provider>
  );
}

export default FeedbackContext;