import Handler, { ResultHandler } from "./Handler";

export default class SetSuggestionToAMessageHandler extends Handler {
  private token: string;
  private messageIdentifier: string;
  private idSuggestions?: number[];
  private text?: string;

  constructor(token: string, messageIdentifier: string) {
    super();
    this.token = token;
    this.messageIdentifier = messageIdentifier;
  }

  setIdSuggestions(idSuggestions: number[]) {
    this.idSuggestions = idSuggestions;
  }

  setText(text: string) {
    this.text = text;
  }

  public async handle(): Promise<ResultHandler> {
    try {
      await this.request.post(
        `${this.baseURL}/suggestions/${this.token}`, 
        { 
          idmensagem: this.messageIdentifier,
          idsugestoes: this.idSuggestions,
          texto: this.text 
        }
      );
      return { result: true };
    } catch(error) {
      console.error(error);
      return { result: false, error: String(error) };
    }
  }
}
