import React, {
  memo,
  useCallback,
  useMemo,
} from 'react';

import { useAudioPosition } from 'react-use-audio-player';

import {
  AudioSlider,
  ProgressContainer,
  TimeProgress,
} from './styles';

function formatTime(time: number) {
  const secondsConversion = { min: 0, sec: 0 };

  const conversion = time / 60;
  secondsConversion.min = Math.trunc(conversion); // conversao segundos para minutos
  secondsConversion.sec = Math.round((conversion - secondsConversion.min) * 60); // segundos restantes após conversão
  
  const timeComponents: string[] = [];
  timeComponents.push(secondsConversion.min < 10 ? `0${secondsConversion.min}` : String(secondsConversion.min));
  timeComponents.push(secondsConversion.sec < 10 ? `0${secondsConversion.sec}` : String(secondsConversion.sec));
  
  return timeComponents.join(':');
}

function AudioProgress() {
  const progress = useAudioPosition({ highRefreshRate: true });

  const handleSlider = useCallback((_, value) => {
    const newPosition = (value / 100) * progress.duration;
    progress.seek(newPosition);
  }, [progress]);

  const currentTime = useMemo(() => formatTime(progress.position), [progress.position]);
  const endTime = useMemo(() => formatTime(progress.duration), [progress.duration]);
  
  return (
    <ProgressContainer>
      <AudioSlider
        value={progress.percentComplete}
        onChange={handleSlider}
      />
      <TimeProgress>
        <span>{currentTime}</span>
        <span>{endTime}</span>
      </TimeProgress>
    </ProgressContainer>
  )
}

export default memo(AudioProgress);