import React, {
  useCallback,
  useState,
  useContext,
} from 'react';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { MessageContext } from '../../../../../../../../../../contexts';

import {
  Dialog,
  Button,
  TextField
} from '../../../../../../../../../../components';

interface NoteDialogProps {
  open: boolean;
  show: (value: boolean) => void;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#414A56',
    },
  },
});

export default function NoteDialog(props: NoteDialogProps) {

  const { 
    addNoteToAMessage,
    message,
  } = useContext(MessageContext);
  const [textContent, setTextContent] = useState(message.note ?? '');

  const handleClose = useCallback(() => {
    props.show(false);
  }, [props]);

  const handleAddNote = useCallback(() => {
    addNoteToAMessage(textContent).then((result) => {
      if (result) {
        props.show(false);
      }
    })
  }, [addNoteToAMessage, props, textContent]);

  return (
    <Dialog
      title="Observação"
      onClose={handleClose}
      open={props.open}
      width={450}
    >
      <ThemeProvider theme={theme}>
        <TextField
          style={{ width: 450 }}
          value={textContent}
          onChange={(event) => setTextContent(event.target.value)}
        />
      </ThemeProvider>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
      }}>
        <Button
          color="#fff"
          backgroundColor={!textContent ? "#515A64" : "#0B70D2"}
          onClick={handleAddNote}
          disabled={!textContent}
          style={{
            width: 120,
            margin: '5px 10px',
            opacity: !textContent ? .5 : 1
          }}
        >
          Confirmar
        </Button>
        <Button
          backgroundColor="transparent"
          color="#515A64"
          onClick={handleClose}
          style={{
            width: 100,
            margin: '5px 10px'
          }}
        >
          Cancelar
        </Button>
      </div>
    </Dialog>
  );
}
