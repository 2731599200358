import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ImportExportIcon from '@material-ui/icons/ImportExport';


export interface OrderMenuIconProps {
  onClick: any;
}

function OrderMenuIcon(props: OrderMenuIconProps) {
	return (
		<IconButton onClick={props.onClick} style={{ padding: 0 }}>
			<ImportExportIcon
				style={{ backgroundColor: 'transparent', color: '#414A56' }}
			/>
		</IconButton>
	);
}


export default OrderMenuIcon;