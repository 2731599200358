import styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check"

export const StyledCheckIcon = styled(CheckIcon)`
  && {
    font-size: 16px;
    margin-right: 10px;
    transform: scale(1.2);
    
    &.hidden {
      color: transparent;
    }
    
    &.visible {
      color: #0E7CD7;
    }
  }
`