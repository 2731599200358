import styled from 'styled-components';

export const Component = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
  
  color: #aaa;
  cursor: pointer;

  :hover {
    text-decoration: underline;
    color: #777;
  }

  p {
    font-size: 14px;
  }
`;