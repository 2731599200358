import React, { ReactChild } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import Title from './components/Title';
import Body from './components/Body';

interface DialogProps {
  title: ReactChild;
  onClose: () => void;
  open: boolean;
  children: ReactChild | ReactChild[];
  width?: React.CSSProperties["width"];
}

const CustomDialog = withStyles((theme) => ({
  paper: {
    borderRadius: 0,
    backgroundColor: '#F4F4F4',
    padding: '20px 25px',
    maxHeight: '80vh',
  }
}))(Dialog);

const DialogComponent: React.FC<DialogProps> = (props: DialogProps) => (
  <CustomDialog
    onClose={props.onClose}
    open={props.open}
    maxWidth={false}
  >
    <Title
      onClose={props.onClose}
      width={props.width}
    >
      {props.title}
    </Title>
    <Body>
      {props.children}
    </Body>
  </CustomDialog>
);

export default DialogComponent;