import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useRef,
} from 'react';

import 'moment/locale/pt-br';
import moment from 'moment';

import SmsIcon from '@material-ui/icons/Mail';
import WhatsIcon from '@material-ui/icons/WhatsApp';
import MailIcon from '@material-ui/icons/AlternateEmail';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import FlagIcon from '@material-ui/icons/Flag';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import MessageHeader from './components/MessageHeader';
import JoinMessages from './components/JoinMessages';

import ChatContext from '../../../../../../../../contexts/ChatContext';
import MessageContext from '../../../../../../../../contexts/MessageContext';
import MessageGroupContext from '../../../../../../../../contexts/MessageGroupContext';

import { AnswerTo } from '../../../../../../../../components';

import DeletedMessage from './components/DeletedMessage';
import MessageContent from './components/MessageContent';
import MessageFooter from './components/MessageFooter';
import NoteDialog from './components/NoteDialog';
import SuggestionDialog from './components/SuggestionDialog';

import {
  Component,
  Body,
  Line,
  LeftTypeDecorator,
  RightTypeDecorator,
  Group,
  Main,
  BottomAppend,
  AppendLine,
} from './styles';

import { getTextFromHTMLString } from '../../../../../../../../utils'

export interface MessageProps {
  isNextMessageJoinable?: boolean;
}

export default function Message(props: MessageProps) {
  const leftRef = useRef<HTMLDivElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const { personId } = useContext(ChatContext);
  const [isDeleted, setDelete] = useState(false);

  const { message } = useContext(MessageContext);

  const {
    joinMessagesInProgress,
    addMessageId,
  } = useContext(MessageGroupContext);

  const [isVisibleNoteDialog, setNoteDialogVisible] = useState(false);
  const [isVisibleSuggestionDialog, setSuggestionDialogVisible] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const handleAddNoteClick = useCallback(() => {
    setNoteDialogVisible(true);
  }, []);

  const handleAnswerSuggestionClick = useCallback(() => {
    setSuggestionDialogVisible(true);
  }, []);

  const typeMessageIcon = useMemo(() => {
    if (message.type === 'sms') {
      return <SmsIcon style={{ color: '#fff' }} />;
    } else if (message.type === 'whats') {
      return <WhatsIcon style={{ color: '#fff' }} />;
    }
    return <MailIcon style={{ color: '#fff' }} />;
  }, [message.type]);

  const verifyDiffTime = useMemo(() => {
    if (!joinMessagesInProgress) return false;

    const diffTime = message.timestamp ?
      Math.abs(moment(message.timestamp.toString())
        .diff(joinMessagesInProgress.date, 'hours')
      ) :
      30

    if (Number.isNaN(diffTime)) {
      return false;
    }
    else {
      return diffTime < 12;
    }

  }, [joinMessagesInProgress, message.timestamp]);

  const isJoinMessagesVisible = useMemo(() => (
    message.origin === 'received' &&
    props.isNextMessageJoinable
  ), [message.origin, props.isNextMessageJoinable]);

  const showCheckBoxMessage = useMemo(() => (
    message.origin === 'received' &&
    joinMessagesInProgress?.type === message.type &&
    verifyDiffTime
  ), [joinMessagesInProgress, message.origin, message.type, verifyDiffTime]);

  const isReceivedMessage = useMemo(() => message.origin === 'received', [message.origin]);
  
  return (
    <Line position={message.origin === 'sent' ? 'right' : 'left'}>
      {
        isDeleted ?
          <DeletedMessage
            undo={() => setDelete(false)}
          />
          :
          <div style={{
            display: 'flex',
            position: 'relative',
          }}>
            {
              showCheckBoxMessage &&
              <Checkbox
                color="primary"
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                style={{
                  position: 'relative',
                  top: 0,
                  left: -10,
                  height: 50,
                  width: 30,
                  color: '#eee',
                }}
                onClick={() => addMessageId(message.id)}
              />
            }
            <Main
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <Group>
                {
                  isReceivedMessage &&
                  <LeftTypeDecorator ref={leftRef} type={message.type}>
                    {typeMessageIcon}
                  </LeftTypeDecorator>
                }
                <Component ref={componentRef} position={message.origin === 'sent' ? 'right' : 'left'}>
                  <MessageHeader
                    setDelete={setDelete}
                    isHovered={isHovered}
                    hasPerson={personId ? true : false}
                    personId={personId}
                 />
                  <Body>
                    {
                      message.answer &&
                      <AnswerTo text={getTextFromHTMLString(message.answer)} />
                    }
                    <MessageContent />
                    <MessageFooter
                      isHovered={isHovered}
                      hasPerson={personId ? true : false}
                    />
                  </Body>
                </Component>
                {
                  message.origin === 'sent'
                  && <RightTypeDecorator type={message.type} />
                }
              </Group>
              {
                message.origin === 'received' &&
                (isHovered || message.note || message.suggestion) &&
                <BottomAppend
                  width={
                    leftRef.current && componentRef.current
                      ? leftRef.current.clientWidth + componentRef.current.clientWidth - 10
                      : undefined
                  }
                >
                  {
                    (isHovered || message.note) &&
                    <AppendLine
                      onClick={handleAddNoteClick}
                      light={message.note ? true : false}
                    >
                      <Tooltip title="Observação">
                        <ChatBubbleIcon style={{ width: 16, marginRight: 10 }} />
                      </Tooltip>
                      <Typography style={{ fontSize: 12 }}>
                        {message.note ?? 'Observação '}
                      </Typography>
                    </AppendLine>
                  }
                  {
                    (isHovered || message.suggestion) &&
                    <AppendLine
                      onClick={handleAnswerSuggestionClick}
                      light={message.suggestion ? true : false}
                    >
                      <Tooltip title="Sugestão de Resposta">
                        <FlagIcon style={{ width: 16, marginRight: 10 }} />
                      </Tooltip>
                      <Typography style={{ fontSize: 12 }}>
                        {message.suggestion ?? 'Sugestão de resposta '}
                      </Typography>
                    </AppendLine>
                  }
                </BottomAppend>
              }
              {
                isVisibleNoteDialog &&
                <NoteDialog
                  show={setNoteDialogVisible}
                  open={isVisibleNoteDialog}
                />
              }
              {
                isVisibleSuggestionDialog &&
                <SuggestionDialog
                  open={isVisibleSuggestionDialog}
                  show={setSuggestionDialogVisible}
                />
              }
              {
                isJoinMessagesVisible &&
                message.timestamp &&
                <JoinMessages isHovered={isHovered} />
              }
            </Main>
          </div>
      }
    </Line >
  );
}