import React, {
  memo,
  useCallback,
  useContext,
} from 'react';

import { Dispatch, SetStateAction } from 'react';

import { SelectedFile } from '../../';

import Tooltip from '@material-ui/core/Tooltip';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';

import { FeedbackContext } from '../../../../../../../../../../../../contexts';

import { Adornment } from '../../styles';

import {
  AttachedFile,
  Filename,
} from './styles';

interface AttachmentAdornmentProps {
  attachment: SelectedFile;
  setAttachment: Dispatch<SetStateAction<SelectedFile>>;
}

function AttachmentAdornment(props: AttachmentAdornmentProps) {    
  const { setFeedback } = useContext(FeedbackContext);
  
  const selectFile = useCallback((e) => {
    e.preventDefault();

    const input = document.createElement('input');
    input.type = 'file';

    input.onchange = (e) => {
      // extensões permitidas
      const allowedExtensions = [
        'jpg', 'jpeg', 'gif', 'png',
        'pdf', 'doc', 'docx', 'txt',
        'csv', 'xls', 'xlsx',
        'mp3', 'wav', 'wma', 'ogg', 'mp4',
      ];
      // calculo de 12 MB, valor máximo permitido de tamanho
      const maxAllowedSize = 12 * (2**10) * (2**10);
      
      // @ts-ignore
      const file: File = e.target.files[0];
      const ext = file.name.split('.').pop();
      if (ext && allowedExtensions.includes(ext)) {
        if (file.size <= maxAllowedSize) {
          const reader = new FileReader();
      
          // `reader.result` será o DataURL lido por `readAsDataURL()`
          reader.onloadend = async () => {
            const base64 = reader.result
            // @ts-ignore
              ?.replace('data:', '')
              .replace(/^.+,/, '');
            // regex para remover o que não é base64
      
            props.setAttachment({
              file,
              info: {
                base64,
                descricao: file.name,
              },
            });
          }
  
          reader.readAsDataURL(file);
        } else {
          setFeedback({
            type: 'warning',
            title: 'Tamanho do arquivo excede o limite',
            description: <span>O arquivo selecionado excede o limite de tamanho do sistema.<br/>O tamanho máximo é de 12MB</span>,
            autoHide: true,
          })
        }
      } else {
        setFeedback({
          type: 'warning',
          title: 'Extensão de arquivo não permitida',
          description: <span>O arquivo selecionado é de uma extensão não permitida pelo sistema.<br/>Extensões permitidas: {allowedExtensions.join(', ')}</span>,
          autoHide: true,
        })
      }
    }
    
    input.click();
  }, [props.setAttachment, setFeedback]); // eslint-disable-line
  
  return (
    <div style={{ paddingLeft: 6 }} >
      {
        props.attachment.file
          ? (
            <AttachedFile>
              <Tooltip title="Remover anexo">
                <Adornment onClick={() => props.setAttachment({ file: null })}>
                  <DeleteIcon style={{ color: '#777' }} />
                </Adornment>
              </Tooltip>
              <Filename>{props.attachment.file.name}</Filename>
            </AttachedFile>
          ) : (
            <Tooltip title="Anexar arquivo">
              <Adornment
                onClick={selectFile}
                style={{ transform: 'rotate(-50deg)' }}
              >
                <AttachFileIcon style={{ fontSize: 22, color: '#999' }} />
              </Adornment>
            </Tooltip>
          )
      }
    </div>
  );
}

/**
 * Icone de anexo para controle de anexo da mensagem.
 */
export default memo(AttachmentAdornment);
