import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

interface QualificationIconProps {
  icon: JSX.Element;
  iconColor: string;
  selected: boolean;
  tooltip: string;
}

function QualificationIcon(props: QualificationIconProps) {
  return (
    <Tooltip title={props.tooltip} >
      <IconButton
        style={{
          color: props.selected ? props.iconColor : undefined,
        }}
      >
        {props.icon}
      </IconButton>
    </Tooltip>
  )
};

export default QualificationIcon;