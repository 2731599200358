import Handler, { ResultHandler } from "./Handler";

type HandlerResult = {
  relationshipId?: number
};

export default class SetCampaignToAMessageHandler extends Handler<HandlerResult> {
  private token: string;
  private messageIdentifier: string;
  private campaignId?: number;
  private relationshipId?: number;
  private subTypeCampaignId?: number;

  constructor(token: string, messageIdentifier: string) {
    super();
    this.token = token;
    this.messageIdentifier = messageIdentifier;
  }

  setCampaign(value: number) {
    this.campaignId = value;
  }

  setRelationshipId(relationshipId: number) {
    this.relationshipId = relationshipId;
  }

  setSubTypeCampaignId(subTypeCampaignId: number) {
    this.subTypeCampaignId = subTypeCampaignId;
  }

  public async handle(): Promise<ResultHandler<HandlerResult>> {
    if(this.campaignId) {
      try {
        const { data, status } = await this.request.post(
          `${this.baseURL}/message/${this.messageIdentifier}/campaign/${this.campaignId}/${this.token}`,
          {
            idrelacionamento: this.relationshipId,
            idSubtipoCampanha: this.subTypeCampaignId
          }
        );
        if (status === 200) {
          return { 
            result: true, 
            data: { 
              relationshipId: data.idRelacionamento,
            },
          };
        };

        return {
          result: false,
        };
      } catch (error) {
        console.error(error);
        return { result: false, error: String(error) };
      }
    }

    return { result: false, error: 'O id da campanha deve ser válido'};
  }
}
