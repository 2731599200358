import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import { LoadingBox } from "./styles";

interface LoadingProps {
  color: React.CSSProperties['color'];
};

function Loading(props: LoadingProps) {
  return (
    <LoadingBox>
      <CircularProgress style={{ color: props.color }} />
    </LoadingBox>
  );
}

export default Loading;
