import React, { ReactChild } from 'react';

interface BodyProps {
  children: ReactChild[] | ReactChild;
  minHeight?: number;
  height?: number;
}

export default function Body(props: BodyProps) {
  
  return(
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      marginTop: 15,
      minHeight: `${props.minHeight ?? 100}px`,
      height: `${props.height ? props.height + 'px' : 'auto'}`,
    }}>
      { props.children }
    </div>
  );
}