import Handler, { ResultHandler } from "./Handler";

type HandlerResult = {
  relationshipId?: number
};

export default class AddMessageToHistoryHandler extends Handler<HandlerResult> {
  private token: string;
  private messageIdentifier: string;
  private historyType?: string;

  constructor(token: string, messageIdentifier: string) {
    super();
    this.token = token;
    this.messageIdentifier = messageIdentifier;
  }

  setHistoryType(historyType: string) {
    this.historyType = historyType;
  }

  public async handle(): Promise<ResultHandler<HandlerResult>> {
    try {
      const { data, status } = await this.request.post(
        `${this.baseURL}/message/add-to-history/${this.messageIdentifier}/${this.token}`, 
        { tipoHistorico: this.historyType }
      );

      if (status === 200) {
        return {
          result: true,
          data: {
            relationshipId: data.idRelacionamento,
          }
        };
      }

      return {
        result: false,
        error: 'Falha na adição ao histórico'
      };
    } catch (error) {
      console.error(error);
      return {
        result: false,
        error: String(error)
      }
    }
  }
}
