import React, { ReactElement } from 'react';

import { Component } from './styles';

interface TableItemsProps {
  selected: boolean;
  width: string;
  index: number;
  children: ReactElement | ReactElement[];
  onClick: () => void;
  position: number;
}

export default function TableItem(props: TableItemsProps) {

  return (
    <Component 
      width={props.width} 
      index={props.index}
      selected={props.selected}
      onClick={props.onClick}
      position={props.position}
    >
      { props.children }
    </Component>
  );
}