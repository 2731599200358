import styled from 'styled-components';

export const LoadingBox = styled.div`
  && {
    display: flex;
    width: 100%;
    height: calc(100% - 100px);
    justify-content: center;
    align-items: center;
  }
`;
