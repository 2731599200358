import Handler, { ResultHandler } from "./Handler";

export default class SetPersonToAChatHandler extends Handler {
  private token: string;
  private type: 'number' | 'email';
  private identifier: string;
  private personId?: number;
  private emailId?: number;
  private phoneId?: number;

  constructor(type: 'number' | 'email', identifier: string, token: string) {
    super();
    this.type = type;
    this.identifier = identifier;
    this.token = token;
  }

  public setPersonId(personId: number) {
    this.personId = personId;
  }

  public setPhoneId(phoneId: number) {
    this.phoneId = phoneId;
  }

  public setEmailId(emailId: number) {
    this.emailId = emailId;
  }

  public async handle(): Promise<ResultHandler> {
    if(this.personId) {
      try {
        await this.request.patch(
          `${this.baseURL}/chats/assign-person/${this.type}/${this.identifier}/${this.token}`,
          {
            idpessoa: this.personId,
            idtelefone: this?.phoneId,
            idemail: this?.emailId
          }
        );
        return { 
          result: true
        };
      } catch (error) {
        console.error(error);
        return { result: false, error: String(error) };
      }
    } 
    return { result: false, error: 'O id da campanha deve ser válido'};
  }
}
