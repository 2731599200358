import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';

export const AttachedFile = styled.div`
  && {
    padding: 3px 6px;
    
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    background-color: #ececec;
    border: 1px solid #ddd;
    border-radius: 25px;
  }  
`;

export const Filename = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: lighter;
    padding: 0 8px;
  }
`;
