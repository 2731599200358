import React, {
  useCallback,
  useState,
  useContext,
} from 'react';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { MessageContext } from '../../../../../../../../../../../../contexts';

import { Dialog, Button, TextField } from '../../../../../../../../../../..';

interface EditMessageDialogProps {
  open: boolean;
  show: (value: boolean) => void;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#414A56',
    },
  },
});

export default function EditMessageDialog(props: EditMessageDialogProps) {

  const {
    message,
    updateMessageText 
  } = useContext(MessageContext);

  const [currentText, setCurrentText] = useState(message.items.join('\n'));

  const handleClose = useCallback(() => {
    props.show(false);
  }, [props]);

  const handleUpdateMessageText = useCallback(() => {
    updateMessageText(currentText.split('\n'));
    props.show(false);
  }, [currentText, props, updateMessageText]);

  return (
    <Dialog
      title="Editar mensagem"
      onClose={handleClose}
      open={props.open}
      width={450}
    >
      <ThemeProvider theme={theme}>
        <TextField
          style={{ 
            width: 450,
          }}
          multiline
          rows={5}
          value={currentText}
          onChange={(event) => setCurrentText(event.target.value)}
        />
      </ThemeProvider>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'row',
        marginTop: 10,
      }}>
        <Button
          color="#fff"
          backgroundColor="#0B70D2"
          onClick={handleUpdateMessageText}
          style={{
            width: 120,
            margin: '5px 10px'
          }}
        >
          Confirmar
        </Button>
        <Button
          backgroundColor="transparent"
          color="#515A64"
          onClick={handleClose}
          style={{
            width: 100,
            margin: '5px 10px'
          }}
        >
          Cancelar
        </Button>
      </div>
    </Dialog>
  );
}
