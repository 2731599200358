import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`
 

  body {
    padding: 0;
    margin: 0;
    font-family: Roboto;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    transition: .5s;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb:focus {
    border-radius: 10px;
    background: rgba(0,0,0,0.6); 
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.2); 
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0,0,0,0.2); 
  }
`;

export default GlobalStyle;