import React from 'react';
import { Typography } from '@material-ui/core';


function SearchTooltip(){
  return (
    <>
      <Typography style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 6 }}>
        Dicas de pesquisa
      </Typography>
      
      <Typography style={{ fontSize: 14 }}>
        Você pode fazer uma busca específica por email ou telefone. Exemplo:
      </Typography>
      
      <Typography style={{ fontSize: 13, color: '#444' }}>
        <i>tel: 11987654321</i>
      </Typography>
      
      <Typography style={{ fontSize: 13, color: '#444' }}>
        <i>email: teste@gmail.com</i>
      </Typography>
      <br />
      
      <Typography style={{ fontSize: 14 }}>
        E também filtrar por vários parâmetros. Exemplo:
      </Typography>
      
      <Typography style={{ fontSize: 13, color: '#444' }}>
        <i>12, José da Silva, tel:(19) 99999-9999</i>
      </Typography>
    </>
  );
}


export default SearchTooltip;