import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';

export interface ReadIndicatorProps {
  read?: boolean;
}

export const ReadIndicator = styled.div<ReadIndicatorProps>`
  width: 8px;
  height: 8px;
  cursor: pointer;
  background-color: ${props => props.read ? '#fff' : '#CF1E37'};
  border: 1px solid ${props => props.read ? '#ccc' : '#CF1E37'};
  border-radius: 4px;
  box-shadow: 0 0 4px #ccc;
  margin: 4px 10px 4px 0px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

interface MessageGroupProps {
  isOverflow: boolean;
  show: boolean;
}

export const MessageGroup = styled.div<MessageGroupProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 5px 5px;
  height: ${props => props.isOverflow && !props.show ? '400px' : 'auto'};
  overflow-y: ${props => props.show ? 'auto' : 'hidden'};
`;

export const MessageItem = styled(Typography)`
  && {
    font-size: 14px;
  }
`;
