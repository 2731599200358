export const avatarColors = {
  A: "#bdbdbd",
  B: "#1d2d50",
  C: "#1d2d50",
  D: "#133b5c",
  E: "#f08a5d",
  F: "#b83b5e",
  G: "#0278ae",
  H: "#32e0c4",
  I: "#726a95",
  J: "#625261",
  K: "#de4463",
  L: "#ff414d",
  M: "#f56a79",
  N: "#838383",
  O: "#ff9a76",
  P: "#70adb5",
  Q: "#931a25",
  R: "#e97171",
  S: "#68b0ab",
  T: "#a37eba",
  U: "#d789d7",
  V: "#f0a500",
  X: "#1a1c20",
  Y: "#28df99",
  Z: "#335d2d",
};
