import React, {
  memo,
  useMemo,
  useCallback,
  useContext,
  useState,
  RefObject,
  useLayoutEffect,
} from 'react';

import SmsIcon from '@material-ui/icons/Mail';
import WhatsIcon from '@material-ui/icons/WhatsApp';
import MailIcon from '@material-ui/icons/AlternateEmail';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorIcon from '@material-ui/icons/Error';

import IconButton from '@material-ui/core/IconButton';

import SendTypeMenu from './components/SendTypeMenu';

import {
  LeftTypeDecorator,
  Container,
  Group,
} from './styles'

import {
  MessageGroupContext,
  ChatContext,
} from '../../../../../../../../contexts';

import MessageInputForm from './components/MessageInputForm';

type SendType = 'whats' | 'email' | 'sms';

interface AnswerFormProps {
  refChat: RefObject<HTMLDivElement>;
}

function AnswerForm(props) {
  const {
    availableMedia,
    responseToAnswer,
  } = useContext(MessageGroupContext);

  const { chatMessages } = useContext(ChatContext);

  const [menuVisible, setVisibilityMenu] = useState<HTMLElement | undefined>(undefined);
  const [currentTypeMessage, setCurrentTypeMessage] = useState<SendType>("whats");

  const isNoOptions = useMemo(() => availableMedia?.length === 0, [availableMedia]);
  
  const typeMessageIcon = useMemo(() => {
    if (isNoOptions) return <ErrorIcon style={{ color: '#fff' }} />;
    if (currentTypeMessage === 'whats') return <WhatsIcon style={{ color: '#fff' }} />;
    if (currentTypeMessage === 'email') return <MailIcon style={{ color: '#fff' }} />;
    if (currentTypeMessage === 'sms') return <SmsIcon style={{ color: '#fff' }} />;
  }, [isNoOptions, currentTypeMessage]);
  
  const showOptionsMenu = useCallback((event) => setVisibilityMenu(event.target), []);

  const handleScrollOnSend = useCallback(() => {
    if (props.refChat.current) {
      props.refChat.current.style.scrollBehavior = "smooth";
      props.refChat.current.scroll(0, props.refChat.current.scrollHeight);
    }
  }, [props.refChat]);
  
  useLayoutEffect(() => {
    if (responseToAnswer) {
      setCurrentTypeMessage(responseToAnswer.type);
    } else {
      const msgsReceived = chatMessages.items.filter(message => message.origin === 'received');

      const lastReceivedType = (msgsReceived && msgsReceived.length > 0) ? msgsReceived[msgsReceived.length - 1].type : "whats";
      setCurrentTypeMessage(lastReceivedType);
    }
  }, [chatMessages.items, responseToAnswer]);
  
  return (
    <Container>
      <Group>
        <LeftTypeDecorator type={currentTypeMessage} noOptions={isNoOptions}>
          {typeMessageIcon}
          {
            !isNoOptions
            && (availableMedia && availableMedia.length > 1)
            && (
              <IconButton
                style={{
                  position: 'absolute',
                  left: 55,
                  top: 19,
                }}
                onClick={showOptionsMenu}
              >
                <ExpandMoreIcon style={{ color: '#fff' }} />
              </IconButton>
            )
          }
        </LeftTypeDecorator>
        <MessageInputForm
          handleScrollOnSend={handleScrollOnSend}
          type={!isNoOptions ? currentTypeMessage : undefined}
        />
      </Group>
      <SendTypeMenu
        option={currentTypeMessage}
        onClick={(value) => {
          setCurrentTypeMessage(value);
          setVisibilityMenu(undefined)
        }}
        refItem={menuVisible}
        setRefItem={setVisibilityMenu}
      />
    </Container>
  );
}

export default memo(AnswerForm);