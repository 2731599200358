import React, { 
  useCallback,
  useState, 
  createContext,
} from 'react';

import ValidateTokenHandler from '../api/handlers/ValidateTokenHandler';

interface AppContextData {
  token: string;
  setToken: (value: string) => void;
  isCityCouncilor: boolean;
  setCityCouncilor: (value: boolean) => void

  validateToken: () => Promise<boolean>;
}

const AppContext = createContext<AppContextData>({} as AppContextData);

/**
 * Este contexto controla os estados e funções gerais da aplicação
 */
export const AppProvider = ({ children }) => {
  /**
   * Este estado armazena o token da sessão de usuário
   */
  const [ token, setToken ] = useState<string>('');
  /**
   * Este estado armazena se o schema atual é referente a um vereador ou não
   */
  const [ isCityCouncilor, setCityCouncilor] = useState(false);
  
  const validateToken = useCallback(async () => {
    const token = new URLSearchParams(window.location.search).get('session_token') || '';

    try {
      const handler = new ValidateTokenHandler(token);
      const { data } = await handler.handle();
      if (data?.validate) {
        setToken(token);
        setCityCouncilor(data.isCityCouncilor ?? false);
      }
      return data?.validate ?? false;
    } catch (error) {
      console.error(error);
    }
    return false;
  }, []);
  
  return (
    <AppContext.Provider value={{ 
      token, 
      setToken,
      isCityCouncilor,
      setCityCouncilor,
      validateToken,
    }}>
      { children }
    </AppContext.Provider>
  );
}

export default AppContext;