import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
} from 'react';

import {
  AppContext,
  ChatContext,
  FeedbackContext,
} from '.';

import { IMessage } from '../contexts/ChatContext';

import {
  JoinMessagesHandler,
  SendIndividualMessageHandler
} from '../api/handlers';

import { Attachment, MessageProps } from '../api/handlers/SendIndividualMessageHandler';

import ChatListContext from './ChatListContext';

type SentType = 'sms' | 'whats' | 'email'

interface JoinMessagesDTO {
  date: Date;
  type: SentType;
}

interface Response {
  messageId: string
  relationshipId?: number
  questionText: string
  suggestion?: string
  type: 'sms' | 'whats' | 'email'
}

interface MessageGroupContextData {
  finalizeJoinMessages: () => Promise<boolean>;
  setJoinMessagesInProgress: (value: JoinMessagesDTO | undefined) => void;
  joinMessagesInProgress: JoinMessagesDTO | undefined;
  clearMessagesIds: () => void;
  addMessageId: (item: string) => void;
  messagesIds: string[];
  addSendMessage: (personId: number, subject: string, message: string | null, type: SentType, attachment?: Attachment, response?: Response) => Promise<boolean>;

  availableMedia?: string[]
  setAvailableMedia: (item: string[] | undefined) => void;

  responseToAnswer: Response | undefined;
  selectResponseToAnswer: (item: Response | undefined) => void;

  isHeaderVisible: boolean;
  setHeaderVisible: (type: boolean) => void
}

interface OperationInProgress {
  [id: string]: boolean;
}

const MessageGroupContext = createContext<MessageGroupContextData>({} as MessageGroupContextData);

/**
 * Este contexto controla todas as operações relacionadas a um
 * conjunto de mensagens na conversa com o usuário
 */
export const MessageGroupProvider = ({ children }) => {

  const { token } = useContext(AppContext);

  const { setFeedback } = useContext(FeedbackContext);
  
  const {
    chatMessages,
    setChatMessages,
    changeMessage,
  } = useContext(ChatContext);

  const { decreaseMessageCounter } = useContext(ChatListContext);
  
  /**
   * Este estado controla a exibição do cabeçalho da
   * conversa
   */
  const [isHeaderVisible, setHeaderVisible] = useState(true);
  
  /**
   * Esta referência controla a execução do progresso das operações
   * para evitar que a mesma operação seja executada, ao mesmo tempo,
   * mais de uma vez
   */
  const operationInProgress = useRef<OperationInProgress>({});

  /**
   * Este estado armazena a lista de mensagens selecionadas
   * para junção
   */
  const [messagesIds, setMessagesIds] = useState<string[]>([]);

  /**
   * Essa função limpa a lista de mensagens para junção
   */
  const clearMessagesIds = useCallback(() => {
    setMessagesIds([]);
  }, []);

  /**
   * Essa função finaliza o processo de junção de mensagens
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const finalizeJoinMessages = useCallback(async () => {
    if (!operationInProgress.current['joinMessages'] && messagesIds.length > 1 && chatMessages.status === 'success') {
      operationInProgress.current['joinMessages'] = true;

      const handler = new JoinMessagesHandler(token);
      handler.setMessagesIds(messagesIds);
      const { result, error } = await handler.handle();

      if (result) {
        const messagesItems = chatMessages.items.filter(item => messagesIds.includes(item.id))

        const others = messagesItems.filter((_, index) => index > 0);

        /**
         * Obtem a listagem de mensagens que serão agrupadas
         */
        const messages = messagesItems.reduce((total: string[], item) => total = [...total, ...item.items], []);
        /**
         * Obtem um id relacionamento não nulo, se houver
         */
        const relationshipId = messagesItems.find((item) => item.relationshipId)?.relationshipId;

        changeMessage(messagesItems[0].id, message => {
          message.items = messages;
          message.relationshipId = relationshipId;
          return message;
        });

        /**
         * Filtra o conjunto de mensagens para
         * remoção das antigas
         */
        const othersIds = others.map(item => item.id)
        const filteredMessages = chatMessages.items.filter(item => !othersIds.includes(item.id));
        setChatMessages((old) => ({ ...old, items: filteredMessages }));

        /**
         * Conta o número de mensagens não lidas que vão ser removidas
         * para atualizar a contagem
         */
        const counter = others.reduce((total, item) => !item.read ? total + 1 : total, 0)
        if (counter > 0) {
          decreaseMessageCounter(counter);
        }

        setJoinMessagesInProgress(undefined);
        clearMessagesIds();
      } else {
        console.error(error);
        setFeedback({
          title: 'Falha na Junção de Mensagens',
          description: <span>Falha na comunicação com o servidor para <br />juntar as mensagens</span>,
          type: 'error',
        })
      }

      operationInProgress.current['joinMessages'] = false;
      return result;
    }

    return false;
  }, [token, messagesIds, chatMessages.status, chatMessages.items, changeMessage, setChatMessages, clearMessagesIds, decreaseMessageCounter, setFeedback]);

  /**
   * Este estado armazena o estágio de progresso de junção de mensagens
   */
  const [joinMessagesInProgress, setJoinMessagesInProgress] = useState<JoinMessagesDTO | undefined>(undefined);

  /**
   * Essa função adiciona/remove o id da mensagem na lista de mensagens
   * para serem juntadas
   * 
   * @param messageId ID da mensagem
   */
  const addMessageId = useCallback((messageId: string) => {
    if (messagesIds.includes(messageId)) {
      setMessagesIds(messagesIds.filter(item => item !== messageId));
    } else {
      setMessagesIds([...messagesIds, messageId]);
    }
  }, [messagesIds]);

  /**
   * Essa função realiza o envio de uma mensagem individual para
   * uma mensagem
   * 
   * @param personId ID da pessoa do destinatário
   * @param subject Assunto da mensagem
   * @param message Texto da mensagem
   * @param type Tipo de envio da mensagem
   */
  const addSendMessage = useCallback(async (personId: number, subject: string, message: string | null, type: SentType, attachment?: Attachment, response?: Response) => {
    if (!operationInProgress.current['sendMessage']) {
      operationInProgress.current['sendMessage'] = true;

      const param: MessageProps = { message, type, personId };
      if (subject.length !== 0) {
        param['subject'] = subject;
      }

      if (response?.relationshipId) {
        param['relationshipId'] = response?.relationshipId;
      }

      if (response?.messageId) {
        param['messageId'] = response?.messageId;
      }

      if (type === 'whats' && attachment) {
        param['attachment'] = attachment;
      }

      const handler = new SendIndividualMessageHandler(token, param);
      
      try {
        const { result, data } = await handler.handle();

        const item: IMessage = {
          demand: 'none',
          id: `sent-${type}-${data?.id_mensagens_individuais}`,
          items: message?.split('\n') ?? [],
          origin: 'sent',
          type,
          isQueued: true,
          answer: response?.questionText,
          attachedFile: attachment,
        }

        setChatMessages((old) => ({ ...old, items: [...chatMessages.items, item] }));
        operationInProgress.current['sendMessage'] = false;

        return result;
      } catch (error) {
        console.error(error);
        setFeedback({
          title: 'Falha no Envio de Mensagem',
          description: <span>Falha na comunicação com o servidor para <br />enviar mensagem</span>,
          type: 'error',
        })

        operationInProgress.current['sendMessage'] = false;
        return false;
      }
    }

    operationInProgress.current['sendMessage'] = false;
    return false;
  }, [chatMessages.items, setChatMessages, setFeedback, token]);

  /**
   * Este estado armazena os meios de comunicação disponíveis
   * para este contato
   */
  const [availableMedia, setAvailableMedia] = useState<string[] | undefined>(undefined);

  /**
   * Estado estado armazena as informações relativas a resposta a uma mensagem
   */
  const [responseToAnswer, selectResponseToAnswer] = useState<Response | undefined>(undefined);

  /**
   * Este useEffect hook monitora o estado da conversa do chat para limpar as configurações, quando
   * o estado for não-inicializado
   */
  useEffect(() => {
    if (chatMessages.status === 'not-initialized') {
      clearMessagesIds();
      setJoinMessagesInProgress(undefined);
      selectResponseToAnswer(undefined);
    }
  }, [chatMessages.status, clearMessagesIds]);

  return (
    <MessageGroupContext.Provider value={{
      finalizeJoinMessages,
      setJoinMessagesInProgress,
      joinMessagesInProgress,
      clearMessagesIds,
      addMessageId,
      messagesIds,
      addSendMessage,
      availableMedia,
      setAvailableMedia,
      responseToAnswer,
      selectResponseToAnswer,
      isHeaderVisible,
      setHeaderVisible,
    }}>
      { children }
    </MessageGroupContext.Provider>
  );
}

export default MessageGroupContext;