import Resource from "./Resource";

export interface Diocese {
  id: number;
  name: string;
  regionId: number;
}

export default class GetListRegionsResource extends Resource<Diocese[]> {
  private token: string;

  constructor(token: string) {
    super();
    this.token = token;
  }

  public async result(): Promise<Diocese[]> {
    let response = await this.request.get(`${this.baseURL}/autocomplete/dioceses/${this.token}`);

    const result: Diocese[] = response.data.map(item => ({
      id: item.id,
      name: item.descricao,
      regionId: item["id_regiao"]
    }));

    return result
  }
}
