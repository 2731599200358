import React, {
  memo,
  useMemo,
  useState,
  useCallback,
} from 'react';

import { useAudioPlayer } from 'react-use-audio-player';

import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

import ErrorIcon from '@material-ui/icons/Error';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

import AudioProgress from '../AudioProgress';

import {
  AudioStatusButton,
  ControllerContainer,
  RateBadge,
  RateTooltip,
} from './styles';

type RateValues = 1.0 | 1.5 | 2.0;

interface AudioControllerProps {
  src: string;
}

function AudioController(props: AudioControllerProps) {
  const [rate, setRate] = useState<RateValues>(1.0);
  
  const audio = useAudioPlayer({
    src: props.src,
    preload: 'metadata',
    html5: true,
  });

  const statusIcon = useMemo(() => {
    if (audio.loading)
      return <CircularProgress />
    
    if (audio.error)
      return (
        <Tooltip title="Ocorreu um erro com o aúdio">
          <ErrorIcon className="error-icon" />
        </Tooltip>
      );
    
    if (audio.playing)
      return <PauseIcon className="pause-icon" />;
    
    return <PlayArrowIcon className="play-icon" />;
  }, [audio.error, audio.loading, audio.playing]);

  const onRateChange = useCallback(() => {
    if (!audio.player) return;

    setRate(old => {
      if (old === 1.0) {
        audio.player.rate(1.5);
        return 1.5;
      }

      if (old === 1.5) {
        audio.player.rate(2.0);
        return 2.0;
      }

      audio.player.rate(1.0);
      return 1.0;
    });
  }, [audio.player]);
  
  return (
    <ControllerContainer>
      <AudioStatusButton
        disableRipple
        onClick={audio.ready ? audio.togglePlayPause : undefined}
      >
        {statusIcon}
      </AudioStatusButton>
      <AudioProgress />
      <Tooltip
        PopperComponent={RateTooltip}
        title={<>Alterar velocidade<br />de reprodução</>}
        placement="right"
      >
        <RateBadge onClick={onRateChange}>
          x{rate.toFixed(1)}
        </RateBadge>
      </Tooltip>
    </ControllerContainer>
  )
}

export default memo(AudioController);