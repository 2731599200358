import React from 'react';

import { Component } from './styles';

import Header from './components/Header';
import ChatBody from './components/ChatBody';

import { MessageGroupProvider } from '../../../../contexts/MessageGroupContext';


export default function ChatScreen() {
  return (
    <MessageGroupProvider>
      <Component>
        <Header />
        <ChatBody />
      </Component>
    </MessageGroupProvider>
  );
}