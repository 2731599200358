import Handler, { ResultHandler } from "./Handler";

type IDemand = 'open' | 'close';
type DemandLevel = -1 | 1 | 2;

interface RequestBody {
  nivel: DemandLevel;
}
export default class SetDemandToAMessageHandler extends Handler {
  private token: string;
  private messageIdentifier: string;
  private type: IDemand;
  private level?: DemandLevel;

  constructor(token: string, messageIdentifier: string) {
    super();
    this.token = token;
    this.messageIdentifier = messageIdentifier;
    this.type = 'open';
  }

  setDemand(value: IDemand) {
    this.type = value;
  }

  setDemandLevel(value: DemandLevel) {
    this.level = value;
  }

  public async handle(): Promise<ResultHandler> {
    let url: string;
    const body = {} as RequestBody;
    if (this.level !== undefined) {
      url = `${this.baseURL}/message/demand/${this.messageIdentifier}/nivel/${this.token}`
      body.nivel = this.level;
    } else {
      url = `${this.baseURL}/message/${this.type === 'open' ? 'demand' : 'close-demand'}/${this.messageIdentifier}/${this.token}`
    }
    try {
      await this.request.patch(url, body);
      return { result: true };
    } catch (error) {
      console.error(error);
      return { result: false, error: String(error) };
    }
  }
}
