import React from 'react';

import TableBody from './components/TableBody';
import TableHeader from './components/TableHeader';
import TableItem from './components/TableItem';
import TableCell from './components/TableCell';


interface HeaderItemProps {
  name: string;
  size?: number;
  align: 'left' | 'center' | 'right'
}

interface TableProps<T> {
  items: T[];
  headers: HeaderItemProps[];
  /**
   * @default '200px'
   */
  maxHeight?: string;
  index: string;
  selectedItems?: T[];
  hideColumns?: string[];
  setSelectedItem?: (value: T) => void;
}

export default function Table<T>(props: TableProps<T>) {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <TableHeader
        items={props.headers.filter(item => !(props.hideColumns ?? []).includes(item.name))}
      />
      <TableBody
        maxHeight={props.maxHeight}
      >
        {
          props.items
            .map((item, pos) => (
              <TableItem
                key={`table-item-${item[props.index]}`}
                index={item[props.index]}
                width="100%"
                selected={props.selectedItems?.map(item => item[props.index]).includes(item[props.index]) ? true : false}
                onClick={() => {
                  if (props.setSelectedItem) {
                    props.setSelectedItem(item);
                  }
                }}
                position={pos}
              >
                {
                  props.headers
                    .filter(item => !(props.hideColumns ?? []).includes(item.name))
                    .map(index => (
                      <TableCell
                        key={`table-cell-${index.name}`}
                        width={index.size}
                        textAlign={index.align}
                        alignItems='start'
                      >
                        {item[index.name]}
                      </TableCell>
                    ))
                }
              </TableItem>
            ))
        }
      </TableBody>
    </div>
  );
}


