import React, {
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';

import 'moment/locale/pt-br';
import moment from 'moment';

import {
  Component,
  Timestamp,
} from './styles';


import ScheduleIcon from '@material-ui/icons/Schedule';
import HistoryIcon from '@material-ui/icons/History';
import ThumbsUpDownRoundedIcon from '@material-ui/icons/ThumbsUpDownRounded';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import HistoryMenu from './components/HistoryMenu';
import QualificationMenu from './components/QualificationMenu';

import { MessageContext } from '../../../../../../../../../../contexts';
import { ChatContext } from '../../../../../../../../../../contexts';

interface MessageFooterProps {
  isHovered: boolean;
  hasPerson: boolean;
};

export default function MessageFooter(props: MessageFooterProps) {

  const {
    message,
    changeHistoryOfAMessage,
    changeQualificationOfAMessage,
  } = useContext(MessageContext);

  const {
    personId
  } = useContext(ChatContext);

  const [menuVisible, setVisibilityMenu] = useState<HTMLElement | undefined>(undefined);

  const qualifiedIcon = useMemo(() => {
    const style = { fontSize: '1.35rem' };
    if (personId) {
      if (message.qualification === 'neutro') {
        return <RemoveCircleOutlineIcon style={{ ...style, color: '#FFCB00' }} />
      }
      if (message.qualification === 'negativo') {
        return <ThumbDownRoundedIcon style={{ ...style, color: '#D60F0F' }} />
      }
      if (message.qualification === 'positivo') {
        return <ThumbUpRoundedIcon style={{ ...style, color: '#0E7CD7' }} />
      }
      return <ThumbsUpDownRoundedIcon />
    }
    return <ThumbsUpDownRoundedIcon />
  }, [message.qualification, personId]);

  const showOptionsMenu = useCallback((event) => {
    setVisibilityMenu(event.currentTarget);
  }, []);

  const selectQualificationOption = useCallback((qualification: string) => {
    changeQualificationOfAMessage(qualification).then(() => {
      setVisibilityMenu(undefined);
    });
  }, [changeQualificationOfAMessage]);

  const selectHistoryOption = useCallback((type: string) => {
    changeHistoryOfAMessage(type).then(() => {
      setVisibilityMenu(undefined);
    });
  }, [changeHistoryOfAMessage]);

  const timestampLabel = useMemo(() => (
    message.timestamp ? moment(message.timestamp.toString()).fromNow() : undefined
  ), [message.timestamp]);

  const editTimestampLabel = useMemo(() => (
    message.editTimestamp ? moment(message.editTimestamp.toString()).fromNow() : undefined
  ), [message.editTimestamp]);

  const qualificationTooltip = useMemo(() => (
    personId
    ? (!message.qualification
      ? 'Adicionar avaliação à mensagem'
      : `Mensagem avaliada como ${message.qualification}`)
    : 'Não é possível classificar mensagens de contatos que não tenham pessoa vinculada.'
  ), [message.qualification, personId]);

  return (
    <Component>
      <Timestamp 
        fontSize='10px'
        date={message.timestamp}
      >
        {
          timestampLabel && !message.isQueued
            ? timestampLabel
            : message.origin === 'sent' ? (
              <span style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <ScheduleIcon style={{ fontSize: 12, marginRight: 5 }} />
                {"Adicionado à fila "}
                { timestampLabel}
              </span>
            ) : <></>
        }
      </Timestamp>
      {
        message.origin === 'received' &&
        message.editTimestamp &&
        editTimestampLabel &&
        !message.isQueued &&

        <Timestamp
          fontSize='10px'
          date={message.editTimestamp}
        >
          {` editado ${editTimestampLabel}`}
        </Timestamp>
      }
      {
        message.origin === 'received' && (
          <div>
            <Tooltip title={qualificationTooltip}>
              <IconButton
                id='qualify-icon'
                style={{
                  padding: 4,
                  opacity: 1.0,
                }}
                onClick={personId ? showOptionsMenu : undefined}
              >
                {qualifiedIcon}
              </IconButton>
            </Tooltip>
            {
              props.hasPerson && (
                message.historyType ? (
                  <Tooltip title="Adicionado ao histórico, clique para alterar tipo de histórico">
                    <IconButton
                      style={{
                        padding: 4,
                        opacity: 1.0,
                      }}
                      onClick={showOptionsMenu}
                    >
                      <HistoryIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  message.code ? (
                    <Tooltip title="Adicionado ao histórico, pois está vinculado à campanha">
                      <IconButton
                        style={{
                          padding: 4,
                          opacity: 1.0,
                        }}
                      >
                        <HistoryIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    props.isHovered && (
                      <Tooltip title="Adicionar ao histórico">
                        <IconButton
                          aria-controls="history-menu"
                          style={{
                            padding: 4,
                            opacity: 0.4,
                          }}
                          onMouseEnter={(event) => {
                            event.currentTarget.style.opacity = '1';
                          }}
                          onMouseLeave={(event) => {
                            event.currentTarget.style.opacity = '.2';
                          }}
                          onClick={showOptionsMenu}
                        >
                          <HistoryIcon />
                        </IconButton>
                      </Tooltip>
                    )
                  )
                )
              )
            }
          </div>
        )
      }
      {
        menuVisible && (
        menuVisible.id === 'qualify-icon'
        ? (
          <QualificationMenu
            onClick={selectQualificationOption}
            setRefItem={setVisibilityMenu}
            refItem={menuVisible}
            option={message.qualification}
          />
        ) : (
          <HistoryMenu
            onClick={selectHistoryOption}
            setRefItem={setVisibilityMenu}
            refItem={menuVisible}
            option={message.historyType}
          />
        ))
      }
    </Component>
  );
}