import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';

export const Component = styled.div`
  position: relative;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  width: calc(100% - 70px);
  height: calc(100% - 24px);
  box-shadow: 0 0 8px #222;
  background-color: #fff;
  padding: 12px;
  margin-left: -10px;
  margin-right: -10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 45px);
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 15px;
  width: 30px;
`;

export const Divider = styled.div`
  background: #ccc;
  height: 2px;
  width: 100%;
  margin: 6px 0;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const Adornment = styled(IconButton)`
  && {
    padding: 0;
    
    .MuiIconButton-label svg {
      font-size: 20px;
    }
  }
`;
