import styled from 'styled-components';

interface TableItemsProps {
  index: number;
  width: string;
  selected: boolean;
  position: number;
}

export const Component = styled.div<TableItemsProps>`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  font-size: 14px;
  font-family: Roboto;
  background-color: ${ props => props.selected ? '#CFDFEF' : props.position%2 === 0 ? '#EBEBEB' : '#F4F4F4'};
  color: ${ props => props.selected ? '#0B70D2' : '#414A56'};
  font-weight: ${props => props.selected ? 'bold' : 'normal'};
  cursor:pointer;
`;