import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';

export const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paper {
      width: max-content;
      height: max-content;
      max-height: 80vh;
    }

    .MuiDialogContent-root {
      padding: 0;

      .MuiAvatar-root {
        width: 100%;
        height: 50vh;
        border-radius: 0;

        /* Estilização para icones de chats sem foto */
        svg {
          font-size: 12rem;
        }
      }
    }
  }
`;
