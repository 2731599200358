import React, {
  memo,
  useMemo,
} from 'react';

import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';

import { Adornment } from '../../styles';

interface EmojiAdornmentProps {
  active: boolean;
  onClick: VoidFunction;
}

function EmojiAdornment(props: EmojiAdornmentProps) {
  const color = useMemo(() => props.active ? '#444' : '#acacac', [props.active])
  
  return (
    <div style={{ paddingRight: 6 }} >
      <Adornment onClick={props.onClick}>
        <EmojiEmotionsIcon style={{ color }} />
      </Adornment>
    </div>
  );
}

/**
 * Icone de emoji para abrir seleção de Emojis.
 */
export default memo(EmojiAdornment);
