import { CancelToken } from "axios";
import { IMessageFilter } from "../../contexts/ChatContext";
import DefaultChat from "./DefaultChat";

export default class GetChatWithoutPersonResource extends DefaultChat {
  private type: 'number' | 'email';
  private identifier: string;

  constructor(cancelToken: CancelToken, type: 'number' | 'email', identifier: string, token: string, filters?: IMessageFilter) {
    super(cancelToken, token, filters);
    this.type = type;
    this.identifier = identifier;
    
    this.setUrl(`${this.baseURL}/chats/${this.type}/${this.identifier}/${this.token}`);
  }
}
