import React, {
  useCallback,
  useContext,
  useState,
  memo
} from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ChatItemBody from './components/ChatItemBody';

import ChatContext from '../../../../../../contexts/ChatContext';

export interface ChatItemProps {
  code: string;
  contact: string;
  contactName?: string;
  city?: string;
  description: string;
  counter: number;
  demand: 'open' | 'close' | 'none';
  demandLevels: string;
  hasOpenSuggestion: boolean;
}

interface MousePosition {
  x: number;
  y: number;
}

const INITIAL_MOUSE_POS = { x: -1, y: -1 } as MousePosition

function ChatItem(props: ChatItemProps) {

  const {
    cancelSource,
    currentChat,
    selectChat,
    clearMessages,
    cleanMessageFilter,
    setPersonBindDialogVisible,
  } = useContext(ChatContext);

  const [mousePosition, setMousePosition] = useState(INITIAL_MOUSE_POS);

  const openChat = useCallback(() => {
    clearMessages();
    cleanMessageFilter();
    selectChat(old => {
      if (cancelSource && old !== props.code) {
        cancelSource.cancel(`Requisições de ${old} abortadas`);
      }
      return props.code;
    });
  }, [cancelSource, clearMessages, cleanMessageFilter, selectChat, props.code]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (props.code.endsWith('null')) {
      event.preventDefault();
      setMousePosition({
        x: event.clientX - 2,
        y: event.clientY - 4,
      });
    }
  }, [props.code]);

  const handleClose = useCallback(() => setMousePosition(INITIAL_MOUSE_POS), []);

  const handleBindChatToAPerson = useCallback(() => {
    handleClose();
    setPersonBindDialogVisible(true);
  }, [handleClose, setPersonBindDialogVisible]);

  return (
    <>
      <ChatItemBody
        onContextMenu={handleClick}
        title={props.contactName || props.contact}
        description={props.description}
        city={props.city}
        chatContact={props.contact}
        chatId={props.code}
        counter={props.counter}
        selected={props.code === currentChat}
        onClick={openChat}
        demand={props.demand}
        demandLevels={props.demandLevels}
        hasOpenSuggestion={props.hasOpenSuggestion}
      />
      <Menu
        keepMounted={props.code.endsWith('null')}
        open={mousePosition.x > 0 && mousePosition.y > 0}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: mousePosition.y, left: mousePosition.x }}
      >
        <MenuItem dense onClick={handleBindChatToAPerson}>Vincular conversa a uma pessoa</MenuItem>
      </Menu>
    </>
  );
}

export default memo(ChatItem);