import { AxiosResponse } from "axios";
import Resource from "./Resource";

export interface Campaign {
  id: number;
  type: string;
  subType: string;
  idSubType: number;
  description: string;
  initialDate: Date;
  finalDate: Date;
  relationshipId: number;
}

export default class GetListCampaignResource extends Resource<Campaign[]> {
  private messageIdentifier: string;
  private token: string;
  private query?: string;

  constructor(messageIdentifier: string, token: string) {
    super();
    this.messageIdentifier = messageIdentifier;
    this.token = token;
  }

  public setQueryParams(params: URLSearchParams) {
    this.query = params.toString();
  }

  public async result(): Promise<Campaign[]> {
    let response: AxiosResponse;
    const path = `${this.baseURL}/campaigns/${this.messageIdentifier}/${this.token}`;

    if (this.query) {
      response = await this.request.get(`${path}?${this.query}`);
    } else {
      response = await this.request.get(path);
    }

    const result: Campaign[] = response.data.campanhas.map(item => ({
      id: item.id,
      type: item.tipo,
      subType: item.subtipo,
      idSubType: item.idsubtipo,
      description: item.descricao,
      initialDate: item.dtinicial,
      finalDate: item.dtprevista,
      relationshipId: item.idrelacionamento
    }));

    return result;
  }
}
