import React, {
  memo,
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';

import Tooltip from '@material-ui/core/Tooltip';

import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import {
  ChatListContext,
  ChatFilterContext,
} from '../../../../../../contexts';

import ChatFilterDialog from './components/ChatFilterDialog';
import ChatFilterDialogIcon from './components/ChatFilterDialogIcon';
import OrderMenu from './components/OrderMenu';
import OrderMenuIcon from './components/OrderMenuIcon';
import SearchTooltip from './components/SearchTooltip';

import { TextField } from '../../../../../../components';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#414A56',
    },
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    padding: '10px 15px',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 270,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: '0 0 15px rgba(0,0,0,.3)'
  },
}))(Tooltip);

type StateTyping = 'blocked' | 'active' | 'disable'

function SearchTextField() {

  const { activeOrder, setActiveFilter, setActiveOrder } = useContext(ChatListContext);
  const { activeResources } = useContext(ChatFilterContext);

  const isTyping = useRef<StateTyping>('disable');
  const refInput = useRef<HTMLInputElement>(null);

  const [searchTerm, setSearchTerm] = useState('');
  
  const [filterCounter, setFilterCounter] = useState(0);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  
  const [ orderMenuAnchorEl, setOrderMenuAnchorEl ] = useState<HTMLElement | null>(null);

  const openAdvancedFilter = useCallback(() => {
    activeResources()
    setShowFilterDialog(true);
  }, [activeResources]);

  const onClickOrderMenuIcon = useCallback((event) => {
    setOrderMenuAnchorEl(event.target);
  }, [setOrderMenuAnchorEl]);
  
  const onSelectOrderMenu =  useCallback((event)=> {
    setActiveOrder(event);
    setOrderMenuAnchorEl(null);
  }, [setActiveOrder])

  const onChangeText = useCallback((event) => {
    setSearchTerm(event.target.value);
    isTyping.current = 'active';
  }, [setSearchTerm]);

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0 && isTyping.current === 'active') {
      setTimeout(() => {
        if (isTyping.current === 'active') {
          isTyping.current = 'blocked';
          const value = refInput.current?.querySelector('input')?.value
          if (value) {
            const codes: number[] = [];
            const names: string[] = [];
            const telephones: string[] = [];
            const emails: string[] = [];

            value.split(',')
              .map(item => item.trim())
              .forEach(item => {
                if (item.includes('email:')) {
                  emails.push(item.replace('email:', '').trim());
                } else if (item.includes('tel:')) {
                  telephones.push(item.replace('tel:', '').trim());
                }
                else if (Number(item)) {
                  codes.push(Number(item));
                } else {
                  names.push(item.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
                }
              })

            setActiveFilter(old => ({
              ...old,
              peopleIds: codes,
              peopleNames: names,
              emails,
              telephones,
            }));
          }
        }
      }, 1000)
    } else if (searchTerm?.length === 0 && isTyping.current === 'active') {
      setActiveFilter(old => ({
        ...old,
        peopleIds: undefined,
        peopleNames: undefined,
        emails: undefined,
        telephones: undefined,
      }));
    }
  }, [setActiveFilter, searchTerm]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <HtmlTooltip placement="right-start" title={<SearchTooltip />}>
          <TextField
            ref={refInput}
            onChange={onChangeText}
            variant="outlined"
            placeholder="Pesquisar código ou nome"
            size="medium"
            style={{ width: 320 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <OrderMenuIcon
                    onClick={onClickOrderMenuIcon}
                  />
                  <ChatFilterDialogIcon
                    onClick={openAdvancedFilter}
                    counter={filterCounter}
                  />
                </InputAdornment>
              ),
              style: {
                fontSize: 14,
              }
            }}
          />
        </HtmlTooltip>
      </ThemeProvider>

      <OrderMenu
        anchorEl={orderMenuAnchorEl}
        onBlur={() => setOrderMenuAnchorEl(null)}
        open={Boolean(orderMenuAnchorEl)}
        initialSelected={activeOrder}
        onSelect={onSelectOrderMenu}
      />

      <ChatFilterDialog
        setFilterCounter={setFilterCounter}
        open={showFilterDialog}
        show={setShowFilterDialog}
      />
    </>
  );
}

export default memo(SearchTextField);