import Handler, { ResultHandler } from "./Handler";

export default class EditMessageHandler extends Handler {
  private token: string;
  private messageIdentifier: string;
  private text: string[] | undefined

  constructor(token: string, messageIdentifier: string) {
    super();
    this.token = token;
    this.messageIdentifier = messageIdentifier;
  }

  setText(text: string[]) {
    this.text = text;
  }

  public async handle(): Promise<ResultHandler> {
    if(this.text) {
      try {
        await this.request.patch(
          `${this.baseURL}/message/edit/${this.messageIdentifier}/${this.token}`, 
          { texto: this.text.join('\n') }
        );
        return { result: true};
      } catch (error) {
        console.error(error);
        return { result: false, error: String(error) };
      }
    }
    return { result: false, error: 'O texto não pode ser vazio' };
  }
}
