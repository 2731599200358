import DefaultRequest from '../Request';

export interface ResultHandler<T = void> {
  result: boolean;
  data?: T;
  error?: string;
}

export default abstract class Handler<T = void> extends DefaultRequest {

  public abstract handle(): Promise<ResultHandler<T>>
}