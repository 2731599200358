import React, {
  memo,
  useContext,
} from 'react';

import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { ChatContext } from '../../../../../../../../../../contexts';

import { NoPhotoAvatar } from './styles';

interface ProfileAvatarProps {
  name: string;
  photo?: string;
}

function ProfileAvatar(props: ProfileAvatarProps) {
  const { personId } = useContext(ChatContext);
  
  return (
    !props.photo
      ? (
        <NoPhotoAvatar alt={props.name}>
          {
            personId
              ? <PersonIcon />
              : <Tooltip title="Vincular conversa a uma pessoa"><PersonAddIcon /></Tooltip>
          }
        </NoPhotoAvatar>
      ) : <Avatar alt={props.name} src={`data:image/jpeg;base64,${props.photo}`} />
  );
}

export default memo(ProfileAvatar);
