import React, { useMemo } from 'react';

import { Typography } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import { truncateString } from '../../../../../../../../utils';

import {
  ChatItemInfo,
  Component,
  DemandBookmark,
  LastMessagePreview,
  UnreadMessagesBadge
} from './styles';

interface ChatItemBodyProps {
  selected: boolean;
  title: string;
  description: string;
  chatContact: string
  city?: string;
  chatId: string;
  counter: number;
  onClick: () => void;
  demand: 'open' | 'close' | 'none';
  demandLevels?: string;
  hasOpenSuggestion: boolean;
  onContextMenu: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export default function ChatItemBody(props: ChatItemBodyProps) {
  const personId = useMemo(() => Number(props.chatId.split('-').pop()), [props.chatId]);

  const showDemandBookmark = useMemo(() => (
    props.hasOpenSuggestion || props.demand !== 'none'
  ), [props.demand, props.hasOpenSuggestion]);

  const bookmarkColor = useMemo(() => {
    if (props.hasOpenSuggestion) {
      return '#1fa800';
    }

    if (props.demand === 'open') {
      return '#ffdd19';
    }

    return '#878787';
  }, [props.demand, props.hasOpenSuggestion]);

  return (
    <Component
      selected={props.selected}
      counter={props.counter}
      onClick={props.onClick}
      onContextMenu={props.onContextMenu}
    >
      <DemandBookmark
        visible={showDemandBookmark}
        hasOpenSuggest={props.demand === 'open' && props.hasOpenSuggestion}
      >
        <span>{props.demandLevels}</span>
        <BookmarkIcon style={{ color: bookmarkColor, fontSize: 40 }} />
      </DemandBookmark>
      <ChatItemInfo
        chatIsSelected={props.selected}
        hasUnreadMessages={props.counter > 0}
        hasPerson={!!personId}
      >
        <div className='chat-identifiers'>
          <span>
            {/*
              Identificador principal sempre aparece.
              Nome de contato, para os sem nome usa o numero ou email
            */}
            <Typography className='main-identifier'>
              {truncateString(props.title, 25, 30)}
            </Typography>

            {/* Outros identificadores são visiveis apenas para chats com pessoa cadastrada */}
            <Typography className='person-identifier'>
              {'(' + personId + ')'}
            </Typography>
          </span>
          <span>
            <Typography className='contact-identifier'>
              {!props.chatContact.includes('@') ? props.chatContact : ''}
            </Typography>
            <Typography className='city-identifier'>
              {props.city ? ' — ' : ''}
              {props.city}
            </Typography>
          </span>
        </div>
        <LastMessagePreview
          style={{
            color: props.selected ? '#aaa' :
              props.counter === 0 ? '#666' : '#444'
          }}
        >
          {truncateString(props.description, 35)}
        </LastMessagePreview>
        <UnreadMessagesBadge
          visible={props.counter > 0}
          badgeContent={props.counter}
          max={25}
        />
      </ChatItemInfo>
    </Component>
  );
}