import React from 'react';

import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { Badge } from '../../../../../../../';


export interface ChatFilterDialogIconProps {
  onClick: () => void;
  counter: number;
}

function ChatFilterDialogIcon(props: ChatFilterDialogIconProps) {
  return (
    <Tooltip title="Filtro Avançado">
      <IconButton
        onClick={props.onClick}
        style={{ padding: 0 }}
      >
        { props.counter !== 0 ?
          <Badge badgeContent={props.counter}>
            <FilterListIcon
              style={{ backgroundColor: 'transparent', color: '#414A56' }}
            />
          </Badge> :
          <FilterListIcon
            style={{ backgroundColor: 'transparent', color: '#414A56' }}
          />
        }
      </IconButton>
    </Tooltip>
  );
}


export default ChatFilterDialogIcon;