import React, { ReactElement } from 'react';

import { Component } from './styles';

interface TableBodyProps {
  children: ReactElement | ReactElement[];
  maxHeight?: string;
}

export default function TableBody(props: TableBodyProps) {

  return (
    <Component maxHeight={props.maxHeight}>
      {props.children}
    </Component>
  );
}