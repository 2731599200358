import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Component } from './styles';

interface ShowMoreProps {
  expand: boolean;
  onClick: VoidFunction;
}

export default function ShowMore(props: ShowMoreProps) {
  return (
    <Component onClick={props.onClick}>
      <Typography>{props.expand ? 'Encolher ↑' : 'Mostrar mais ↓'}</Typography>
    </Component>
  );
}