import React, { memo } from 'react';

import DialogContent from '@material-ui/core/DialogContent';

import { Profile } from '../../../../../../../../../../api/resources/GetProfileResource';

import ProfileAvatar from '../ProfileAvatar';

import { StyledDialog } from './styles';

interface ProfileDialogProps {
  visible: boolean;
  onClose: VoidFunction;
  data: Profile;
}

function ProfileDialog(props: ProfileDialogProps) {
  return (
    <StyledDialog open={props.visible} onClose={props.onClose}>
      <DialogContent>
        <ProfileAvatar name={props.data.contact} photo={props.data.photo} />
      </DialogContent>
    </StyledDialog>
  )
}

export default memo(ProfileDialog);
