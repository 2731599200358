import { CancelToken } from "axios";
import { CancelableResource } from "./Resource";

export interface Profile {
  contact: string
  name?: string;
  code?: number;
  photo?: string;
  city?: string;
  color?: 'Verde' | 'Amarelo' | 'Cinza' |
          'Azul' | 'Roxo' | 'Vermelho' |
          'Laranja' | 'Preto';
  availableMedia?: ('whats' | 'email' | 'sms')[];
}

export default class GetProfileResource extends CancelableResource<Profile> {
  private personId: number;
  private token: string;

  constructor(cancelToken: CancelToken, personId: number, token: string) {
    super(cancelToken);
    this.personId = personId;
    this.token = token;
  }

  public async result(): Promise<Profile> {
    const config = { cancelToken: this.cancelToken };
    const { data } = await this.request.get(`${this.baseURL}/profile/${this.personId}/${this.token}`, config);

    return {
      contact: data.contato,
      name: data.nome,
      code: data.idpessoa,
      photo: data.foto,
      city: data.cidade,
      color: data.cor,
      availableMedia: data['meios_de_comunicacao'],
    };
  }
}
