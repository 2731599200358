import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';


export default () => (
  <div
    style={{
      display: 'flex',
      width: 'calc(100% - 40px)',
      height: 42,
      padding: '12px 20px',
      justifyContent: 'space-between',
      borderBottom: '1px solid #ccc',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '70%',
      }}
    >
      <Skeleton variant="rect" width="70px" height="20px" />
      <Skeleton variant="rect" width="150px" height="14px" />
    </div>
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Skeleton variant="circle" width="20px" height="20px" />
    </div>
  </div>
);