import styled from 'styled-components';

interface HeaderProps {
  visible: boolean;
  hasPerson: boolean;
}

export const Component = styled.div<HeaderProps>`
  && {
    display: ${props => props.visible ? 'flex' : 'none'};
    flex-direction: row;
    background-color: #2B323B;
    min-height: 80px;
    max-height: 80px;
    width: calc(100vw - 410px);
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: inset 0 -10px 10px -10px #000;

    .cabecalho-texto {
      font-size: 18px;
      color: #fff;

      &.perfil-nome {
        padding: 0 10px 0 1rem;
      }

      &.perfil-dado {
        opacity: .3;
      }

      &.perfil-cidade {
        opacity: .8;
      }

      &.perfil-contato {
        display: ${props => props.hasPerson ? 'initial' : 'none'};
      }
    }

    div.right-side {
      display: flex;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;
