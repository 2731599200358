import styled from 'styled-components';

export const Component = styled.div`
  background-color: #EBEBEB;
  border-left: 6px solid #B5B5B5;
  display: flex;
  flex-direction: row;
  padding: 8px;
  margin: 4px 0 6px;
  justify-content: space-between;
`;
