import styled from 'styled-components';


export const Component = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 10px;
  background-color: #414A56;
  color: #DFE0E2;
`;
