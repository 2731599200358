import React, {
  memo,
  useMemo,
} from 'react';

import CheckIcon from '@material-ui/icons/Check';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
 

interface OrderMenuProps {
  anchorEl?: HTMLElement | null;
  open?: boolean;
  initialSelected?: string;
  onSelect: (value: string) => void;
  onBlur: () => void;
}

function OrderMenu(props: OrderMenuProps) {
 
  const iconElement = useMemo(() => (
    <CheckIcon 
      style={{ 
        fontSize: 16, 
        color: '#0E7CD7', 
        marginRight: 10 
      }} 
    />
  ), []);

  return (
    <Menu
      id="order-menu"
      open={Boolean(props.open)}
      anchorEl={props.anchorEl}
      onBlur={props.onBlur}
    >
      <MenuItem dense onClick={() => props.onSelect('Mais Recentes')}>
        { props.initialSelected === 'Mais Recentes' && iconElement }
        <span>Mais Recentes</span>
      </MenuItem>
      <MenuItem dense onClick={() => props.onSelect('Mais Antigas')}>
        { props.initialSelected === 'Mais Antigas' && iconElement }
        <span>Mais Antigas</span>
      </MenuItem>
    </Menu>
   );
 }
 

 export default memo(OrderMenu);