import styled from 'styled-components';

export const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 350px);
  height: calc(100vh - 270px);
  padding-bottom: 80px;
  padding-top: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: -100;
`;


export const FloatingBox = styled.div`
  && {
    transform: scale(1);
    position: relative;
    bottom: 0;
    left: 0;
  
    width: 100%;
  
    span.MuiBadge-root {
      position: fixed;
      top: -56px;
      left: 15px;

      z-index: -50;
    }
    
    button.MuiFab-root {
      width: 54px;
      height: 54px;
      z-index: -40;
  
      background-color: rgba(14, 124, 215);
      color: #fff;
  
      &:hover {
        background-color: rgba(28, 148, 215);
      }
    }
  }
`;