import styled from 'styled-components'

export const NewMessagesPopup = styled.div<{ visible: boolean }>`
  padding: 5px 0;
  display: ${props => props.visible ? 'flex' : 'none'};
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: #eee;
  font-size: 1.2rem;
`
export const PopupText = styled.span`
  padding: 2px 8px;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #fff;
  background-color: #cf1e37;
  border-radius: 10px;
`