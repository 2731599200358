import Handler, { ResultHandler } from "./Handler";

export default class UnbindCampaignFromMessageHandler extends Handler {
  private token: string;
  private messageIdentifier: string;
  private campaignId: number;
  private relationshipId: number;

  constructor(token: string, messageIdentifier: string, campaignId: number, relationshipId: number) {
    super();
    this.token = token;
    this.messageIdentifier = messageIdentifier;
    this.campaignId = campaignId;
    this.relationshipId = relationshipId;
  }

  public async handle(): Promise<ResultHandler> {
    if(this.campaignId) {
      try {
        await this.request.put(
          `${this.baseURL}/message/${this.messageIdentifier}/dismiss/${this.token}`,
          {
            idrelacionamento: this.relationshipId,
            idcampanha: this.campaignId
          }
        );
        return { result: true };
      } catch (error) {
        console.error(error);
        return { result: false, error: String(error) };
      }
    }

    return { result: false, error: 'O id da campanha deve ser válido'};
  }
}
