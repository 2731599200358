import React, {
  useCallback,
  useMemo,
  memo,
} from "react";

import AttachmentIcon from '@material-ui/icons/Attachment';

import { Attachment as AttachedFile } from '../../../../../../../../../../../../api/handlers/SendIndividualMessageHandler';

import AudioPlayer from './components/AudioPlayer';

import { AttachedFileIconButton } from './styles';

interface AttachmentProps {
  attachedFile: AttachedFile;
  MIMEType: string;
  campaing?: number;
};

function Attachment(props: AttachmentProps) {
  const src = useMemo(() => (
    `data:${props.MIMEType};base64,${props.attachedFile.base64}`
  ), [props.attachedFile.base64, props.MIMEType]);
  
  const fileDownload = useMemo(() => (
    props.campaing && !props.attachedFile.descricao
      ? `anexo-campanha-${props.campaing}`
      : props.attachedFile.descricao
  ), [props.attachedFile.descricao, props.campaing]);
  
  const OnAttachmentView = useCallback(() => {
    try {
      fetch(src)
        .then(response => response.blob())
        .then(blob => {
          const [, ext] = blob.type.split('/');
          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.target = '_blank';
          link.href = fileUrl;
          if (ext !== 'pdf') {
            link.download = fileDownload;
          }
          link.click();
        })
    } catch (error) {
      console.error(error);
    }
  }, [src, fileDownload]);
  
  const content = useMemo(() => {
    if (props.MIMEType.startsWith('image'))
      return <img src={src} alt={props.attachedFile.descricao} />;

    if (props.MIMEType.startsWith('audio'))
      return <AudioPlayer src={src} />;

    return (
      <AttachedFileIconButton onClick={OnAttachmentView}>
        <AttachmentIcon />
      </AttachedFileIconButton>
    );
  }, [OnAttachmentView, props.attachedFile.descricao, props.MIMEType, src]);
  
  return content;
};

export default memo(Attachment);