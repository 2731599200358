import Handler, { ResultHandler } from "./Handler";

interface Response {
  validate: boolean;
  isCityCouncilor?: boolean;
}

export default class ValidateTokenHandler extends Handler<Response> {
  private token: string;

  constructor(token: string) {
    super();
    this.token = token;
  }

  public async handle(): Promise<ResultHandler<Response>> {
    const { status, data } = await this.request.post(`${this.baseURL}/session/${this.token}`);

    if (status === 200) {
      return { result: true, data: { validate: true, isCityCouncilor: data['schema_candidato'] } };
    }
    return { result: true, data: { validate: false } };
  }
}
