import Resource from './Resource';

export default class GetNewMessagesResource extends Resource<number> {
  private token: string;
  private timestamp: string;

  constructor(timestamp: number, token: string) {
    super();
    const strTime = timestamp.toString();
    this.timestamp = strTime.substring(0, strTime.length - 3);
    this.token = token;
  }

  public async result(): Promise<number> {
    const { data } = await this.request
      .get(`${this.baseURL}/new-messages/${this.token}?apos_momento=${this.timestamp}`);

    return data.quantidade;
  }
}