import React, {
  ReactChild,
  memo,
} from 'react';

import Button from '@material-ui/core/Button';

interface ButtonProps {
  children?: ReactChild;
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const Item = (props: ButtonProps) => {

  return (
    <Button
      disabled={props.disabled}
      onClick={() => {
        if ((!props.disabled) && props.onClick) {
          props.onClick();
        }
      }}
      style={{
        color: props.color ?? '#000',
        fontWeight: 'bold',
        fontSize: 14,
        backgroundColor: props.backgroundColor ?? 'transparent',
        ...props.style,
      }}
    >
      { props.children}
    </Button>
  );
}

export default memo(Item);
