import React, {
  memo,
  useCallback,
  useContext,
} from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {
  MessageContext,
} from '../../../../../../../../../../../../contexts';

import { StyledCheckIcon } from './styles';
import DemandLevelMenu from './components/DemandLevelMenu';

interface DemandMenuProps {
  refItem?: HTMLElement;
  onClick: (value: string) => void;
  setRefItem: (refItem?: HTMLElement) => void;
}

function DemandMenu(props: DemandMenuProps) {

  const {
    message,
  } = useContext(MessageContext);

  const closeMenu = useCallback(() => {
    props.setRefItem(undefined);
  }, [props.setRefItem]);

  return (
    <Menu
      id="demand-menu"
      open={Boolean(props.refItem)}
      anchorEl={props.refItem}
      onClose={closeMenu}
    >
      <MenuItem dense onClick={() => props.onClick('open')}>
        <StyledCheckIcon className={message.demand === 'open' ? 'visible' : 'hidden'}/>
        <span>{message.demand === 'open' ? 'Demanda aberta' : 'Abrir demanda'}</span>
      </MenuItem>
      <DemandLevelMenu
        label={
          <>
            <StyledCheckIcon className="hidden"/>
            Classificar
          </>
        }
        disabled={message.demand !== 'open'}
        dense
        parentMenuOpen={Boolean(props.refItem)}
        parentMenuClose={closeMenu}
      />
      <MenuItem dense onClick={() => props.onClick('close')}>
        <StyledCheckIcon className={message.demand === 'close' ? 'visible' : 'hidden'}/>
        <span>{message.demand === 'close' ? 'Demanda fechada' : 'Fechar demanda'}</span>
      </MenuItem>
    </Menu>
  );
}

export default memo(DemandMenu);