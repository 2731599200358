import React, {
  createContext,
  useRef,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';

import {
  AppContext,
} from '.';

import GetListRegionsResource, { Region } from '../api/resources/GetListRegionsResource';
import GetListDiocesesResource, { Diocese } from '../api/resources/GetListDiocesesResource';
import GetListCitiesResource, { City } from '../api/resources/GetListCitiesResource';
import GetListNeighborhoodResource, { Neighborhood } from '../api/resources/GetListNeighborhoodResource';
import GetAvailableColorsResource, { Color } from '../api/resources/GetAvailableColorsResource';


interface DiocesesFilter {
  regions: number[]
}

interface CitiesFilter {
  dioceses: number[];
  regions: number[];
}

interface NeighborhoodFilter {
  cities: number[];
}

type ResourceData = 'not-loaded' | 'loading' | 'loaded';

interface ChatFilterContextData {
  getRegionsList: () => Region[];
  getDiocesesList: (filter?: DiocesesFilter) => Diocese[];
  getCitiesList: (filter?: CitiesFilter) => City[];
  getNeighborhoodList: (filter: NeighborhoodFilter) => Promise<Neighborhood[]>;

  loadRegions: () => Promise<void>;
  loadDioceses: () => Promise<void>;
  loadCities: () => Promise<void>;

  loadColors: () => Promise<void>;
  getColors: () => Color[];
  setColors: (colors: Color[]) => void;
  
  activeResources: () => void;
}

interface OperationInProgress {
  [id: string]: boolean;
}

const ChatFilterContext = createContext<ChatFilterContextData>({} as ChatFilterContextData);

/**
 * Este contexto contém todos os estados e funções que controlam o 
 * filtro de conversas
 */
export const ChatFilterProvider = ({ children }) => {

  const { token, isCityCouncilor } = useContext(AppContext);
  /**
   * Este estado armazena as cores disponíveis para filtro 
   * no schema atual
   */
  const [colors, setColors] = useState<Color[]>([]);

  /**
   * Essa referência registra o estado de uma determinada operação
   * impedindo que a operação seja executada duas ou mais vezes ao
   * mesmo tempo
   */
  const operationInProgress = useRef<OperationInProgress>({});

  //========================================================
  /**
   * Este estado armazena a lista de regiões para filtro
   */
  const [regions, setRegions] = useState<Region[]>([]);
  /**
   * Este estado armazena a lista de dioceses para filtro
   */
  const [dioceses, setDioceses] = useState<Diocese[]>([]);
  /**
   * Este estado armazena a lista de cidades para filtro
   */
  const [cities, setCities] = useState<City[]>([]);

  /**
   * Este estado controla o estágio de carregamento dos dados
   */
  const [loadResources, setLoadResources] = useState<ResourceData>('not-loaded');

  /**
   * Essa função ativa o carregamento de informações
   */
  const activeResources = useCallback(() => {
    setLoadResources((old) => old === 'not-loaded' ? 'loading': old);
  }, []);


  /**
   * Essa função carrega a lista de regiões
   */
  const loadRegions = useCallback(async () => {
    if (!operationInProgress.current['getRegions'] && regions.length === 0) {
      operationInProgress.current['getRegions'] = true;

      const resource = new GetListRegionsResource(token);
      const result = await resource.result();
      setRegions(result);

      operationInProgress.current['getRegions'] = false;
    }
  }, [regions.length, token]);

  /**
   * Essa função carrega a lista de dioceses
   */
  const loadDioceses = useCallback(async () => {
    if (!operationInProgress.current['getDioceses'] && dioceses.length === 0) {
      operationInProgress.current['getDioceses'] = true;

      const resource = new GetListDiocesesResource(token);
      const result = await resource.result();
      setDioceses(result);

      operationInProgress.current['getDioceses'] = false;
    }
  }, [dioceses.length, token]);

  /**
   * Essa função carrega a lista de cidades
   */
  const loadCities = useCallback(async () => {
    if (!operationInProgress.current['getCities'] && cities.length === 0) {
      operationInProgress.current['getCities'] = true;

      const resource = new GetListCitiesResource(token);
      const result = await resource.result();
      setCities(result);

      operationInProgress.current['getCities'] = false;
    }
  }, [cities.length, token]);

  /**
   * Essa função carrega a lista de cores
   */
  const loadColors = useCallback(async () => {
    if (!operationInProgress.current['getColors'] && colors.length === 0) {
      operationInProgress.current['getColors'] = true;

      const resource = new GetAvailableColorsResource(token);
      const result = await resource.result();
      setColors(result);

      operationInProgress.current['getColors'] = false;
    }
  }, [colors.length, token]);

  /**
   * Essa função retorna a lista de cores disponíveis
   */
  const getColors = useCallback(() => {
    return colors;
  }, [colors]);

  //========================================================
  /**
   * Essa função retorna a lista de regiões
   */
  const getRegionsList = useCallback(() => {
    return regions;
  }, [regions]);

  /**
   * Essa função retorna a lista de dioceses com base 
   * na lista de regiões selecionadas no filtro
   */
  const getDiocesesList = useCallback((filter?: DiocesesFilter) => {
    if (filter && filter.regions.length !== 0) {
      return dioceses.filter(item => (
        filter.regions.includes(item.regionId)
      ));
    }
    return dioceses;
  }, [dioceses]);

  /**
   * Essa função retorna a lista de cidades com base nas configurações de filtro
   * de regiões e dioceses
   */
  const getCitiesList = useCallback((filter?: CitiesFilter) => {
    if (filter) {
      if (filter.dioceses.length !== 0) {
        return cities.filter(item => filter.dioceses.includes(item.dioceseId));
      } else if (filter.regions.length !== 0) {
        const diocesesIds = dioceses
          .filter(item => filter.regions.includes(item.regionId))
          .map(item => item.id);
        return cities.filter(item => diocesesIds.includes(item.dioceseId));
      }
    }
    return cities;
  }, [cities, dioceses]);

  /**
   * Essa função retorna a lista de bairros com base no filtro de cidade
   */
  const getNeighborhoodList = useCallback(async (filter: NeighborhoodFilter) => {
    if (!operationInProgress.current['getNeighborhood']) {
      operationInProgress.current['getNeighborhood'] = true;

      const resource = new GetListNeighborhoodResource(token, filter.cities);
      const result = await resource.result();

      operationInProgress.current['getNeighborhood'] = false;
      return result
    }

    return [];
  }, [token]);

  /**
   * Esse useEffect hook monitora o estado de carregamento dos recursos 
   * iniciais para liberação dos demais recursos
   */
  useEffect(() => {
    if(loadResources === 'loading') {
      setLoadResources('loaded');
      if (!isCityCouncilor) {
        loadRegions();
        loadDioceses();
      }
      loadCities();
      loadColors();
    }
  }, [isCityCouncilor, loadCities, loadColors, loadDioceses, loadRegions, loadResources]);

  return (
    <ChatFilterContext.Provider value={{
      getRegionsList,
      getDiocesesList,
      getCitiesList,
      loadRegions,
      loadDioceses,
      loadCities,
      getNeighborhoodList,
      loadColors,
      getColors,
      setColors,
      activeResources,
    }}>
      { children}
    </ChatFilterContext.Provider>
  );
}

export default ChatFilterContext;