import styled from 'styled-components';

export const ActionsContainer = styled.div`
  && {
    display: flex;
    margin-top: 15px;
    flex-direction: row;

    .MuiButton-root {
      margin: 0 10px;

      
      &:first-of-type {
        width: 120px;
      }

      &:last-of-type {
        width: 100px;
      }
    }
  }
`;
