import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { MessageContext } from '../../../../../../../../../../../../contexts';
import type { CampaingQuery } from '../../../../../../../../../../../../contexts/MessageContext';

import {
  Dialog,
  Table,
  Button,
  TextField
} from '../../../../../../../../../../../../components';

import { ActionsContainer } from './styles';

interface AssignCampaignDialogProps {
  open: boolean;
  show: (value: boolean) => void;
}

interface CampaignDTO {
  'Código': number;
  'Campanha': string;
  'idrelacionamento': number;
  'subtipocampanha': number;
}

export default function AssignCampaignDialog(props: AssignCampaignDialogProps) {
  const { listCampaigns, setCampaign } = useContext(MessageContext);

  const [searchValue, setSearchValue] = useState('');
  const [currentRow, setCurrentRow] = useState<CampaignDTO>({} as CampaignDTO);

  const [tableLines, setTableLines] = useState(() => {
    const initialState: CampaignDTO[] = [];
    listCampaigns()
      .then(campaigns => {
        initialState.unshift( // adiciona os elementos da resposta no array result
          ...campaigns
            .map(item => ({
        'Código': item.id,
        'Campanha': item.description,
        'idrelacionamento': item.relationshipId,
        'subtipocampanha': item.idSubType
            }) as CampaignDTO)
        )
    });
    return initialState;
  });

  const handleClose = useCallback(() => props.show(false), [props]);

  const handleConfirmCampaign = useCallback(() => {
    setCampaign({
      id: currentRow['Código'],
      name: currentRow['Campanha'],
      relationshipId: currentRow['idrelacionamento'],
      subTypeCampaignId: currentRow['subtipocampanha']
    })
    .then(result => (result && props.show(false)));
  }, [currentRow, props.show, setCampaign]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchChange = useCallback((event) => setSearchValue(event.target.value), []);
  
  const onSearchRequest = useCallback(() => {
    const query: CampaingQuery = { codigo: '', descricao: '' };
    const code = Number(searchValue);

    if (Number.isInteger(code))
      query.codigo = searchValue;
    else
      query.descricao = searchValue;
    
    listCampaigns(query)
      .then(campaigns => {
        setTableLines(campaigns.map(item => ({
          'Código': item.id,
          'Campanha': item.description,
          'idrelacionamento': item.relationshipId,
          'subtipocampanha': item.idSubType
        })));
    });
  }, [listCampaigns, searchValue]);

  const refSearchRequest = useRef(-1);

  useEffect(() => {
    window.clearTimeout(refSearchRequest.current);
    refSearchRequest.current = window.setTimeout(onSearchRequest, 800);
  }, [onSearchRequest]);

  return (
    <Dialog
      title="Atribuir campanha"
      onClose={handleClose}
      open={props.open}
    >
      <TextField
        style={{ margin: '5px 0 10px' }}
        value={searchValue}
        onChange={handleSearchChange}
        size="small"
        placeholder="Procurar campanha pelo código ou descrição ..."
      />
      <Table
        selectedItems={[currentRow]}
        setSelectedItem={setCurrentRow}
        index="Código"
        headers={[
          { name: 'Código', size: 50, align: 'right' },
          { name: 'Campanha', align: 'right' },
        ]}
        items={tableLines}
      />
      <ActionsContainer>
        <Button
          color="#fff"
          backgroundColor="#0B70D2"
          onClick={handleConfirmCampaign}
          disabled={currentRow['Código'] === undefined ? true : false}
        >
          Confirmar
        </Button>
        <Button
          onClick={handleClose}
          backgroundColor="transparent"
          color="#515A64"
        >
          Cancelar
        </Button>
      </ActionsContainer>
    </Dialog>
  );
}
