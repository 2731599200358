import React, {
  createContext,
  useRef,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';

import {
  AppContext,
  ChatContext,
  FeedbackContext,
} from '..';

import { DemandLevel } from '../ChatContext';

import GetListChatResource, { Chat, ChatFilter } from '../../api/resources/GetListChatResource';
import GetNewMessagesResource from '../../api/resources/GetNewMessagesResource';

import {
  SetPersonToAChatHandler
} from '../../api/handlers';

type DemandType = 'open' | 'close' | 'none';

interface ChatListContextData {
  activeOffset: number;
  activeOrder: string;
  activeFilter: ChatFilter;
  chatList?: Chat[];
  currentUrlRequest: string | undefined;
  currentChat: string;
  isNewListLoading: boolean;
  loadNewMessages: () => Promise<void>;
  newMessages: number;
  refActiveFilterChat: React.MutableRefObject<Boolean>;
  bindPersonToAChat: (chatId: string, personId: number, phoneId?: number, emailId?: number) => Promise<boolean>;
  decreaseMessageCounter: (count: number) => void;
  getMoreChatItems: (forceOffset?: number) => void;
  increaseMessageCounter: (count: number) => void;
  setActiveFilter: (filter: React.SetStateAction<ChatFilter>) => void;
  setActiveOrder: (order: string) => void;
  setDemandToCurrentChat: (type: DemandType) => void;
  setDemandLevelToCurrentChat: (oldLevel: DemandLevel | null, newLevel?: DemandLevel | null) => void;
  setSuggestionToCurrentChat: (value: boolean) => void;
}

interface OperationInProgress {
  [id: string]: boolean;
}

const ChatListContext = createContext<ChatListContextData>({} as ChatListContextData);

/**
 * Este contexto controla todos os estados e operações
 * referentes a lista de conversas
 */
export function ChatListProvider({ children }) {

  const { token } = useContext(AppContext);
  const { setFeedback } = useContext(FeedbackContext);
  const {
    currentChat,
    clearMessages,
    getMoreChatMessages,
    chatMessages,
  } = useContext(ChatContext);

  /**
   * Esta referência controla a execução das operações com requisição,
   * evitando que essa seja executada duas vezes ao mesmo tempo
   */
  const operationInProgress = useRef<OperationInProgress>({});

  /**
   * Este estado armazena a lista de conversas apresentadas
   */
  const [ chatList, setChatList ] = useState<Chat[] | undefined>(undefined);

  /**
   * Este estado indica se uma requisição da ChatList está em progresso
   */
  const [ isNewListLoading, setNewListLoading ] = useState<boolean>(false);

  /**
   * Esta referência indica se todos os itens possíveis
   * da lista de conversa foram buscados
   */
   const chatItemsRetrieved = useRef(false);

  /**
   * Estado que armazena o filtro atual
   */
  const [ activeFilter, setActiveFilter ] = useState<ChatFilter>(
    {
      addedToHistory: false,
      withNote: false,
      withSuggestion: false,
      startDate: null,
      endDate: null,
    }
  );

  /**
   * Estado que armazena a ordenação atual
  */
  const [ activeOrder, setActiveOrder ] = useState<string>('Mais Recentes');

  /**
   * Estado que armazena offset atual de registros que serão retornados
  */
  const [ activeOffset, setActiveOffset ] = useState<number>(0);

  /**
   * Estado que armazena limite atual de registros que serão retornados
  */
  const [ activeLimit ] = useState<number>(20);

  /**
   * Estado que armazena url atual de busca de chats
  */
  const [ currentUrlRequest, setCurrentUrlRequest ] = useState<string>('/chats');

  const [newMessages, setNewMessages] = useState<number>(0);
  const refLastLoadTimestamp = useRef(Date.now());

  const loadNewMessages = useCallback(async () => {
    if (!operationInProgress.current['chatList']) {
      operationInProgress.current['chatList'] = true;

      const resource = new GetListChatResource(token);
      resource.setOffset(0);
      resource.setLimit(activeLimit);
      resource.setOrder(activeOrder);
      if (!Object.values(activeFilter).every(item => !item)) {
        resource.setFilter(activeFilter);
      }

      try {
        setNewListLoading(true);
        const result = await resource.result();

        setChatList((old) => {
          if (old && old.length > 0) {
            const chatsCodes = result.map(item => `${item.contactName}-${item.personId}`);
            const activeChatIndex = chatsCodes.findIndex(item => item === currentChat);
            if (activeChatIndex > -1) {
              operationInProgress.current['chatMessages'] = true;
              clearMessages();
            }

            const newItemsKey = result.map(item => item.contactName);
            const oldItems = old.filter(item => !newItemsKey.includes(item.contactName));

            const output = (activeOrder === 'Mais Recentes')
              ? [...result, ...oldItems]
              : [...oldItems, ...result];

            return output;
          }
          return result;
        });

        setNewMessages(0);
        refLastLoadTimestamp.current = Date.now();
      } catch (error) {
        console.error(error);
      } finally {
        setNewListLoading(false);
      }
      operationInProgress.current['chatList'] = false;
    }
  }, [
    activeFilter,
    activeLimit,
    activeOrder,
    clearMessages,
    currentChat,
    token,
  ]);

  const getNewMessages = useCallback(async () => {
    if (token) {
      const resource = new GetNewMessagesResource(refLastLoadTimestamp.current, token);
      const result = await resource.result();
      setNewMessages(result);
    }
  }, [token]);

  useEffect(() => { window.setInterval(getNewMessages, 30000); }, [getNewMessages]);

  /**
   * Este hook monitora a atualização da lista de novas conversas, caso hajam novas conversas,
   * essas são adicionadas a lista de conversas
   */
  //  TODO: Qual deve se o algoritimo para nao quebrar a ordem da lista?
  //        Algo que preserve a ordem e mude apenas os estados do item.
  // useEffect(() => {
  //   if(chatListRefreshedData && !refActiveFilterChat.current && !operationInProgress.current['chatList']) {
  //     setChatList((old) => {
  //       if(old) {
  //         const newItemsKey = chatListRefreshedData.map(item => item.contactName);
  //         const oldItems = old.filter(item => !newItemsKey.includes(item.contactName));
  //         let output;
  //         if (activeOrder === 'Mais Recentes') {
  //           output = [...chatListRefreshedData, ...oldItems];
  //         } else {
  //           output = [...oldItems, ...chatListRefreshedData];
  //         }
  //         return output;
  //       } else {
  //         return chatListRefreshedData;
  //       }
  //     });
  //   }
  // }, [chatListRefreshedData, activeOrder]);

  /**
   * Este hook monitora a atualização de parametros para buscar novas conversas.
   * Parametros monitorados: filter, order.
   */

  const getChatList = useCallback(async (offset: number) => {
    if (!operationInProgress.current['chatList']) {
      if (offset === 0)
        chatItemsRetrieved.current = false;

      operationInProgress.current['chatList'] = true;

      const resource = new GetListChatResource(token);
      resource.setFilter(activeFilter);
      resource.setOrder(activeOrder);
      resource.setOffset(offset);
      resource.setLimit(activeLimit);

      setCurrentUrlRequest(resource.getRequestUrl());

      try {
        const result = await resource.result();

        if (result.length > 0) {
          setChatList(old => {
            const newChatList = offset > 0
              ? [...(old || []), ...result]
              : result;

            setActiveOffset(newChatList.length);
            return newChatList;
          });
        } else {
          setChatList(old => {
            if (offset > 0) {
              chatItemsRetrieved.current = true;
              return old;
            }

            setActiveOffset(0);
            return [];
          });
        }
      } catch (error) {
        console.error(error);
      }

      operationInProgress.current['chatList'] = false;
    }
  }, [activeFilter, activeOrder, activeLimit, token]);

  useEffect(() => {
    setNewListLoading(true);
    getChatList(0)
      .then(() => setNewListLoading(false))
      .catch(() => setNewListLoading(false));
  }, [activeFilter, activeOrder, getChatList]);

  /**
   * Essa função manipula a interface do contador de mensagens não-lidas de uma conversa
   *
   * @param increase Valor booleano indicando se a manipulação é um aumento ou não
   * @param count Quantidade de mensagens não-lidas que deve aumentar ou diminuir
   *
   */
  const changeMessageCounter = useCallback((increase: boolean, count: number) => {
    setChatList(old => old!.map(item => {
      if (currentChat === `${item.contactName}-${item.personId}`) {
        if (increase) item.counter += count;
        else item.counter = item.counter >= count ? item.counter - count : 0;
      }
      return item;
    }))
  }, [currentChat]);

  /**
   * Essa função diminui o número de mensagens não lidas no contador
   * de acordo com uma quantidade
   *
   * @param counter Quantidade a ser alterada
   */
  const decreaseMessageCounter = useCallback((counter: number) => {
    changeMessageCounter(false, counter);
  }, [changeMessageCounter]);

  /**
   * Essa função aumenta o número de mensagens não lidas no contador
   * de acordo com uma quantidade
   *
   * @param counter Quantidade a ser alterada
   */
  const increaseMessageCounter = useCallback((counter: number) => {
    changeMessageCounter(true, counter)
  }, [changeMessageCounter]);

  /**
   * Essa função modifica a interface de uma conversa atribuindo a sinalização
   * de uma nova demanda
   *
   * @param type Tipo da demanda
   */
  const setDemandToCurrentChat = useCallback((type: DemandType) => {
    setChatList(old => old!.map(item => {
      if (currentChat === `${item.contactName}-${item.personId}`)
        item.demand = type;
      return item;
    }))
  }, [currentChat]);

  /**
   * Essa função modifica a interface de uma conversa na lista atualizando seus níveis de demanda
   *
   * @param oldLevel Indica nível atual
   * @param newLevel Indica novo nível
   * newLevel === null Indica a remoção do nível atual
   */

  const setDemandLevelToCurrentChat = useCallback((oldLevel: DemandLevel | null, newLevel?: DemandLevel | null) => {
    setChatList(old => old!.map(item => {
      if (currentChat === `${item.contactName}-${item.personId}`) {
        let levels = item.demandLevels.split('|').filter(item => item !== '');
        const updateLevel = newLevel ? String(newLevel) : null;
        if (
          updateLevel !== null && !levels.find(item => item === updateLevel)) {
          levels.push(updateLevel);
        }
        if (chatMessages.items.filter(item => (item.demand === 'open' && item.demandLevel === oldLevel)).length === 0) {
          levels = levels.filter(item => item !== String(oldLevel))
        }
        item.demandLevels = levels.sort().join('|');
      }
      return item;
    }))
  }, [currentChat, chatMessages]);

  /**
   * Essa função modifica a interface da conversa atribuindo a sinalização de
   * uma sugestão de resposta
   *
   * @param value Indica a adição/remoção da sugestão de resposta
   */
  const setSuggestionToCurrentChat = useCallback((value: boolean) => {
    setChatList(old => old!.map(item => {
      if (currentChat === `${item.contactName}-${item.personId}`)
        item.hasOpenSuggestion = value;
      return item;
    }))
  }, [currentChat]);

  //========================================================
  /**
   * Essa função realiza a busca de novas conversas passando o offset.
   */
  //  TODO: Só chamar getMore se ultima busca retornar qtd de resultados
  //        igual a quantidade solicitada.
  const getMoreChatItems = useCallback((forceOffset?: number) => {
    if (activeOffset > 0 && !chatItemsRetrieved.current) {
      getChatList(forceOffset ?? activeOffset);
    }
  }, [activeOffset, getChatList]);

  //========================================================
  /**
   * Essa referência indica que o filtro de conversa está ativado
   */
  const refActiveFilterChat = useRef(false);

  /**
   * Essa função realiza a vinculação de uma pessoa a uma conversa sem identificação,
   * ou seja, a conversa continha apenas o número de telefone ou e-mail
   *
   * @param chatId Identificação do chat
   * @param personId Identificação da pessoa a ser vinculada
   * @param phoneId Identificação do telefone
   * @param emailId Identificação do email
   */
  const bindPersonToAChat = useCallback(async (chatId: string, personId: number, phoneId: number | undefined, emailId: number | undefined) => {

    if (!operationInProgress.current['bindPersonToAChat']) {
      let identifier: string;
      let type: 'email' | 'number';
      if (chatId.includes('@')) {
        identifier = chatId!.replace('-null', '');
        type = 'email';
      } else {
        type = 'number';
        const formattedNumber = chatId!.replace('-null', '');
        const justNumbers = formattedNumber.match(/\d+/g) ? formattedNumber.match(/\d+/g)!.join("") : "";
        identifier = justNumbers;
      }

      const handler = new SetPersonToAChatHandler(type, identifier, token);
      handler.setPersonId(personId);

      if (type === 'email') {
        handler.setEmailId(emailId!);
      } else {
        handler.setPhoneId(phoneId!);
      }

      operationInProgress.current['bindPersonToAChat'] = true;

      const { result, error } = await handler.handle();

      if (result) {
        const resource = new GetListChatResource(token);
        resource.setPersonId(personId);
        const [chat] = await resource.result();

        setChatList(old =>
          old!.filter(item => item.personId !== personId)
              .map(item => {
                if (item.contactName?.includes(chatId.replace('-null', ''))) {
                  return {
                    ...item,
                    contactName: chat.contactName,
                    personId,
                    counter: chat.counter,
                    description: chat.description,
                    lastMessageDate: chat.lastMessageDate,
                  }
                }
                return item;
              })
        )

      } else {
        console.error(error);
        setFeedback({
          title: 'Falha ao vincular pessoa',
          description: <span>Falha na requisição enviada ao servidor <br />para vincular pessoa</span>,
          type: 'error',
        });
      }

      if (chatId === currentChat) {
        clearMessages();
        getMoreChatMessages();
      }

      operationInProgress.current['bindPersonToAChat'] = false;
    }

    return false;
  }, [token, currentChat, setChatList, setFeedback, clearMessages, getMoreChatMessages]);


  return (
    <ChatListContext.Provider value={{
      activeOffset,
      activeFilter,
      refActiveFilterChat,
      activeOrder,
      currentUrlRequest,
      bindPersonToAChat,
      currentChat,
      chatList,
      decreaseMessageCounter,
      getMoreChatItems,
      increaseMessageCounter,
      isNewListLoading,
      loadNewMessages,
      newMessages,
      setActiveFilter,
      setActiveOrder,
      setDemandToCurrentChat,
      setDemandLevelToCurrentChat,
      setSuggestionToCurrentChat,
    }}>
      { children}
    </ChatListContext.Provider>
  );
}

export default ChatListContext;