import React, {
  createContext,
  useCallback,
  useContext,
  useRef
} from 'react';

import {
  MarkMessageAsReadHandler,
  DeleteMessageHandler,
  UndoDeleteMessageHandler,
  SetDemandToAMessageHandler,
  AddNoteToAMessageHandler,
  SetCampaignToAMessageHandler,
  AddMessageToHistoryHandler,
  EditMessageHandler,
  SetSuggestionToAMessageHandler,
  SetQualificationToAMessageHandler,
  UnbindCampaignFromMessageHandler,
} from '../api/handlers';

import GetListCampaignResource, { Campaign } from '../api/resources/GetListCampaignResource';
import GetListSuggestionsResource, { Suggestion } from '../api/resources/GetListSuggestionsResource';

import { DemandLevel, IMessage } from './ChatContext';

import {
  AppContext,
  ChatContext,
  FeedbackContext,
  ChatListContext,
} from '.';

export type CampaingQuery = { codigo: string; descricao: string; };
interface MessageContextData {
  message: IMessage;
  changeDemandLevel: (value: -1 | 1 | 2) => Promise<void>;
  markAsRead: (value: boolean) => Promise<boolean>;
  deleteMessage: () => Promise<boolean>;
  undoDeleteMessage: () => Promise<boolean>;
  addNoteToAMessage: (note: string) => Promise<boolean>;
  changeHistoryOfAMessage: (type: string) => Promise<boolean>;
  changeQualificationOfAMessage: (type: string) => Promise<boolean>;
  openDemand: () => Promise<boolean>;
  closeDemand: () => Promise<boolean>;
  setCampaign: (campaign: CampaignDTO) => Promise<boolean>;
  updateMessageText: (text: string[]) => Promise<boolean>;
  listCampaigns: (params?: CampaingQuery) => Promise<Campaign[]>;
  listSuggestions: () => Promise<Suggestion[]>;
  setSuggestion: (idSuggetions: number[], suggestion: string) => Promise<boolean>;
  unbindCampaignFromMessage: () => Promise<boolean>;
}

interface OperationInProgress {
  [id: string]: boolean;
}

interface CampaignDTO {
  id: number;
  name: string;
  relationshipId: number;
  subTypeCampaignId: number;
}

interface MessageContextProps {
  message: IMessage;
  children: any;
}

const MessageContext = createContext<MessageContextData>({} as MessageContextData);

/**
 * Este contexto controla todas as operações e estados relacionados a
 * cada mensagem
 */
export const MessageProvider: React.FC<MessageContextProps> = ({ children, message }) => {

  const { token } = useContext(AppContext);
  const { setFeedback } = useContext(FeedbackContext);

  const {
    changeMessage,
    hasOpenDemand,
    hasOpenSuggestion,
  } = useContext(ChatContext);

  const {
    decreaseMessageCounter,
    increaseMessageCounter,
    setDemandToCurrentChat,
    setSuggestionToCurrentChat,
    setDemandLevelToCurrentChat,
  } = useContext(ChatListContext);

  const operationInProgress = useRef<OperationInProgress>({})

  /**
   * Essa função atualiza a cor da bandeira e os níveis de demanda do chat
   * de acordo com o estado das demandas e sugestões de resposta
   */
  const updateFlag = useCallback((oldLevel?:DemandLevel | null, newLevel?: DemandLevel | null) => {
    setSuggestionToCurrentChat(hasOpenSuggestion());
    if(hasOpenDemand()) {
      setDemandToCurrentChat('open');
    }
    else setDemandToCurrentChat('none');
    if (oldLevel !== undefined) {
      setDemandLevelToCurrentChat(oldLevel, newLevel);
    } 
  }, [hasOpenDemand, hasOpenSuggestion, setDemandLevelToCurrentChat, setDemandToCurrentChat, setSuggestionToCurrentChat]);
  
  /**
   * Essa função marca a mensagem como lida ou não lida
   * de acordo com o parâmetro 'read'
   * 
   * @param read Indica se a mensagem foi lida ou não-lida
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const markAsRead = useCallback(async (read: boolean) => {
    if (message.id && !operationInProgress.current['markAsRead']) {
      operationInProgress.current['markAsRead'] = true;

      const handler = new MarkMessageAsReadHandler(token, message.id);
      handler.setRead(read);
      const { result, error } = await handler.handle();

      if (result) {
        if (read) {
          decreaseMessageCounter(1);
        } else {
          increaseMessageCounter(1);
        }

        changeMessage(message.id, (message) => {
          message.demand = 'none';
          message.read = read;
          return message;
        });

        updateFlag();

      } else {
        console.error(error);
        if (read) {
          setFeedback({
            title: 'Falha ao Marcar como Lida',
            description: <span>Falha na requisição enviada ao servidor <br />para marcar mensagem como lida</span>,
            type: 'error',
          });
        } else {
          setFeedback({
            title: 'Falha ao Marcar como Não-Lida',
            description: <span>Falha na requisição enviada ao servidor <br />para marcar mensagem como não lida</span>,
            type: 'error',
          });
        }
      }

      operationInProgress.current['markAsRead'] = false;
      return result;
    }

    return false;
  }, [token, message.id, changeMessage, updateFlag, decreaseMessageCounter, increaseMessageCounter, setFeedback]);

  /**
   * Essa função remove a mensagem da conversa
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const deleteMessage = useCallback(async () => {
    if (message.id && !operationInProgress.current['deleteMessage']) {
      operationInProgress.current['deleteMessage'] = true;

      const handler = new DeleteMessageHandler(token, message.id);
      const { result, error } = await handler.handle();

      if(result) {
        if (!message.read) {
          decreaseMessageCounter(1);
        }

        updateFlag();

      } else {
        console.error(error);
        setFeedback({
          title: 'Falha na Remoção de Mensagens',
          description: <span>Falha na comunicação com o servidor para <br/>remover as mensagens</span>,
          type: 'error',
        })
      }

      operationInProgress.current['deleteMessage'] = false;
      return result;
    }

    return false;
  }, [token, message.id, message.read, updateFlag, decreaseMessageCounter, setFeedback]);

  /**
   * Essa função desfaz a remoção de mensagem da conversa
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const undoDeleteMessage = useCallback(async () => {
    if (message.id && !operationInProgress.current['undoDeleteMessage']) {
      operationInProgress.current['undoDeleteMessage'] = true;

      const handler = new UndoDeleteMessageHandler(token, message.id);
      const { result, error } = await handler.handle();

      if(result) {
        if (!message.read) {
          increaseMessageCounter(1);
        }

        updateFlag();

      } else {
        console.error(error);
        setFeedback({
          title: 'Falha ao Desfazer Remoção de Mensagens',
          description: <span>Falha na comunicação com o servidor para <br/>remover as mensagens</span>,
          type: 'error',
        });
      }

      operationInProgress.current['undoDeleteMessage'] = false;
      return result;
    }

    return false;
  }, [token, message.id, message.read, updateFlag, increaseMessageCounter, setFeedback]);

  /**
   * Essa função adiciona adiciona ou atualiza uma observação a uma 
   * mensagem
   * 
   * @param note Observação adicionada à mensagem
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const addNoteToAMessage = useCallback(async (note: string) => {
    if (message.id && !operationInProgress.current['addNoteToAMessage']) {
      operationInProgress.current['addNoteToAMessage'] = true;

      const handler = new AddNoteToAMessageHandler(token, message.id);
      handler.setNote(note);

      const { result, error } = await handler.handle();

      if (result) {
        changeMessage(message.id, message => {
          message.note = note;
          return message;
        });
      } else {
        console.error(error);
        setFeedback({
          title: 'Falha ao Adicionar Observação',
          description: <span>Falha na comunicação com o servidor ao <br/> adicionar observação a uma mensagem</span>,
          type: 'error',
        });
      }

      operationInProgress.current['addNoteToAMessage'] = false;
      return result;
    }

    return false;
  }, [token, message.id, changeMessage, setFeedback]);

  /**
   * Essa função adiciona ou atualiza a qualificação da mensagem
   * com base em determinado tipo
   * 
   * @param type Tipo do histórico ('Positivo', 'Negativo', 'Neutro', 'Nulo')
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const changeQualificationOfAMessage = useCallback(async (qualification: string) => {
    if (message.id && !operationInProgress.current['changeQualificationOfAMessage']) {
      operationInProgress.current['changeQualificationOfAMessage'] = true;

      const handler = new SetQualificationToAMessageHandler(token, message.id);
      handler.setQualification(qualification !== 'Nulo' ? qualification.toLowerCase() : undefined);
      const { result, error } = await handler.handle();

      if (result) {
        changeMessage(message.id, message => {
          
          const values = {
            'Nulo': null,
            'Neutro': 'neutro',
            'Positivo': 'positivo',
            'Negativo': 'negativo',
          }
          
          message.qualification = values[qualification];
          return message;
        });
      } else {
        console.error(error);
        setFeedback({
          title: 'Falha ao Qualificar Mensagem',
          description: <span>Falha na comunicação com o servidor para <br/>qualificar mensagem</span>,
          type: 'error',
        });
      }

      operationInProgress.current['changeQualificationOfAMessage'] = false;
      return result;
    }

    return false;
  }, [changeMessage, message.id, setFeedback, token]);
  
  /**
   * Essa função adiciona ou atualiza a mensagem no histórico
   * com base em determinado tipo
   * 
   * @param type Tipo do histórico ('Questionamento', 'Informativo', 'Convite', 'Pedido')
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const changeHistoryOfAMessage = useCallback(async (type: string) => {
    if (message.id && !operationInProgress.current['addNoteToAMessage']) {
      operationInProgress.current['changeHistoryOfAMessage'] = true;
      
      const handler = new AddMessageToHistoryHandler(token, message.id);
      handler.setHistoryType(type);
      const { result, data, error } = await handler.handle();

      if (result) {
        changeMessage(message.id, message => {

          const values = {
            'Questionamento': 'questioning',
            'Informativo': 'informative',
            'Convite': 'invitation',
            'Pedido': 'order'
          }

          message.historyType = values[type];
          message.relationshipId = data?.relationshipId;
          return message;
        });
      } else {
        console.error(error);
        setFeedback({
          title: 'Falha ao Adicionar ao Histórico',
          description: <span>Falha na comunicação com o servidor para <br/>adicionar mensagem ao histórico</span>,
          type: 'error',
        });
      }

      operationInProgress.current['changeHistoryOfAMessage'] = false;
      return result;
    }

    return false;
  }, [changeMessage, message.id, setFeedback, token]);

  /**
   * Essa função atribui um nível a uma demanda aberta em uma mensagem
   */
  const changeDemandLevel = useCallback(async (value: DemandLevel) => {
    if (message.id && !operationInProgress.current['changeDemandLevel']) {
      operationInProgress.current['changeDemandLevel'] = true;
      
      const handler = new SetDemandToAMessageHandler(token, message.id);
      handler.setDemandLevel(value)
      const { result, error } = await handler.handle();
      
      if (result) {
        const oldLevel = message.demandLevel ?? null;
        const demandLevel = value !== -1 ? value : null;
        changeMessage(message.id, (message) => {
          message.demandLevel = demandLevel;
          // chama função para atualizar o chatlist: updateDemandLevelFlag(demandLevel)
          return message;
        });
        
        updateFlag(oldLevel, demandLevel);
      
      } else {
        console.error(error);
        setFeedback({
          title: 'Falha ao definir nível de demanda',
          description: <span>Falha na requisição enviada ao servidor <br />para definir nível de demanda</span>,
          type: 'error',
        });
      }
      
      operationInProgress.current['changeDemandLevel'] = false;
    }
  }, [changeMessage, message.demandLevel, message.id, setFeedback, token, updateFlag]);

  /**
   * Essa função atribui uma demanda aberta a uma mensagem
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const openDemand = useCallback(async () => {
    if (message.id && !operationInProgress.current['openDemand']) {
      operationInProgress.current['openDemand'] = true;

      const handler = new SetDemandToAMessageHandler(token, message.id);
      handler.setDemand('open');
      const { result } = await handler.handle();
      
      if (result) {
        let level: DemandLevel | null | undefined = undefined;
        changeMessage(message.id, message => {
          if(!message.read) {
            decreaseMessageCounter(1);
          }

          level = message.demandLevel;
          message.demand = 'open';
          message.read = true;
          return message;
        });
        setDemandToCurrentChat('open');

        updateFlag(level, level);
      }


      operationInProgress.current['openDemand'] = false;
      return result;
    }
    return false;
  }, [changeMessage, decreaseMessageCounter, message.id, setDemandToCurrentChat, token, updateFlag]);
  
  /**
   * Essa função encerra a demanda atribuída a mensagem
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const closeDemand = useCallback(async () => {
    if (message.id && !operationInProgress.current['closeDemand']) {
      operationInProgress.current['closeDemand'] = true;

      const handler = new SetDemandToAMessageHandler(token, message.id);
      handler.setDemand('close');
      const { result, error } = await handler.handle();

      if (result) {
        let level: DemandLevel | null | undefined = undefined;
        changeMessage(message.id, message => {
          if(!message.read) {
            decreaseMessageCounter(1);
          }

          level = message.demandLevel;
          message.demand = 'close';
          message.read = true;
          return message;
        });

        updateFlag(level);


      } else {
        console.error(error);
        setFeedback({
          title: 'Falha ao Finalizar Demanda',
          description: <span>Falha na comunicação com o servidor para <br/>finalizar demanda</span>,
          type: 'error',
        });
      }

      
      operationInProgress.current['closeDemand'] = false;
      return result;
    }
    return false;
  }, [message.id, token, changeMessage, updateFlag, decreaseMessageCounter, setFeedback]);

  /**
   * Essa função lista das últimas campanhas antes da data da mensagem
   * ou busca por uma campanha específica com base no parâmetro 'code'.
   * 
   * @param code Código da campanha, se fornecido.
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const listCampaigns = useCallback<MessageContextData["listCampaigns"]>(async (params) => {
    const result: Campaign[] = [];
    if (message.id && !operationInProgress.current['listCampaigns']) {
      operationInProgress.current['listCampaigns'] = true;
      const resource = new GetListCampaignResource(message.id, token);
      if (params) {
        const query = new URLSearchParams(params);
        resource.setQueryParams(query);
      }
      try {
        result.unshift(...(await resource.result()));
      } catch (error) {
        console.error(error);
        setFeedback({
          title: 'Falha ao Buscar Campanhas',
          description: <span>Falha na comunicação com o servidor para <br/>obter lista de campanhas</span>,
          type: 'error',
        });
      }
    }
    operationInProgress.current['listCampaigns'] = false;
    return result;
  }, [message.id, setFeedback, token]);

  /**
   * Essa função atribui campanha a uma dada mensagem
   * 
   * @param campaign Informações da campanha
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const setCampaign = useCallback(async (campaign: CampaignDTO) => {
    if (message.id && !operationInProgress.current['setCampaign']) {
      operationInProgress.current['setCampaign'] = true;

      const handler = new SetCampaignToAMessageHandler(token, message.id);
      handler.setCampaign(campaign.id);
      handler.setRelationshipId(campaign.relationshipId);
      handler.setSubTypeCampaignId(campaign.subTypeCampaignId);

      const { result, data, error } = await handler.handle();
      
      if (result) {
        changeMessage(message.id, message => {
          message.code = campaign.id;
          message.label = campaign.name;
          message.relationshipId = data?.relationshipId;
          return message;
        });
      } else {
        console.error(error);
        setFeedback({
          title: 'Falha ao Vincular Campanha',
          description: <span>Falha na comunicação com o servidor na <br/>vinculação de campanha</span>,
          type: 'error',
        });
      }

      operationInProgress.current['setCampaign'] = false;
      return result;
    }
    return false;
  }, [changeMessage, message.id, setFeedback, token]);

  /**
   * Essa função desvincula a campanha atual da mensagem
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const unbindCampaignFromMessage = useCallback(async () => {
    if (message.id && message.code && message.relationshipId && !operationInProgress.current['unbindCampaignFromMessage']) {
      operationInProgress.current['unbindCampaignFromMessage'] = true;

      const handler = new UnbindCampaignFromMessageHandler(token, message.id, message.code, message.relationshipId);
      const { result, error } = await handler.handle();

      if (result) {
        changeMessage(message.id, message => {
          message.code = undefined;
          message.label = undefined;
          message.relationshipId = undefined;
          return message;
        });
      } else {
        console.error(error);
        setFeedback({
          title: 'Falha ao Desvincular Campanha',
          description: <span>Falha na comunicação com o servidor na <br/>desvinculação de campanha</span>,
          type: 'error',
        });
      }

      operationInProgress.current['unbindCampaignFromMessage'] = false;
      return result;
    }
    return false;
  }, [changeMessage, message.code, message.id, message.relationshipId, setFeedback, token]);

  /**
   * Essa função atualiza o texto da mensagem atual
   * 
   * @param value Novas linhas do texto que compõem a mensagem
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const updateMessageText = useCallback(async (value: string[]) => {
    if (message.id && !operationInProgress.current['updateMessageText']) {
      operationInProgress.current['updateMessageText'] = true;

      const handler = new EditMessageHandler(token, message.id);
      handler.setText(value);
      const { result, error } = await handler.handle();

      if (result) {
        changeMessage(message.id, message => {
          message.items = value;
          message.editTimestamp = new Date();
          return message;
        });
      } else {
        console.error(error);
        setFeedback({
          title: 'Falha ao Editar Mensagem',
          description: <span>Falha na comunicação com o servidor na <br/>edição da mensagem</span>,
          type: 'error',
        });
      }

      operationInProgress.current['updateMessageText'] = false;
      return result;
    }

    return false;
  }, [changeMessage, message.id, setFeedback, token]);

  /**
   * Essa função busca a listagem de sugestões disponíveis para a
   * mensagem atual
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const listSuggestions = useCallback(async () => {
    let result: Suggestion[] = [];
    if (!operationInProgress.current['listSuggestions']) {
      operationInProgress.current['listSuggestions'] = true;
      try {
        const resource = new GetListSuggestionsResource(token);
        result = await resource.result();
      } catch (error) {
        console.error(error);
        setFeedback({
          title: 'Falha ao Obter Sugestões',
          description: <span>Falha na comunicação com o servidor na <br/>listagem de sugestões</span>,
          type: 'error',
        });
      }

      operationInProgress.current['listSuggestions'] = false;
    }
    return result;
  }, [setFeedback, token]);

  /**
   * Essa função atribui uma ou mais sugestões a mensagem atual
   * 
   * @param idSuggestions Lista de identificadores das sugestões atribuídas
   * @param suggestion Texto final da sugestão
   * 
   * @returns Retorna o resultado da solicitação no serviço (API)
   */
  const setSuggestion = useCallback(async (idSuggestions: number[], suggestion: string) => {
    if (message.id && !operationInProgress.current['setSuggestion']) {
      operationInProgress.current['setSuggestion'] = true;

      const handler = new SetSuggestionToAMessageHandler(token, message.id);
      handler.setIdSuggestions(idSuggestions);
      handler.setText(suggestion);
      
      const { result, error } = await handler.handle();

      if (result) {
        changeMessage(message.id, message => {
          message.suggestion = suggestion;
          return message;
        });

        setSuggestionToCurrentChat(true);

      } else {
        console.error(error);
        setFeedback({
          title: 'Falha ao vincular sugestão de resposta',
          description: <span>Falha na comunicação com o servidor na <br/>vinculação de sugestão de resposta</span>,
          type: 'error',
        });
      }

      operationInProgress.current['setSuggestion'] = false;
      return result;
    }
    return false;
  }, [changeMessage, message.id, setFeedback, setSuggestionToCurrentChat, token]);

  return (
    <MessageContext.Provider value={{
      message,
      changeDemandLevel,
      markAsRead,
      deleteMessage,
      undoDeleteMessage,
      addNoteToAMessage,
      changeHistoryOfAMessage,
      changeQualificationOfAMessage,
      closeDemand,
      openDemand,
      setCampaign,
      listCampaigns,
      updateMessageText,
      listSuggestions,
      setSuggestion,
      unbindCampaignFromMessage,
    }}>
      { children}
    </MessageContext.Provider>
  );
}

export default MessageContext;