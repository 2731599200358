import Resource from "./Resource";

export interface Neighborhood {
  id: number;
  name: string;
}

export default class GetListNeighborhoodResource extends Resource<Neighborhood[]> {
  private token: string;
  private cities: number[];

  constructor(token: string, cities: number[]) {
    super();
    this.token = token;
    this.cities = cities;
  }

  public async result(): Promise<Neighborhood[]> {
    let response = await this.request.get(`${this.baseURL}/autocomplete/bairros/${this.token}?idCidades=${this.cities.join(',')}`);

    const result: Neighborhood[] = response.data.map(item => ({
      id: item.id,
      name: item.descricao,
    }));

    return result
  }
}
