import { CancelToken } from "axios";
import { IMessageFilter } from "../../contexts/ChatContext";
import DefaultChat from "./DefaultChat";

export default class GetChatResource extends DefaultChat {
  private personId: number;

  constructor(cancelToken: CancelToken, personId: number, token: string, filters?: IMessageFilter) {
    super(cancelToken, token, filters);
    this.personId = personId;
    
    this.setUrl(`${this.baseURL}/chats/${this.personId}/${this.token}`);
  }
}
