import React, { useContext } from 'react'

import { ChatListContext } from '../../../../../../contexts'
import { NewMessagesPopup, PopupText } from './styles'
import Button from '../../../../../Button'

export default function NewMessagesAlert() {
  const { newMessages, loadNewMessages } = useContext(ChatListContext)

  return (
    <NewMessagesPopup visible={newMessages > 0}>
      <PopupText>
        {
          newMessages > 1
            ? `${newMessages <= 25 ? newMessages : '25+'} novas conversas`
            : '1 nova conversa'
        }
      </PopupText>
      <Button
        onClick={loadNewMessages}
        style={{
          padding: '2px 4px',
          fontSize: '1rem',
        }}
      >
        Visualizar
      </Button>
    </NewMessagesPopup>
  )
}