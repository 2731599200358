import Handler, { ResultHandler } from "./Handler";

interface Response {
  id_pessoa: number;
  id_mensagens_individuais: number;
}

export interface Attachment {
  base64: string;
  descricao: string;
}

export interface MessageProps {
  type: string;
  message: string | null;
  personId: number;
  subject?: string;
  relationshipId?: number;
  messageId?: string;
  attachment?: Attachment;
}

export default class SendIndividualMessageHandler extends Handler<Response> {
  private token: string;
  private item: MessageProps

  constructor(token: string, item: MessageProps) {
    super();
    this.token = token;
    this.item = item;
  }

  public async handle(): Promise<ResultHandler<Response>> {
    const item = {
      tipo: this.item.type,
      mensagem: this.item.message ?? null,
      idpessoa: this.item.personId,
    };

    if (this.item.messageId) {
      if (this.item.messageId.includes("whats")){
        item['idretornowhats'] = this.item.messageId.replace("received-whats-", "");
      } else if (this.item.messageId.includes("sms")) {
        item['idretornosms'] = this.item.messageId.replace("received-sms-", "");
      } else {
        item['idretornoemail'] = this.item.messageId.replace("received-email-", "");
      }
    }
   
    if (this.item.subject) {
      item['assunto'] = this.item.subject
    }

    if (this.item.relationshipId) {
      item['idrelacionamento'] = this.item.relationshipId
    }

    if (this.item.attachment) {
      item['anexo'] = this.item.attachment;
    }

    try {
      const { data } = await this.request
        .post(`${this.baseURL}/individual-message/${this.token}`, item);

      const [message] = data['mensagens_individuais'];
      
      return { 
        result: true,
        data: message,
      };
    } catch (error) {
      console.error(error);
      return { result: false, error: String(error) };
    }
  }
}
