import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton'
import BookmarkIcon from '@material-ui/icons/Bookmark'

interface HeaderProps {
  hasCampaign: boolean;
}

export const Header = styled.div<HeaderProps>`
  width: calc(100% - 16px);
  min-height: 30px;
  background-color: ${props => props.hasCampaign ? '#EFEFEF' : '#FFFF9C' };
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
  align-items: center;
  padding: 0 8px;
`
export const BookmarkButton = styled(IconButton)`
  && {
    padding: 0;
    position: absolute;
    top: -12px;
    right: 20px;
    cursor: pointer;

    &.unset-demand {
      svg {
        opacity: 0.5;
        
        &:hover {
          opacity: 1;
        }
      }

      .light-text, .dark-text {
        display: none;
      }
    }

    &.set-demand {
      svg {
        display: block;
      }
      
      &.closed {
        svg {
          color: #878787;
        }

        .light-text, .dark-text {
          display: none;
        }
      }

      &.with-suggestion {
        svg {
          color: #1fa800;
        }
      }
    }
  }
`
interface DemandBookmarkProps {
  isMessageHovered?: boolean;
}

export const DemandBookmark = styled(BookmarkIcon)<DemandBookmarkProps>`
  && {
    display: ${props => props.isMessageHovered ? 'block' : 'none'};
    font-size: 30px;
    color: #ffdd19;
  }
`

interface BookmarkLabelProps {
  demandLevel?: number | null;
}

export const BookmarkLabel = styled.span<BookmarkLabelProps>`
    display: ${props => Boolean(props.demandLevel) ? 'block' : 'none'};
    font-size: 1rem;
    position: absolute;
    top: 5px;
    z-index: 99;
    cursor: pointer;
    user-select: none;

    &.light-text {
      color: #fff;
    }

    &.dark-text {
      color: #333;
    }
`
