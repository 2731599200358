import Resource from "./Resource";

export interface Color {
  hex: string;
  label: string;
  id: number;
}

export default class GetAvailableColorsResource extends Resource<Color[]> {
  private token: string;

  constructor(token: string) {
    super();
    this.token = token;
  }

  public async result(): Promise<Color[]> {
    let response = await this.request.get(`${this.baseURL}/cores/${this.token}`);
    const result: Color[] = response.data.map(item => ({
      hex: item.cor,
      label: item.nome,
      id: item.id,
    }));

    return result
  }
}
