import React, {
  useContext,
  useMemo,
  useState,
} from 'react';
import { Loading } from '../../components';

import AppContext from '../../contexts/AppContext';

import { ChatProvider } from '../../contexts/ChatContext';
import { ChatListProvider } from '../../contexts/ChatListContext';

import AppScreen from './components/AppScreen';
import NotAuthorizedScreen from './components/NotAuthorizedScreen';

import { Container } from './styles';

function Home() {
  const { validateToken } = useContext(AppContext);

  const [isValidated, setValidated] = useState<boolean | undefined>(() => {
    // inicia a validação do token
    validateToken()
      .then(result => setValidated(result));

    // retorna um estado indefinido para iniciar a tela de carregamento
    return undefined;
  });

  // conteudo depende do estado da validação 
  const content = useMemo(() => {
    if (isValidated === undefined)
      return (
        <Container>
          <Loading color="#fff" />
        </Container>
      );
    
    if (!isValidated)
      return <NotAuthorizedScreen />;

    return (
      <ChatProvider>
        <ChatListProvider>
          <AppScreen />
        </ChatListProvider>
      </ChatProvider>
    );
  }, [isValidated]);
  
  return content;
}

export default Home;